<div class="client-inspection-contract-log-container">
  <div  class="inspection-log-container">
    <app-client-subheader [clientSubheader]="subheader" (clickBack)="back()" class="subheader"/>
    <div class="inspection-log-items-container" *ngIf="!loading">
      <div class="log-items-container">
        <div *ngIf="!hideNotPlanned">
          <div *ngFor="let task of tasks" class="log-item">
            <app-cell-item
              [title]="correctFormattedDate(task.planDate ?? '')"
              [label]="tuskStatusesMap.get(task.status) ?? ''"
              [color]="getColor(task.status)"
              [icon]="getIcon(task.status)"
              [routerLink]="'/client/inspection-log/contract/' + contractId + '/task/' + task.id "
              [beginDate]="correctFormattedDate(task.beginDate ?? '')"
              [endDate]="correctFormattedDate(task.endDate ?? '')"
            />
          </div>
        </div>
        <div *ngIf="hideNotPlanned">
          <div *ngFor="let task of plannedTask" class="log-item">
            <app-cell-item
              [title]="correctFormattedDate(task.planDate ?? '')"
              [label]="tuskStatusesMap.get(task.status) ?? ''"
              [color]="getColor(task.status)"
              [icon]="getIcon(task.status)"
              [routerLink]="'/client/inspection-log/contract/' + contractId + '/task/' + task.id "
              [beginDate]="correctFormattedDate(task.beginDate ?? '')"
              [endDate]="correctFormattedDate(task.endDate ?? '')"
            />
          </div>
        </div>
        <app-no-items
          *ngIf="hideNotPlanned && plannedTask.length == 0 && tasks.length > 0"
          [title]="['Запланированных осмотров пока нет', 'Todavía no hay inspecciones programadas']"
        />
        <app-no-items
          *ngIf="tasks.length == 0"
          [title]="['Нет осмотров', 'No hay inspecciones']"
        />
      </div>
      <div class="log-filter-container">
        <div class="filter-container-inner">
          <div class="checkbox-container">
            <div class="log-checkbox">
              <app-checkbox class="log-checkbox" [value]="hideNotPlanned" (valueOutput)="changeMode($event)"/>
            </div>
            <div class="log-checkbox-text">
              {{ text(['Скрыть незапланированные задачи', 'Ocultar tareas no programadas']) }}
            </div>
          </div>
          <div class="filter-container" (click)="switchSort()">
            <div class="log-filter-icon">
              <app-icon [icon]="sortIcon"/>
            </div>
            <div class="log-filter-text">
              {{ sortTitle }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loading" class="loading-container">
    <app-loading />
  </div>
</div>

