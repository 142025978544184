import {Component, Input} from '@angular/core';
import {Icon, icons} from "../../../model/icon";
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-icon',
  standalone: true,
  imports: [
    NgOptimizedImage
  ],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss'
})
export class IconComponent {
  @Input() icon: Icon = icons[0];
  @Input() color: string = "";
  @Input() styles: string = '';
}
