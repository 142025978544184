import {Component, Input} from '@angular/core';
import {IconComponent} from "../icon/icon.component";
import {Icon, iconsByName} from "../../../model/icon";
import {NgIf} from "@angular/common";
import {text} from "../../../function/localization";

@Component({
  selector: 'app-cell-item',
  standalone: true,
  imports: [
    IconComponent,
    NgIf
  ],
  templateUrl: './cell-item.component.html',
  styleUrl: './cell-item.component.scss'
})
export class CellItemComponent {
  @Input() title: string = "";
  @Input() label: string = "";
  @Input() color: string = "";
  @Input() beginDate: string = "";
  @Input() endDate: string = "";
  @Input() background: string = "";
  @Input() icon: Icon = iconsByName.default;
  @Input() active: boolean | null = null;
  @Input() headRightText: boolean | null = null;
  @Input() botRightText: string | null = null;

  correctActive() {
    if (this.active != null) {
      if (this.active) {
        return text(['Действующий', 'Vigente']);
      } else return text(['Не действует', 'No funciona']);
    } else return "";
  }

  correctPeriodical() {
    if (this.headRightText != null) {
      if (this.headRightText) {
        return text(['Переодическая', 'Peródica']);
      } else return text(['Разовая', 'Una sola vez']);
    } else return "";
  }

  protected readonly iconsByName = iconsByName;
}
