import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";



@Injectable({
  providedIn: 'root'
})
export class MobileMenuService {
  constructor(private httpClient: HttpClient) {}
  private activeMobileMenu: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  getActiveInterface() {
    return this.activeMobileMenu.value;
  }


  setActiveInterface(value: number) {
    this.activeMobileMenu.next(value);
  }
}
