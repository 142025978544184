import {Component, OnInit} from '@angular/core';
import {SectionComponent} from "../../elements/section/section.component";
import {headers, HeadersName} from "../../../model/header";
import {MenuNames} from "../../../model/menu";
import {HeaderService} from "../../../services/header.service";
import {MenuService} from "../../../services/menu.service";
import {dataDirectoriesTusk, DirectoriesTusks} from "../../../model/directoriesTusks";
import {NgForOf, NgIf} from "@angular/common";
import {defaultFilter, TableFilter, TableTemplate} from "../../../model/table";
import {TaskDirectoriesService} from "../../../services/task-directories.service";
import {ApiService} from "../../../services/api.service";
import {tablesTemplates, TemplatesNames} from "../../../model/templates";
import {ApiPath, UrlPath} from "../../../model/global";
import {Task} from "../../../model/task";
import {ButtonComponent} from "../../elements/button/button.component";
import {ActivatedRoute, Router} from "@angular/router";
import {PaginationComponent} from "../../elements/pagination/pagination.component";
import {TableComponent} from "../../elements/table/table.component";
import {DropDownListComponent} from "../../elements/drop-down-list/drop-down-list.component";
import {SaveStorage} from "../../../services/save-storage.service";
import {CalendarPageComponent} from "./pages/calendar-page/calendar-page.component";
import {text} from "../../../function/localization";

@Component({
  selector: 'app-tasks',
  standalone: true,
  imports: [
    SectionComponent,
    NgForOf,
    ButtonComponent,
    NgIf,
    PaginationComponent,
    TableComponent,
    DropDownListComponent,
    CalendarPageComponent
  ],
  templateUrl: './tasks.component.html',
  styleUrl: './tasks.component.scss'
})
export class TasksComponent implements OnInit {
  constructor(protected headerService: HeaderService,
              protected getService: ApiService,
              protected menuService: MenuService,
              protected tuskService: TaskDirectoriesService,
              private router: Router,
              private route: ActivatedRoute,
              public saveStorage: SaveStorage) {
  }

  directories: DirectoriesTusks[] = dataDirectoriesTusk;
  tableTemplate: TableTemplate = tablesTemplates[TemplatesNames.taskRegistry];
  tableFilter: TableFilter = defaultFilter;
  loading: boolean = false;
  refreshSort: number = 0;

  ngOnInit() {
    this.headerService.setCurrentHeader(headers[HeadersName.Menu]);
    this.menuService.setActiveTab(MenuNames.Tasks);
    this.changeDirectory(this.tuskService.getActiveTusk())
  }

  changePageDirectory(newTableFilter: TableFilter) {
    this.tableFilter = newTableFilter;
    this.saveStorage.setTableFilter(TemplatesNames.taskRegistry, this.tableFilter);
    this.changeDirectory(this.tuskService.getActiveTusk());
  }

  changeDirectory(index: number) {
    this.tuskService.setActiveTusk(index);
    switch (index) {
      case 0:
        this.getDataTusk<Task>(ApiPath.Task);
        this.tableTemplate = tablesTemplates[TemplatesNames.taskRegistry];
        break;
      case 1:
        this.getDataTusk<Task>(ApiPath.Task);
        this.tableTemplate = tablesTemplates[TemplatesNames.taskRegistry];
        break;
      default:
        this.getDataTusk<Task>(ApiPath.Task);
        this.tableTemplate = tablesTemplates[TemplatesNames.taskRegistry];
    }
  }

  refresh(){
    this.refreshSort++;
    this.tableFilter = new TableFilter(defaultFilter);
    this.saveStorage.setTableFilter(TemplatesNames.taskRegistry, this.tableFilter);
    this.getDataTusk<Task>(ApiPath.Task);
  }

  getDataTusk<T>(path: string) {
    this.loading = true;
    this.tableFilter = this.saveStorage.getTableFilter(TemplatesNames.taskRegistry);
    this.getService.getList<T>(path, this.tableFilter)
      .subscribe((data) => {
        this.tableTemplate.content = data.content ?? [];
        // TODO уёбищное решение, когда с методом всё устаканится — переделать
        this.tableFilter.size = data.page.size;
        this.tableFilter.totalElements = data.page.totalElements;
        this.tableFilter.totalPages = data.page.totalPages;
        this.loading = false;
      });
  }

  updFilter(filter: TableFilter){
    this.tableFilter = filter;
    if (this.tableFilter.sortRefresh) {
      this.refreshSort++;
      this.tableFilter.sortRefresh = false;
    }
    this.saveStorage.setTableFilter(TemplatesNames.taskRegistry, this.tableFilter);
    this.getDataTusk<Task>(ApiPath.Task);
  }

  openCard(activeDirectory: number, obj?: any) {
    const add = obj ? ((obj.id != undefined) ? "/" + obj.id : null) : "/add";
    if (add != undefined) {
      switch (activeDirectory) {
        case 0:
          this.router.navigate(["/" + UrlPath.TaskRegistry + add], {relativeTo: this.route}).then(r => r);
          break;
        case 1:
          this.router.navigate(["/" + UrlPath.TaskRegistry + add], {relativeTo: this.route}).then(r => r);
          break;
      }
    }
  }

    protected readonly text = text;
}
