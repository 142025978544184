<div class="legal-container">
  <div class="legal-main-container">
    <div class="legal-header">
      <app-client-subheader [clientSubheader]="subheader" (clickBack)="back()"/>
    </div>
    <div class="legal-workspace">
      <div *ngIf="contracts.length > 0">
        <div *ngFor="let contract of contracts">
          <div class="legal-contract-item">
            <app-cell-item
              [icon]="iconsByName.contractHands"
              [title]="'№ ' + contract.number + ' ('
              + correctFormattedDate(contract.beginDate ?? '') + ' - '
              + correctFormattedDate(contract.endDate ?? '') + ')'"
              [label]="getAddress(contract.objective)"
              [active]="contract.active"
              [color]="getColor()"
              (click)="openLegalContract(contract)"
            />
          </div>
        </div>
      </div>
      <div *ngIf="contracts.length == 0">
        {{ text(['У вас пока нет заключеных контрактов', 'Aún no tiene contratos concluidos']) }}
      </div>
    </div>
  </div>
</div>
