import {Component, OnInit} from '@angular/core';
import {SectionComponent} from "../../../elements/section/section.component";
import {IconComponent} from "../../../elements/icon/icon.component";
import {iconsByName} from "../../../../model/icon";
import {HeaderService} from "../../../../services/header.service";
import {headers} from "../../../../model/header";
import {MenuService} from "../../../../services/menu.service";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {text} from "../../../../function/localization";
import {UrlPath} from "../../../../model/global";
import {CellItemComponent} from "../../../elements/cell-item/cell-item.component";

@Component({
  selector: 'app-main-client',
  standalone: true,
  imports: [
    SectionComponent,
    IconComponent,
    RouterLink,
    CellItemComponent
  ],
  templateUrl: './main-client.component.html',
  styleUrl: './main-client.component.scss'
})
export class MainClientComponent implements OnInit {

  constructor(public headerService: HeaderService,
              public menuService: MenuService,
              private router: Router,
              private route: ActivatedRoute,) {}

  ngOnInit() {
    this.menuService.setActiveTab(0);
    this.headerService.setCurrentHeader(headers[1]);

  }

  openChapter(idx: number) {
    switch (idx) {
      case 0:
        this.router.navigate(["/" + UrlPath.InspectionLog], {relativeTo: this.route}).then(r => r);

        break;
      case 1:
        this.router.navigate(["/" + UrlPath.LegalContractPage], {relativeTo: this.route}).then(r => r);
        break;
    }
  }


  protected readonly iconsByName = iconsByName;
  protected readonly text = text;
}
