import {Component, OnInit} from '@angular/core';
import {FormComponent} from "../../../../../elements/form/form.component";
import {NgIf} from "@angular/common";
import {Header, headers, HeadersName} from "../../../../../../model/header";
import {FormTemplate} from "../../../../../../model/form";
import {ApiPath} from "../../../../../../model/global";
import {TableFilter, tableTemplatesNames} from "../../../../../../model/table";
import {ActivatedRoute, Router} from "@angular/router";
import {HeaderService} from "../../../../../../services/header.service";
import {ApiService} from "../../../../../../services/api.service";
import {Country} from "../../../../../../model/country";
import {NotificationService} from "../../../../../../services/notification.service";
import {formTemplates, TemplatesNames} from "../../../../../../model/templates";
import {NotificationForm, NotificationType} from "../../../../../../model/notification";
import {text} from "../../../../../../function/localization";

@Component({
  selector: 'app-country-page',
  standalone: true,
  imports: [
    FormComponent,
    NgIf
  ],
  templateUrl: './country-page.component.html',
  styleUrl: './country-page.component.scss'
})
export class CountryPageComponent implements OnInit{
  constructor( private route: ActivatedRoute,
               protected headerService: HeaderService,
               protected apiService: ApiService,
               private router: Router,
               public notificationService: NotificationService) {}

  countryId: null | string = null;
  header: Header = new Header(headers[HeadersName.Admin]);
  formTemplate: FormTemplate = new FormTemplate(formTemplates[TemplatesNames.countries]);
  loading: boolean = false;
  country: Country = new Country();

  ngOnInit() {
    this.countryId = this.route.snapshot.paramMap.get('id') ?? 'add';
    this.header.buttonBackUrl = "/admin/directories"
    if (this.countryId === 'add') {
      this.header.title = text(["Новая запись", "Nuevo registro"]);
      this.formTemplate.object = this.country;
      this.loading = false;
      this.headerService.setCurrentHeader(this.header);
    } else {
      this.loading = true;
      this.apiService.getObj<Country>(ApiPath.Country, Number(this.countryId)).subscribe((data) => {
        this.country = new Country(data) ?? new Country();
        this.header.title = text(['Редактировние страны "', 'Editar país "']) + this.country.name + '"';
        // this.header.subTitle = "<h5>Активная запись — <span class='font-color__green'>да</span></h5>";
        this.formTemplate.object = new Country(this.country);
        this.headerService.setCurrentHeader(this.header);
        // setTimeout(() => {
          this.loading = false;
        // }, 3000);
      });
    }
  }

  cancel(){
    // this.notificationService.callNotification(new NotificationForm({
    //   label: "Редактирование отменено",
    //   status: NotificationType.Info,
    //   text: `Изменения не сохранены`
    // }));
    this.router.navigateByUrl(this.header.buttonBackUrl ?? "", { skipLocationChange: false }).then();
  }

  saveCountry(country: Country): void {
    this.apiService.saveObj<Country>(ApiPath.Country, country).subscribe({
      next: () => {
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Запись успешно сохранена", "Registro guardado exitosamente"]),
          status: NotificationType.Success,
          text: text(["Страна ", "País "])
            + `\"${country.name}\"`
            + text([" успешно сохранена", " guardado exitosamente"]),
        }));
        this.cancel();
      },
      error: (err) => {
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Ошибка", "Error"]),
          status: NotificationType.Error,
          text: text(["Код ошибки ", "Código de error "]) + err.status + " " + err.error.message,
        }));
      },
    });
    // setTimeout(() => {
    //   this.notificationService.setActive(false);
    // }, 3000);
  }
}
