<header>
  <div class="header">
    <div class="header-container" [style.justify-content]="headerService.getCurrentHeader().align">
      <div class="titles">
        <span class="key">Key</span>holdermate
<!--        <span [innerHTML]="headerService.getCurrentHeader().subTitle" style="margin-top: 0.4rem;"></span>-->
      </div>
      <div class="left-side">
        <app-menu
          *ngIf="headerService.getCurrentHeader().menu || headerService.getCurrentHeader().clientMenu"
          [menu]="headerService.getMenu()"
        />
        <app-button
          *ngIf="headerService.getCurrentHeader().buttonBack"
          [styleClass]="'button-style-1'"
          [routerLink]="headerService.getCurrentHeader().buttonBackUrl ?? '/'"
          (click)="cancel()"
        > {{ text(["Назад", "Atrás"]) }}
        </app-button>
<!--        <app-login-info *ngIf="headerService.getCurrentHeader().login"/>-->
        <app-login-info/>
      </div>
    </div>
  </div>
  <div class="header-mobile">
    <div class="header-mobile-menu" (click)="openMenu = true">
      <div class="header-mobile-menu-icon">
        <app-icon [icon]="iconsByName.headerMobileMenu"/>
      </div>
    </div>
    <div class="header-mobile-title">
      {{ getHeaderTitle() }}
    </div>
    <div class="header-mobile-icon" [style]="'background: ' + getHeaderIconColor()">
      <div class="header-mobile-icon-inner">
        <app-icon [icon]="getHeaderIcon()"/>
      </div>
    </div>
  </div>
  <div class="mobile-menu" *ngIf="openMenu">
    <div class="mobile-menu-icon" (click)="openMenu = false">
      <div class="mobile-menu-icon-inner">
        <app-icon [icon]="iconsByName.headerMobileMenu"/>
      </div>
    </div>
    <div class="mobile-menu-labels">
      <app-menu
        *ngIf="headerService.getCurrentHeader().menu || headerService.getCurrentHeader().clientMenu"
        [menu]="headerService.getMenu()"
        (click)="openMenu = false"
      />
    </div>
  </div>
</header>
