export class Icon {
  id: number;
  name: string;
  alt: string;
  src: string
  constructor(value: Icon) {
    this.id = value.id ?? 0;
    this.name = value.name ?? "";
    this.alt = value.alt ?? "";
    this.src = value.src ?? "";

  }
}

export const icons: Icon[] = [
  {
    id: 0,
    name: "",
    alt: "",
    src: "",
  },
  {
    id: 1,
    name: "person",
    alt: "person",
    src: "/assets/icons/person.svg",
  },
  {
    id: 2,
    name: "logout",
    alt: "logout",
    src: "/assets/icons/logout.svg",
  },
  {
    id: 3,
    name: "check",
    alt: "check",
    src: "/assets/icons/check.svg",
  },
  {
    id: 4,
    name: "arrow",
    alt: "arrow",
    src: "/assets/icons/arrow.svg",
  },
  {
    id: 5,
    name: "plus",
    alt: "plus",
    src: "/assets/icons/plus.svg",
  },
  {
    id: 6,
    name: "context-menu",
    alt: "context-menu",
    src: "/assets/icons/context-menu.svg",
  },
  {
    id: 7,
    name: "pencil",
    alt: "pencil",
    src: "/assets/icons/pencil.svg",
  },
  {
    id: 8,
    name: "arrow-selector",
    alt: "arrow-selector",
    src: "/assets/icons/arrow-selector.svg",
  },
  {
    id: 9,
    name: "arrow-selector",
    alt: "arrow-selector",
    src: "/assets/icons/cross-close.svg",
  },
  {
    id: 10,
    name: "arrow-selector",
    alt: "arrow-selector",
    src: "/assets/icons/select-item.svg",
  },
  {
    id: 11,
    name: "arrow-selector",
    alt: "arrow-selector",
    src: "/assets/icons/filter.svg",
  },
  {
    id: 12,
    name: "arrow-selector",
    alt: "arrow-selector",
    src: "/assets/icons/none-sort.svg",
  },
  {
    id: 13,
    name: "arrow-selector",
    alt: "arrow-selector",
    src: "/assets/icons/asc.svg",
  },
  {
    id: 14,
    name: "arrow-selector",
    alt: "arrow-selector",
    src: "/assets/icons/desc.svg",
  },
  {
    id: 15,
    name: "arrow-selector",
    alt: "arrow-selector",
    src: "/assets/icons/none-sort-mini.svg",
  },
  {
    id: 16,
    name: "arrow-selector",
    alt: "arrow-selector",
    src: "/assets/icons/asc-mini.svg",
  },
  {
    id: 17,
    name: "arrow-selector",
    alt: "arrow-selector",
    src: "/assets/icons/desc-mini.svg",
  },
  {
    id: 18,
    name: "trash",
    alt: "trash",
    src: "/assets/icons/trash.svg",
  },
  {
    id: 19,
    name: "quotation-left",
    alt: "quotation-left",
    src: "/assets/icons/quotation-left.svg",
  },
  {
    id: 20,
    name: "quotation-right",
    alt: "quotation-right",
    src: "/assets/icons/quotation-right.svg",
  },
  {
    id: 21,
    name: "calendar",
    alt: "calendar",
    src: "/assets/icons/calendar.svg",
  },
  {
    id: 22,
    name: "calendar-day",
    alt: "calendar-day",
    src: "/assets/icons/calendar-day.svg",
  },
  {
    id: 23,
    name: "filter-big",
    alt: "filter-big",
    src: "/assets/icons/filter-big.svg",
  },
  {
    id: 24,
    name: "calendar-blue",
    alt: "calendar-blue",
    src: "/assets/icons/calendar-blue.svg",
  },
  {
    id: 25,
    name: "calendar-day-blue",
    alt: "calendar-day-blue",
    src: "/assets/icons/calendar-day-blue.svg",
  },
  {
    id: 26,
    name: "success",
    alt: "success",
    src: "/assets/icons/success.svg",
  },
  {
    id: 27,
    name: "sand-clock",
    alt: "sand-clock",
    src: "/assets/icons/sand-clock.svg",
  },
  {
    id: 28,
    name: "finish-flag",
    alt: "finish-flag",
    src: "/assets/icons/finish-flag.svg",
  },
  {
    id: 29,
    name: "cross-red",
    alt: "cross-red",
    src: "/assets/icons/cross-red.svg",
  },
];

export const iconsByName = {
  default: icons[0],
  person: icons[1],
  logout: icons[2],
  check: icons[3],
  arrow: icons[4],
  plus: icons[5],
  contextMenu: icons[6],
  pencil: icons[7],
  arrowSelector: icons[8],
  crossClose: icons[9],
  selectItem: icons[10],
  filter: icons[11],
  noneSort: icons[12],
  asc: icons[13],
  desc: icons[14],
  noneSortMini: icons[15],
  ascMini: icons[16],
  descMini: icons[17],
  trash: icons[18],
  quotationLeft: icons[19],
  quotationRight: icons[20],
  calendar: icons[21],
  calendarDay: icons[22],
  filterBig: icons[23],
  calendarBlue: icons[24],
  calendarDayBlue: icons[25],
  success: icons[26],
  sandClock: icons[27],
  finishFlag: icons[28],
  crossRed: icons[29],
}
