import {Component, HostListener, Input, input} from '@angular/core';
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-tool-tip',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './tool-tip.component.html',
  styleUrl: './tool-tip.component.scss'
})
export class ToolTipComponent {
  constructor() {}
  @Input() text: string = "";
  @HostListener("mouseleave") onMouseLeave() {
    this.isCursorIn = false;
    setTimeout(() => {
      if (!this.isCursorIn) {
        this.tooltip = false;
      }
    }, 200)
  }
  @HostListener("mouseover") onMouseCome() {
    if (!this.isCursorIn) {
      this.isCursorIn = true;
      setTimeout(() => {
        if (this.isCursorIn) {
          this.tooltip = true;
        }
      }, 700)
    }
  }

  isCursorIn: boolean = false;
  tooltip: boolean = false
}
