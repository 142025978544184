<div class="select-container" appClickedOutsideDirective (clickedOutside)="this.openList = false">
  <div class="select-container-inner" [class]="{'disable': disable}" (click)="open()">
    <div class="selected-value">{{ getCorrectLabel(value, labelKey, specifyLabelType) }}</div>
    <div class="select-selector">
      <app-icon
        [icon]="iconsByName.arrowSelector"
        [class]="{'arrow-selector-active':openList}"
        class="arrow-selector"/>
    </div>
  </div>
  <div *ngIf="openList" class="input-list">
    <div *ngIf="search" class="input-list-search">
      <div class="input-list-search-inner">
        <input [(ngModel)]="tableFilter.query" (keyup)="getDataByMethod()" value="{{ tableFilter.query }}">
        <div class="cross-close" (click)="$event.stopPropagation()">
          <app-icon (click)="clearSearch()" [icon]="iconsByName.crossClose" class="checkbox-img"/>
        </div>
      </div>
    </div>
    <div *ngIf="loading">
      <div class="input-list-el">
        {{ text(["Идёт загрузка данных", "Carga de datos en curso"]) }}
      </div>
    </div>
    <div *ngIf="!loading">
      <div
        *ngFor="let option of options"
        class="input-list-el"
        (click)="selectItem(option)">
        {{ getCorrectLabel(option, labelKey, specifyLabelType) }}
      </div>
    </div>
    <div  *ngIf="(options.length < totalElements) && !loading" class="input-list-el-message">
      {{ text(["И ещё", "Y"]) }}
      {{ totalElements - options.length }}
      {{ text(["записей", "más registros"]) }}
    </div>
    <div *ngIf="(options.length == 0) && !loading" class="input-list-el-message">
      {{ text(["Ничего не найдено", "No se encontró nada"]) }}
    </div>
  </div>
</div>


