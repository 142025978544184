<div class="login-info-container">
  <div class="icon-container-login">
    <app-icon [icon]="icons[1]" (click)="openMenu = !openMenu"/>
  </div>
  <div class="system-settings" *ngIf="openMenu" appClickedOutsideDirective (clickedOutside)="this.openMenu = false">
    <div class="system-header-user-container" *ngIf="loginService.getLoginInfo() != undefined">
      <div class="shu-container-inner">
        <div class="shu-info">
          {{ text(['Пользователь ', 'Usuario ']) + loginService.getLoginInfo()?.sub }}
          <app-icon [icon]="iconsByName.person" />
        </div>
      </div>
      <hr>
    </div>
    <div class="system-settings-content">
      <div class="sst-title">
        {{ text(['Язык', 'Idioma']) }}
      </div>
      <div class="lang-item-container">
        <div *ngFor="let item of languagesLabels; index as i" class="lang-item">
          <div class="lang-item-el">
            <app-checkbox
              [value]="item.active"
              [radioSelection]="true"
              (valueOutput)="setActiveLang(i)"
            />
            <label>{{ text(item.labels) }}</label>
          </div>
        </div>
      </div>
      <hr>
      <div class="sst-title">
        {{ text(["Локация", "Ubicación"]) }}
      </div>
      <div class="lang-item-container">
        <div *ngFor="let item of locationLabels; index as j" class="lang-item">
          <div class="lang-item-el">
            <app-checkbox
              [value]="item.active"
              [radioSelection]="true"
              (valueOutput)="setActiveLoc(j)"
            />
            <label>{{ text(item.labels) }}</label>
          </div>
        </div>
      </div>
      <div class="system-header-user-switcher" *ngIf="loginService.getLoginInfo() != undefined">
        <div *ngIf="this.loginService.getUserRole(false)">
          <div *ngIf="interfaceService.getActiveInterface() == 0" class="shus-el" (click)="switchInterface()">
            {{ text(['Перейти в режим "Клиент"', 'Cambiar al modo "Cliente"']) }}
            <div class="shu-icon">
              <app-icon [icon]="iconsByName.bluePerson"/>
            </div>
          </div>
          <div *ngIf="interfaceService.getActiveInterface() == 1" class="shus-el shus-el-orange" (click)="switchInterface()">
            {{ text(['Перейти в режим "Оператор"', 'Cambiar al modo "Operador"']) }}
            <div class="shu-icon">
              <app-icon [icon]="iconsByName.clocks"/>
            </div>
          </div>
        </div>
      </div>
      <div class="system-header-user-container system-header-user-logout-container" *ngIf="loginService.getLoginInfo() != undefined">
        <hr>
        <div class="shu-container-inner" (click)="loginService.logout()">
          <div class="shu-info">
            {{ text(['Выйти', 'Cerrar sesión']) }}
          </div>
          <div class="shu-icon">
            <app-icon [icon]="icons[2]"/>
          </div>
        </div>
      </div>
      <div *ngIf="isChange()">
        <hr>
        <div class="system-setting-save-button">
          <app-button class="sss-btn" (click)="save()">
            {{ text(["Сохранить изменения", "Guardar cambios"]) }}
          </app-button>
        </div>
      </div>
    </div>
  </div>
</div>

