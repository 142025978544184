import {text} from "../function/localization";

export class Header {
  id?: number;
  name: string = "default";
  title?: string = "";
  subTitle?: string = "";
  align?: string = "";
  menu?: boolean = false;
  login?: boolean = false;
  buttonBack?: boolean = false;
  buttonBackUrl?: string = "/";
  constructor(value: Header) {
    this.id = value.id;
    this.title = value.title ?? "";
    this.align = value.align ?? "";
    this.menu = value.menu ?? false;
    this.login = value.login ?? false;
    this.buttonBack = value.buttonBack ?? false;
    this.buttonBackUrl = value.buttonBackUrl ?? "";
  }
}

export const headers: Header[] = [
  {
    id: 0,
    name: "menu",
    title: "",
    align: "left",
    menu: true,
    login: true,
  },
  {
    id: 1,
    name: "login",
    title: text(["Личный кабинет оператора(CRM)", "Cuenta personal del operador(CRM)"]),
    align: "center",
    menu: false,
    login: false,
  },
  {
    id: 2,
    name: "form",
    title: "",
    align: "left",
    menu: false,
    login: false,
    buttonBack: true,
    buttonBackUrl: "",
  }
];

export enum HeadersName  {
  Menu,
  Login,
  Form,
}
