<app-section>
  <div class="admin-container">
    <div class="admin-above-panel">
      <div class="admin-menu">
        <div *ngFor="let item of directories; index as i"
             class="admin-menu-item"
             (click)="activeDirectory = i"
             [class]="{ 'admin-menu-item-active': activeDirectory == i }"
        >
          {{ item }}
        </div>
      </div>
      <div *ngIf="activeDirectory == 0" class="button-container">
        <app-button class="operation-button" (click)="getData()">
          {{ text(["Обновить", "Actualizar"]) }}
        </app-button>
        <app-button class="operation-button" [styleClass]="'button-style-1'">
          {{ text(["Очистить", "Limpiar"]) }}
        </app-button>
        <app-button class="operation-button" [routerLink]="addLink">
          {{ text(["Создать", "Crear"]) }}
        </app-button>
      </div>
    </div>
    <div *ngIf="activeDirectory == 0" class="admin-workspace">
      <app-pagination [tableFilter]="tableFilter" (tableFilterOut)="changePage($event)"/>
      <app-table
        class="users-table"
        [tableTemplate]="tableTemplate"
        [loading] = loading
        (clickRow)="openCard($event)"/>
      <app-pagination [tableFilter]="tableFilter" (tableFilterOut)="changePage($event)"/>
    </div>
    <div *ngIf="activeDirectory == 1" class="admin-settings">
      <h4>{{ text(["Язык", "Idioma"]) }}</h4>
      <div *ngFor="let item of languagesLabels; index as i" class="lang-item">
        <app-checkbox
          [value]="item.active"
          [radioSelection]="true"
          (valueOutput)="setActiveLang(i)"
        />
        <label>{{ text(item.labels) }}</label>
      </div>
      <h4>{{ text(["Локация", "Ubicación"]) }}</h4>
      <div *ngFor="let item of locationLabels; index as j" class="lang-item">
        <app-checkbox
          [value]="item.active"
          [radioSelection]="true"
          (valueOutput)="setActiveLoc(j)"
        />
        <label>{{ text(item.labels) }}</label>
      </div>
      <app-button class="mt-3" style="width: 200px"  (click)="save()">
        {{ text(["Сохранить", "Guardar"]) }}
      </app-button>
    </div>
  </div>
</app-section>
