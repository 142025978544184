import {Component, Input, OnInit} from '@angular/core';
import {getAddress} from "../../../../../../function/getAddress";
import {TableComponent} from "../../../../../elements/table/table.component";
import {PaginationComponent} from "../../../../../elements/pagination/pagination.component";
import {ButtonComponent} from "../../../../../elements/button/button.component";
import {ApiService} from "../../../../../../services/api.service";
import {LoadingComponent} from "../../../../../elements/loading/loading.component";
import {NgForOf, NgIf} from "@angular/common";
import {SectionComponent} from "../../../../../elements/section/section.component";
import {HeaderService} from "../../../../../../services/header.service";
import {ActivatedRoute, Router} from "@angular/router";
import {headers} from "../../../../../../model/header";
import {MenuService} from "../../../../../../services/menu.service";
import {ClientSubheaderComponent} from "../../../../../elements/client-subheader/client-subheader.component";
import {ClientSubheader} from "../../../../../../model/clientSubheader";
import {iconsByName} from "../../../../../../model/icon";
import {text} from "../../../../../../function/localization";
import {Task} from "../../../../../../model/task";
import {ApiPath} from "../../../../../../model/global";
import {formatDate} from "../../../../../../function/formatDate";
import {Contract} from "../../../../../../model/contract";
import {IconComponent} from "../../../../../elements/icon/icon.component";
import {CellItemComponent} from "../../../../../elements/cell-item/cell-item.component";

@Component({
  selector: 'app-legal-contract-page',
  standalone: true,
  imports: [
    TableComponent,
    PaginationComponent,
    ButtonComponent,
    LoadingComponent,
    NgIf,
    SectionComponent,
    ClientSubheaderComponent,
    IconComponent,
    NgForOf,
    CellItemComponent
  ],
  templateUrl: './legal-contract-page.component.html',
  styleUrl: './legal-contract-page.component.scss'
})
export class LegalContractPageComponent implements OnInit {

  constructor(public headerService: HeaderService,
              public menuService: MenuService,
              protected apiService: ApiService,
              private route: ActivatedRoute,
              private router: Router) {}

  contractId: string = "";
  contract: Contract = new Contract();
  loading: boolean = false;

  subheader: ClientSubheader = {
    title: text(['Правовая информация', 'Información legal']),
    breadcrumbs: [
      {
        name: text(['Главное меню', 'Menú principal']),
        link: "/client",
      },
      {
        name: text(['Правовая информация', 'Información legal']),
        link: "",
      },
    ],
    icon: iconsByName.contract75,
    backLink: true,
    color: "rgba(77, 202, 118, 1)",
  }

  ngOnInit() {
    this.headerService.setCurrentHeader(headers[1]);
    this.menuService.setActiveTab(2);
    this.contractId = this.route.snapshot.paramMap.get('id') ?? '';
    this.getContract();
  }

  getContract(){
    this.loading = true;
    this.apiService.getObj<Contract>(ApiPath.ClientContractById, Number(this.contractId), false).subscribe((data) => {
      this.contract = new Contract(data);
      const title: string = `Договор №${this.contract.number} (${formatDate(this.contract.beginDate ?? "")} -
       ${formatDate(this.contract.endDate ?? "")}) - ${this.contract.terminated ? text(['Расторгнутый', 'Disuelto']) :
        text(['Действующий', 'Activo'])}`;
      const subTitle = `Договор №${this.contract.number}`;
      this.subheader.title = title;
      this.subheader.breadcrumbs.push({
        name: subTitle,
        link: "",
      });
      this.loading = false;
    });
  }

  back(){
    this.router.navigate(["/client/legal-information"],{relativeTo: this.route}).then(r => r);
  }

  getCorrectPeriod(num: number, per: string) {
    let z = '';
    if (num == 1) {
      z = text([' раз в ', 'vez ']);
    }
    if (num >= 2 && num < 5) {
      z = text([' раза в ', 'veces ']);
    }
    if (num >= 5) {
      z = text([' раз в ', 'vez ']);
    }
    switch (per) {
      case "MONTH":
        return num + z + text(['месяц', ' al mes']);
      case "WEEK" :
        return num + z + text(['неделю', ' por semana']);
      default: return '';
    }
  }

  protected readonly getAddress = getAddress;
  protected readonly text = text;
  protected readonly iconsByName = iconsByName;
  protected readonly formatDate = formatDate;
}
