import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForOf} from "@angular/common";
import {Menu, menuItems} from "../../model/menu";
import {MenuService} from "../../services/menu.service";
import {RouterLink} from "@angular/router";
import {HeaderService} from "../../services/header.service";
import {IconComponent} from "../elements/icon/icon.component";
import {Icon, iconsByName} from "../../model/icon";
import {MobileMenuService} from "../../services/mobile-menu.service";

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    NgForOf,
    RouterLink,
    IconComponent,
  ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent implements OnInit{
  constructor(public menuService: MenuService,
              public headerService: HeaderService,
              protected mobileMenuService: MobileMenuService) {
  }

  @Input() menu: Menu[] = [];
  // @Input() icons: Icon[] = [
  //   iconsByName.mobileHome,
  //   iconsByName.spanner,
  //
  // ];

  ngOnInit() {}


  changeTab(id: number) {
    this.menuService.setActiveTab(id);
  }

  getIcon(i: number) {
    switch (i) {
      case 0:
        return iconsByName.mobileHome;
      case 1:
        return iconsByName.spanner;
      case 2:
        return iconsByName.contract75;
      case 3:
        return iconsByName.bill;
      case 4:
        return iconsByName.envelop;
      default:
        return iconsByName.default;
    }
  }

  getColor(i: number) {
    switch (i) {
      case 0:
        return '#B070E6';
      case 1:
        return '#3ABEB1';
      case 2:
        return '#4DCA76';
      case 3:
        return '#4192D9';
      case 4:
        return '#B070E6';
      default:
        return '';
    }
  }

  setHeaderIcon(i: number) {
    this.mobileMenuService.setActiveInterface(i);
  }


  protected readonly menuItems = menuItems;
  protected readonly iconsByName = iconsByName;
}
