export enum ApiPath {
  Default = "",
  User = "dictionaries/user",
  Country ="dictionaries/country",
  Currency ="dictionaries/currency",
  Document ="dictionaries/document-type",
  ObjectType ="dictionaries/objective-type",
  Contract = "contract",
  Measure = "dictionaries/measure",
  Client = "dictionaries/client",
  Objective = "dictionaries/objective",
  Tariff = "dictionaries/tariff",
  ServiceType = "dictionaries/service-type",
  DefectType = "dictionaries/defect-type",
  Task = "task",
  TaskEngineer = "task/engineer",
  UploadFile = "contract/files/upload",
  DownloadFile = "contract/files/download",
  DownloadFileDefects = "task/defect/files/download",
  DeleteFile = "contract/files/delete",
  ClientContract = "contract/client",
  ClientTasksByContract = "task/client/by-contract",
  ClientTasksById = "task/client",
  ClientContractById = "contract/client",
  ClientContractFind = "contract/client/find",
  DefectFile = "task/client/defect/files/download",
}

export enum UrlPath {
  Country ="admin/directories/country",
  Currency ="admin/directories/currency",
  Document ="admin/directories/document-type",
  ObjectType ="admin/directories/objective-type",
  Measure = "admin/directories/measure",
  Client = "admin/directories/client",
  Objective = "admin/directories/objective",
  Tariff = "admin/directories/tariff",
  ServiceType = "admin/directories/service-type",
  DefectType = "admin/directories/defect-type",
  TaskRegistry = "admin/tasks",
  LegalContractPage = "client/legal-information",
  InspectionLog = "client/inspection-log",
}


