<div class="cell-item-container" [style]="'background: ' + background">
  <div class="cell-item-icon">
    <div class="icon-container" [style]="'background: ' + color">
      <app-icon class="icon" [icon]="icon"/>
    </div>
  </div>
  <div class="cell-item-info">
    <div *ngIf="title?.length > 0" class="cell-item-info-header">
      <h5>{{ title }}</h5>
      <div
        *ngIf="headRightText != null"
        class="cell-item-periodical"
        [class]="{ 'cell-item-periodical-true': headRightText }"
      >
        {{ correctPeriodical() }}
      </div>
    </div>
    <div *ngIf="title?.length == 0" class="cell-item-info-header">
      <h5>{{ beginDate + " — " + endDate }}</h5>
    </div>
<!--    <div *ngIf="title?.length > 0" class="cell-item-divider"></div>-->
    <div class="cell-item-divider"></div>
    <div class="cell-item-bottom">
      <p>{{ label }}</p>
      <p *ngIf="botRightText != null" class="cell-item-period">
        {{ botRightText }}
      </p>
    </div>
    <div *ngIf="active != null" class="cell-active" [class]="{ 'cell-active-green': active }">
      {{ correctActive() }}
    </div>
  </div>
</div>
