<div class="client-subheader-container">
  <div class="icon-container" [style]="'background:' + clientSubheader?.color">
      <app-icon class="icon" [icon]="clientSubheader?.icon ?? iconsByName.default"/>
  </div>
  <div class="titles-container">
    <div class="title-text">
      <h2>{{ clientSubheader?.title }}</h2>
    </div>
    <div class="breadcrumbs">
      <div *ngFor="let breadcrumb of clientSubheader?.breadcrumbs; index as i"
           (click)="route(breadcrumb)"
           class="breadcrumbs-item"
      ><p>{{ breadcrumb.name }}</p><app-icon *ngIf="i < clientSubheader?.breadcrumbs.length - 1" class="arrows" [icon]="iconsByName.arrows"/>
      </div>
    </div>
  </div>
  <div *ngIf="clientSubheader?.backLink" class="buttons-container">
    <app-button (click)="action()">{{ text(['Назад', 'Atrás']) }}</app-button>
  </div>
</div>
