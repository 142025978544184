import {User} from "./user";
import {DocumentType} from "./documents";

export class Client {
  id?:              number;
  documentType: DocumentType = new DocumentType();
  documentNumber:  string = "";
  telegramAccount?: string = "";
  whatsappAccount?: string = "";
  viberAccount?:    string = "";
  user:            User = new User();
  constructor(value?: Client) {
    if(value?.id != undefined) this.id = value?.id;
    this.documentType = value?.documentType ?? new DocumentType();
    this.documentNumber = value?.documentNumber ?? "";
    this.telegramAccount = value?.telegramAccount;
    this.whatsappAccount = value?.whatsappAccount;
    this.viberAccount = value?.viberAccount;
    this.user = value?.user ?? new User();
  }
}

export enum ClientStatus {
  existed,
  new,
}

export const clientStatusMap = new Map<ClientStatus, string[]>([
  [ClientStatus.existed, ["Зарегистрирован", "Registrado"]],
  [ClientStatus.new, ["Новый", "Nuevo"]],
]);
