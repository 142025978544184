<div class="page-login-container">
    <div class="login-box">
      <form [formGroup]="loginForm"  (keyup.enter)="login()">
        <div class="form-group">
          <div class="form-item">
            <div class="input-login">
              <label>{{ text(["Логин:", "Inicio de sesión:"]) }}</label>
              <input id="username" formControlName="username" required>
              <div *ngIf="username?.invalid && (username?.dirty || username?.touched)" class="error">
                {{ text(["Обязательное поле!", "Campo obligatorio!"]) }}
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="input-login">
              <label>{{ text(["Пароль:", "Contraseña:"]) }}</label>
              <input type="password" id="password" name="password" formControlName="password" required
                     autocomplete="on">
              <div *ngIf="password?.invalid && (password?.dirty || password?.touched)" class="error">
                {{ text(["Обязательное поле!", "Campo obligatorio!"]) }}
              </div>
            </div>
          </div>
          <div class="form-item justify-content-center">
            <app-button styleClass="width: 100%" (click)="login()" [disabled]="loginForm.invalid">
              {{ text(["Войти", "Entrar"]) }}
            </app-button>
          </div>
        </div>
        <div *ngIf="showError" class="alert alert-danger">
          {{ text(["Ошибка входа: ", "Error de Inicio de sesión:"]) }} {{ loginError }}
        </div>
        <div *ngIf="showSuccess" class="alert alert-success">
          {{ text(["Успешный вход", "Inicio de sesión exitoso"]) }}
        </div>
      </form>
    </div>
</div>
