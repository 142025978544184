<div class="legal-contract-container">
  <div class="legal-contract-header">
    {{ "№ " + number + " (" + beginDate + " - " + endDate + ")" }}
  </div>
  <hr>
  <div class="legal-contract-footer">
    <div class="legal-contract-address">
<!--      {{ address + " кв. " + flatNumber }}-->
      {{ getAddress(objective) }}
    </div>
    <div class="legal-contract-status" [class]="{ 'legal-contract-status-green': active }">
      {{ checkActive() }}
    </div>
  </div>
</div>
