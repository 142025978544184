import {BehaviorSubject} from "rxjs";
import {TemplatesNames} from "../model/templates";
import {defaultFilter, TableFilter} from "../model/table";
import {Injectable} from "@angular/core";
import {Calendar} from "../model/calendar";

@Injectable({
  providedIn: 'root'
})
export class SaveStorage {

  constructor() {}

  public modal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  setData(key: string, obj: any){
    const json = JSON.stringify(obj);
    localStorage.setItem(key, json);
  }

  getTableFilterData(key: string, filter: TableFilter){
    const x = localStorage.getItem(key);
    if (x != undefined) {
      return JSON.parse(x);
    } else {
      this.setData(key, filter);
      return new TableFilter(filter);
    }
  }

  setInterfaceService(n: number) {
    const key = 'interface:';
    this.setData(key, n);
  }

  getInterfaceService() {
    const x = localStorage.getItem("interface:");
    return  x ? JSON.parse(x) : null;
  }

  setTableFilter(name: TemplatesNames, tableFilter: TableFilter){
      const key = "table:" + name;
      this.setData(key, tableFilter);
  }

  getTableFilter(name: TemplatesNames, filter?: TableFilter){
    const f = filter ? filter : defaultFilter;
    const key = "table:" + name;
    return this.getTableFilterData(key, f);
  }

  setCalendar(calendarData: Calendar) {
    this.setData("calendar", calendarData);
  }

  getCalendar() {
    const x = localStorage.getItem("calendar");
    if (x != undefined) {
      return new Calendar(JSON.parse(x));
    } else {
      return new Calendar();
    }
  }

  setLanguage(idx: number){
    this.setData("language", idx);
  }

  setLocation(idx: number){
    this.setData("location", idx);
  }

  getLocation(){
    const code = localStorage.getItem("location");
    return  code ? JSON.parse(code) : null;
  }

  getLanguage(){
    const code = localStorage.getItem("language");
    return  code ? JSON.parse(code) : null;
  }
}

