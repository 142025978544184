import {FormTemplate} from "./form";
import {ApiPath} from "./global";
import {TemplatesNames} from "./templates";
import {text} from "../function/localization";

export enum ModalType {
  Form,
  Table,
  Confirm,
  ImagesFullScreen,
}

export class ModalOptions {
  key: string;
  type: ModalType;
  title: string;
  titleAdd: string;
  templatesNames?: TemplatesNames;
  formTemplate?: FormTemplate;
  method?: ApiPath;
  obj?: any;
  isCreateNew?: boolean;
  constructor(value?: ModalOptions) {
    this.key = value?.key ?? "";
    this.type = value?.type ?? ModalType.Form;
    this.title = value?.title ?? text(["Запись", "Grabación"]);
    this.titleAdd = value?.titleAdd ?? text(["Запись", "Grabación"]);
    this.templatesNames = value?.templatesNames ;
    this.formTemplate = value?.formTemplate ;
    this.method = value?.method;
    this.obj = value?.obj;
    this.isCreateNew = value?.isCreateNew;
  }
}
