import {Component, HostListener, Input, OnInit} from '@angular/core';
import {ButtonComponent} from "../../../../../elements/button/button.component";
import {IconComponent} from "../../../../../elements/icon/icon.component";
import {iconsByName} from "../../../../../../model/icon";
import {TableComponent} from "../../../../../elements/table/table.component";
import {LoadingComponent} from "../../../../../elements/loading/loading.component";
import {NgForOf, NgIf} from "@angular/common";
import {DropDownListComponent} from "../../../../../elements/drop-down-list/drop-down-list.component";
import {ApiPath} from "../../../../../../model/global";
import {defaultFilter, TableFilter} from "../../../../../../model/table";
import {SpecifyLabelType} from "../../../../../../function/getSpecifyLabel";
import {FormComponent} from "../../../../../elements/form/form.component";
import {FormTemplate} from "../../../../../../model/form";
import {formTemplates, TemplatesNames} from "../../../../../../model/templates";
import {ApiService} from "../../../../../../services/api.service";
import {
  formatDate,
  formatDateYYYYMMDD, formatStringToDate,
  getFirstDayOfWeek,
  getFormattedDate
} from "../../../../../../function/formatDate";
import {WeakDirection} from "../../../../../../model/weakDirection";
import {ApiTask} from "../../../../../../services/get-tasks.service";
import {daysOfWeak, Task, TaskGroupedByAddress, TaskStatuses, TaskWeak} from "../../../../../../model/task";
import {SaveStorage} from "../../../../../../services/save-storage.service";
import {User} from "../../../../../../model/user";
import {
  DndDraggableDirective,
  DndDragImageRefDirective,
  DndDropEvent,
  DndDropzoneDirective,
  DndHandleDirective, DndPlaceholderRefDirective
} from "ngx-drag-drop";
import {NotificationForm, NotificationType} from "../../../../../../model/notification";
import {NotificationService} from "../../../../../../services/notification.service";
import {TaskServices} from "../../../../../../model/taskServices";
import {ToolTipComponent} from "../../../../../elements/tool-tip/tool-tip.component";
import {Calendar} from "../../../../../../model/calendar";
import {MapComponent} from "../../../../../elements/map/map.component";
import {MapSettings} from "../../../../../../model/map";
import {LngLatLike} from "maplibre-gl";
import {text} from "../../../../../../function/localization";

@Component({
  selector: 'app-calendar-page',
  standalone: true,
  imports: [
    ButtonComponent,
    IconComponent,
    TableComponent,
    LoadingComponent,
    NgIf,
    DropDownListComponent,
    FormComponent,
    NgForOf,
    DndDraggableDirective,
    DndDropzoneDirective,
    DndHandleDirective,
    DndDragImageRefDirective,
    DndPlaceholderRefDirective,
    ToolTipComponent,
    MapComponent
  ],
  templateUrl: './calendar-page.component.html',
  styleUrl: './calendar-page.component.scss'
})
export class CalendarPageComponent implements OnInit {
  constructor(protected apiService: ApiService,
              protected apiTask: ApiTask,
              public notificationService: NotificationService,
              public saveStorage: SaveStorage) {
  }

  @Input() loading: boolean = false;

  engineerValue: any = null;

  formTemplate: FormTemplate = new FormTemplate(formTemplates[TemplatesNames.taskDate]);

  tableFilterEngineers: TableFilter = new TableFilter({
    size: 100,
    query: "",
    sort: "",
    page: 0,
    totalPages: 0,
    totalElements: 0,
    role: "ROLE_ENGINEER",
  });

  tableFilterPlannedTasks: TableFilter = new TableFilter({
    size: 1000,
    query: "",
    sort: "planDate:ASC",
    page: 0,
    totalPages: 0,
    totalElements: 0,
    role: "",
    engineerIds: [],
    statuses: ["PLANNED", "IN_PROGRESS", "FINISHED", "CANCELLED"],
  })

  onlyDay: boolean = false;

  date: Date = new Date();
  firstDate: Date = new Date();
  lastDate: Date = new Date();

  tasks: Task[] = [];
  tasksGrouped: TaskGroupedByAddress[] = [];

  engineer: User | undefined = undefined;
  selectedWeak: TaskWeak[] = [];
  plannedTask: Task[] = [];
  selectedTask: Task[] = [];
  dragTask: Task = new Task();
  isDragging: boolean = false;

  fullList: number = 4;

  calendar: Calendar = new Calendar();

  loadingEngineer: boolean = false;

  mapSetting: MapSettings = new MapSettings();

  isMap: boolean = false;
  isFirstLoading: boolean = true;

  ngOnInit() {
    this.loadingEngineer = true;
    this.calendar = this.saveStorage.getCalendar();
    this.loadingEngineer = false;
    setTimeout(() => this.refreshMap(), 500);
  }


  getTasks() {
    const first = formatDateYYYYMMDD(this.firstDate);
    const last = formatDateYYYYMMDD(this.lastDate);
    this.apiTask.getTasksByPeriod(first, last)
      .subscribe((data) => {
        this.tasks = data ?? [];
        this.getGrouped();
        const filter = new TableFilter(this.tableFilterPlannedTasks);
        filter.planDateFrom = first;
        filter.planDateTo = last;
        filter.engineerIds = [];
        this.selectedWeak = [];
        if (this.engineer) {
          if (this.engineer.id != null) {
            filter.engineerIds.push(this.engineer.id);
          }
          this.apiService.getList<Task>(ApiPath.Task, filter).subscribe((data) => {
            this.plannedTask = data.content ?? [];
            this.selectedWeak = [];
            for (let i = 0; i < 7; i++) {
              const currDate = new Date(this.firstDate);
              currDate.setDate(this.firstDate.getDate() + i);
              const tasks = this.plannedTask.map(v => new Task(v))
                .filter((f) => f.planDate == formatDateYYYYMMDD(currDate));
              // const tasks = this.plannedTask.map(v => new Task(v));
              this.selectedWeak.push(new TaskWeak({
                day: currDate.getDate().toString() ?? "",
                dayOfWeak: daysOfWeak.get(i) ?? "",
                tasks: tasks ?? [],
                date: currDate
              }));
            }
          });
        } else {
          for (let i = 0; i < 7; i++) {
            const currDate = new Date();
            currDate.setDate(this.firstDate.getDate() + i);
            this.selectedWeak.push(new TaskWeak({
              day: currDate.getDate().toString() ?? "",
              dayOfWeak: daysOfWeak.get(i) ?? "",
              tasks: [],
              date: currDate
            }));
          }
        }
      });
    this.updCalendar();
    setTimeout(() => this.refreshMap(), 100);
  }

  selectEngineer(engineer: User) {
    if (!this.isFirstLoading) {
      this.engineer = engineer;
      this.changeDate(WeakDirection.Current)
    } else {
      this.onlyDay = this.calendar.onlyDay;
      this.date = new Date(this.calendar.date);
      this.engineer = engineer;
      this.isFirstLoading = false;
      this.changeDate(WeakDirection.Current);
    }
  };
  // this.getAllTasks(formatDateYYYYMMDD(this.firstDate), formatDateYYYYMMDD(this.lastDate), engineer.id ?? 0);
  getGrouped() {
    this.tasksGrouped = [];
    const taskSorted = this.tasks.sort((a, b) => {
      const c = a.contract?.objective?.address ?? "";
      const d = b.contract?.objective?.address ?? "";
      return c.localeCompare(d);
    });
    let indexGroup = 0;
    taskSorted.forEach((value, index) => {
      if (index > 0) {
        const prev = this.tasks[index - 1].contract?.objective?.address;
        const curr = this.tasks[index].contract?.objective?.address;
        if (prev === curr) {
          this.tasksGrouped[indexGroup].tasks.push(value);
        } else {
          indexGroup++;
          this.tasksGrouped.push(new TaskGroupedByAddress());
          this.tasksGrouped[indexGroup].label = curr ?? "";
          this.tasksGrouped[indexGroup].tasks.push(value);
        }
      } else {
        this.tasksGrouped.push(new TaskGroupedByAddress({
          label: value.contract?.objective?.address ?? "",
          tasks: [],
        }));
        this.tasksGrouped[indexGroup].tasks.push(value);
      }
    });
  }

  setToday() {
    this.date = new Date();
    this.changeDate(WeakDirection.Current);
  }

  changeDate(direction: WeakDirection) {
    this.fullList = 4;
    let days = 0;
    if (this.onlyDay) {
      switch (direction) {
        case WeakDirection.Current:
          days = 0;
          break;
        case WeakDirection.Minus:
          days = -1;
          break;
        case WeakDirection.Plus:
          days = 1;
          break;
      }
      this.date.setDate(this.date.getDate() + days);
      this.firstDate = this.date;
      this.lastDate = this.date;
    } else {
      switch (direction) {
        case WeakDirection.Current:
          days = 0;
          break;
        case WeakDirection.Minus:
          days = -7;
          break;
        case WeakDirection.Plus:
          days = 7;
          break;
      }
      this.date.setDate(this.date.getDate() + days);
      this.firstDate = getFirstDayOfWeek(this.date);
      this.lastDate = new Date(this.firstDate);
      this.lastDate.setDate(this.lastDate.getDate() + 6);
    }
    this.getTasks();
  }

  changeMode(mode: boolean) {
    this.onlyDay = mode;
    this.changeDate(WeakDirection.Current);
  }


  draggableSettings = {
    data: "myDragData",
    effectAllowed: "all",
    disable: false,
    handle: false
  };

  onDragTask(task: Task) {
    this.isDragging = true;
    this.dragTask = new Task(task);
  }

  onDragEnd (){
    this.isDragging = false;
  }

  getSingeTask() {
    return  this.selectedWeak[0] ?? new TaskWeak();
  }
  onDropAssignTask(taskWeak: TaskWeak) {
    if (this.engineer != undefined) {
      const task = new Task(this.dragTask);
      task.planDate = formatDateYYYYMMDD(taskWeak.date);
      task.status = "PLANNED";
      task.engineer = new User(this.engineer);
      this.assignTask(task);
    }
  }

  onDropDissociateTask() {
    if (this.engineer != undefined) {
      const task = new Task(this.dragTask);
      task.planDate = "";
      task.status = "NOT_PLANNED";
      task.engineer = null;
      this.assignTask(task);
    }
  }

  disableDropZoneNotPlanned(status: string) {
    return this.dragTask.status == status;
  }


  getSingleDate() {
    const x = formatDateYYYYMMDD(this.selectedWeak[0]?.date);
    return x ?? "";
  }
  disableDropZonePlanned(planDate: string) {
    return this.dragTask.planDate == planDate || this.dragTask.beginDate > planDate || this.dragTask.endDate < planDate;
  }

  assignTask(task: Task) {
    this.apiService.saveObj<Task>(ApiPath.Task, task).subscribe({
      next: () => {
        this.changeDate(WeakDirection.Current);
        if (task.status == "NOT_PLANNED") {
          this.notificationService.callNotification(new NotificationForm({
            label: `Задача снята`,
            status: NotificationType.Info,
            text: `Задача №${task.id} успешно переведена в "Задание для планирования"`
          }));
        } else {
          this.notificationService.callNotification(new NotificationForm({
            label: "Задача успешно назначена",
            status: NotificationType.Success,
            text: `Задача №${task.id} успешно назначена на ${formatDate(new Date(task.planDate ?? ''))},
             инженер — ${task.engineer?.getFIO()}`
          }));
        }
      },
      error: (err) => {
        this.notificationService.callNotification(new NotificationForm({
          label: "Ошибка",
          status: NotificationType.Error,
          text: `Код ошибки ${err?.status ?? JSON.stringify(err)}`
        }));
      },
    });
  }

  alertTask() {
    if (this.engineer == undefined) {
      this.notificationService.callNotification(new NotificationForm({
        label: "Предупреждение",
        status: NotificationType.Info,
        text: `Для назначения необходимо выбрать инженера`
      }));
    }
  }

  allowedDatesStyle(date?: Date) {
    const d = formatDateYYYYMMDD(date);
    const drop = (this.isDragging && this.dragTask.beginDate <= d && this.dragTask.endDate >= d) ? 'drop-allowed' : '';
    // const weekend =  date?.getDay() == 0 ? "weekend" : "";
    const weekend =  date?.getDay() == 0 || date?.getDay() == 6 ? "weekend" : "";
    return drop.length > 0 ? drop : weekend;
  }

  getAdditionalServices(services: TaskServices[]) {
    return services.filter((f) => f.isAdditional);
  }

  openDay(day: any) {
    this.onlyDay = true;
    this.date = day;
    this.changeDate(WeakDirection.Current);
  }

  updCalendar() {
    setTimeout(() => {
      this.calendar.onlyDay = this.onlyDay;
      this.calendar.date = this.date;
      this.calendar.engineerId = this.engineer?.id ?? -1;
      this.saveStorage.setCalendar(this.calendar);
    }, 300);
  }

  clickTask(event: any, task: Task){
    const ctrl = event.metaKey != undefined && (event.ctrlKey || event.metaKey);
    if (this.isSelectedTask(task)) {
      if (ctrl) {
        const index = this.selectedTask.findIndex(f => f.id == task.id);
        this.selectedTask.splice(index, 1);
      } else {
        this.selectedTask = [];
      }
    } else {
      if (ctrl) {
        this.selectedTask.push(task);
      } else {
        this.selectedTask = [];
        this.selectedTask.push(task);
      }
    }
    this.refreshMap();
  }

  isSelectedTask(task: Task){
    const f = this.selectedTask.findIndex(f => f.id == task.id);
    return f > -1;
  }

  refreshMap(){
    let tasks: Task[];
    if (this.selectedTask.length > 0) {
      tasks = this.selectedTask;
    } else {
      tasks = this.tasks;
    }
    this.isMap = false;
    if (tasks.length > 0) {
      this.mapSetting.markers = [];
      tasks.forEach(t => {
        const lon = t.contract?.objective?.longitude;
        const lat = t.contract?.objective?.latitude;
        const label = t.contract?.objective?.address ?? "";
        if (lon != undefined && lat != undefined) {
          const coords: LngLatLike = [lon, lat];
          this.mapSetting.markers.push({label: label, lngLat: coords});
        }
      });
      if (this.mapSetting.markers.length > 0) {
        const lons: number[] = this.mapSetting.markers.map(v => v.lngLat[0]);
        const lats: number[] = this.mapSetting.markers.map(v => v.lngLat[1]);
        const lonsMax = Math.max.apply(null, lons);
        const latsMax = Math.max.apply(null, lats);
        const lonsMin = Math.min.apply(null, lons);
        const latsMin = Math.min.apply(null, lats);
        const centerLon = lonsMin + ((lonsMax - lonsMin) / 2);
        const centerLat = latsMin + ((latsMax - latsMin) / 2);
        const k = (lonsMax - lonsMin) + (latsMax - latsMin);
        const zoom = 12 / (k/4 + 1);
        this.mapSetting.center = [centerLon, centerLat];
        this.mapSetting.zoom = zoom;
        setTimeout(() => {
          this.isMap = true;
        }, 100);
      }
    }
  }

  protected readonly iconsByName = iconsByName;
  protected readonly ApiPath = ApiPath;
  protected readonly SpecifyLabelType = SpecifyLabelType;
  protected readonly defaultFilter = defaultFilter;
  protected readonly getFormattedDate = getFormattedDate;
  protected readonly formatDate = formatDate;
  protected readonly WeakDirection = WeakDirection;
  protected readonly daysOfWeak = daysOfWeak;
  protected readonly formatDateYYYYMMDD = formatDateYYYYMMDD;
  protected readonly formatStringToDate = formatStringToDate;
    protected readonly text = text;
}
