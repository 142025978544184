import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {CheckboxComponent} from "../checkbox/checkbox.component";
import {ClickedOutsideDirectiveDirective} from "../../directive/clicked-outside-directive.directive";
import {AutoSetRule} from "../../../model/relationRule";
import {text} from "../../../function/localization";
import {environment} from "../../../../environments/environment";
import * as maptilersdk from '@maptiler/sdk';
import {GeocodingFeature, GeocodingOptions} from "@maptiler/client";
import {LocalizationService} from "../../../services/localization.service";
import {
  NgxGpAutocompleteDirective,
  NgxGpAutocompleteModule,
  NgxGpAutocompleteOptions
} from "@angular-magic/ngx-gp-autocomplete";

@Component({
  selector: 'app-autocomplete',
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    NgForOf,
    CheckboxComponent,
    ClickedOutsideDirectiveDirective,
    NgxGpAutocompleteModule
  ],
  templateUrl: './autocomplete.component.html',
  styleUrl: './autocomplete.component.scss'
})
export class AutocompleteComponent implements OnInit {
  constructor(private localizationService: LocalizationService) {
  }

  @Input() value: string | null = null;
  @Input() autoSetRules: AutoSetRule[] = [];
  @Output() emitAddress = new EventEmitter<string>();
  @Output() emitAutoSetRules = new EventEmitter<AutoSetRule[]>();


  searchString: string = "";

  ngOnInit() {
    this.searchString = this.value ?? "";
  }



  options: any = {
  componentRestrictions: {
    country: this.localizationService.getLocCode(),
  },
  language: this.localizationService.getLangCode()
};

  public handleAddressChange(place: google.maps.places.PlaceResult) {
    const lat = place.geometry?.location?.lat();
    const lng = place.geometry?.location?.lng();
    this.autoSetRules.forEach(v => {
      if (v.determinantKey == "latitude") {
        v.value = lat;
      }
      if (v.determinantKey == "longitude") {
        v.value = lng;
      }
    })
    console.log(place);
    this.searchString = place.formatted_address ?? this.searchString;
    this.emitAddress.emit(this.searchString);
    this.emitAutoSetRules.emit(this.autoSetRules);
  }
  protected readonly text = text;
}
