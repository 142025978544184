<app-section>
  <div class="main-client-container">
      <div class="main-cell cell-turquoise" (click)="openChapter(0)">
        <div class="main-cell-left">
          <app-icon class="main-cell-icon" [icon]="iconsByName.spanner"/>
        </div>
        <div class="main-cell-right">
          <h4>{{ text(['Журнал осмотров', 'Registro de inspecciones']) }}</h4>
          <div class="line-grey"></div>
          <p>
            {{ text(['Проведённые осмотры, выявленные пробелы и рекомендации',
            'Inspecciones realizadas, deficiencias detectadas y recomendaciones']) }}
          </p>
        </div>
      </div>
      <div class="main-cell cell-green" (click)="openChapter(1)">
        <div class="main-cell-left">
          <app-icon class="main-cell-icon" [icon]="iconsByName.document"/>
        </div>
        <div class="main-cell-right">
          <h4>{{ text(['Правовая информация', 'Información legal']) }}</h4>
          <div class="line-grey"></div>
          <p>
            {{ text(['Заключенные договоры',
            'Celebrar un contrato']) }}
          </p>
        </div>
      </div>
      <div class="main-cell cell-blue" >
        <div class="main-cell-left">
          <app-icon class="main-cell-icon" [icon]="iconsByName.money2"/>
        </div>
        <div class="main-cell-right">
          <h4>{{ text(['Управление услугами', 'Gestión de servicios']) }}</h4>
          <div class="line-grey"></div>
          <p>{{ text(['Просмотр текущих и заказ новых услуг', 'Ver servicios actuales y nuevos pedidos']) }}</p>
        </div>
      </div>
      <div class="main-cell cell-purple">
        <div class="main-cell-left">
          <app-icon class="main-cell-icon" [icon]="iconsByName.envelop"/>
        </div>
        <div class="main-cell-right">
          <h4>{{ text(['Уведомления', 'Notificación']) }}</h4>
          <div class="line-grey"></div>
          <p>{{ text(['Поступившие уведомления об осмотрах', 'Notificaciones de inspección recibidas']) }}</p>
        </div>
      </div>
  </div>
  <div class="mobile-main-client-container">
    <div class="mobile-main-workspace">
      <app-cell-item
        [icon]="iconsByName.spanner"
        [color]="'#3ABEB1'"
        [title]="text(['Журнал осмотров', 'Registro de inspecciones'])"
        [label]="text(['Проведённые осмотры, выявленные пробелы и рекомендации',
            'Inspecciones realizadas, deficiencias detectadas y recomendaciones'])"
        (click)="openChapter(0)"
        class="mobile-main-el"

      />
      <app-cell-item
        [icon]="iconsByName.contract75"
        [color]="'#4DCA76'"
        [title]="text(['Правовая информация', 'Información legal'])"
        [label]="text(['Заключенные договоры',
            'Celebrar un contrato'])"
        (click)="openChapter(1)"
        class="mobile-main-el"
      />
      <app-cell-item
        [icon]="iconsByName.contract75"
        [color]="'#4192D9'"
        [title]="text(['Управление услугами', 'Gestión de servicios'])"
        [label]="text(['Просмотр текущих и заказ новых услуг',
            'Ver servicios actuales y nuevos pedidos'])"
        class="mobile-main-el"
      />
      <app-cell-item
        [icon]="iconsByName.envelop"
        [color]="'#B070E6'"
        [title]="text(['Уведомления', 'Notificación'])"
        [label]="text(['Поступившие уведомления об осмотрах',
            'Notificaciones de inspección recibidas'])"
        class="mobile-main-el"
      />
    </div>
  </div>
</app-section>
