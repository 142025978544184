import {Component, OnInit} from '@angular/core';
import {text} from "../../../../../../function/localization";
import {Icon, iconsByName} from "../../../../../../model/icon";
import {Task, TaskStatuses, tuskStatusesMap} from "../../../../../../model/task";
import {correctFormattedDate} from "../../../../../../function/formatDate";
import {CellItemComponent} from "../../../../../elements/cell-item/cell-item.component";
import {IconComponent} from "../../../../../elements/icon/icon.component";
import {NgForOf, NgIf} from "@angular/common";
import {LoadingComponent} from "../../../../../elements/loading/loading.component";
import {ClientSubheader} from "../../../../../../model/clientSubheader";
import {ClientSubheaderComponent} from "../../../../../elements/client-subheader/client-subheader.component";
import {Contract} from "../../../../../../model/contract";
import {ApiPath} from "../../../../../../model/global";
import {HeaderService} from "../../../../../../services/header.service";
import {MenuService} from "../../../../../../services/menu.service";
import {ApiService} from "../../../../../../services/api.service";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {TableFilter} from "../../../../../../model/table";
import {headers} from "../../../../../../model/header";
import {CheckboxComponent} from "../../../../../elements/checkbox/checkbox.component";
import {NoItemsComponent} from "../../../../../elements/no-items/no-items.component";

@Component({
  selector: 'app-client-inspection-contract-log',
  standalone: true,
  imports: [
    CellItemComponent,
    IconComponent,
    NgForOf,
    NgIf,
    LoadingComponent,
    ClientSubheaderComponent,
    RouterLink,
    CheckboxComponent,
    NoItemsComponent
  ],
  templateUrl: './client-inspection-contract-log.component.html',
  styleUrl: './client-inspection-contract-log.component.scss'
})
export class ClientInspectionContractLogComponent implements OnInit{
  constructor(public headerService: HeaderService,
              public menuService: MenuService,
              protected apiService: ApiService,
              private route: ActivatedRoute,
              private router: Router,) {}

  loading = false;
  tasks: Task[] = [];
  plannedTask: Task[] = [];

  subheader: ClientSubheader = {
    title: text(['Правовая информация', 'Información legal']),
    breadcrumbs: [
      {
        name: text(['Главное меню', 'Menú principal']),
        link: "/client",
      },
      {
        name: text(['Журнал осмотров', 'Registro de inspecciones']),
        link: "/client/inspection-log",
      },
    ],
    icon: iconsByName.spanner,
    backLink: true,
    color: "#3abeb1",
  }

  tableFilter: TableFilter = new TableFilter({
    size: 100,
    query: "",
    sort: "planDate:DESC,beginDate:DESC",
    page: 0,
    totalPages: 0,
    totalElements: 0,
  });

  sortAsc: boolean = false;
  sortTitle: string = text(['По убыванию', 'Descendente']);
  isFirst: boolean = false;
  sortIcon: Icon = iconsByName.ascBlue;

  contractId = this.route.snapshot.paramMap.get('id')

  hideNotPlanned: boolean = true;

  ngOnInit() {
    this.headerService.setCurrentHeader(headers[1]);
    this.menuService.setActiveTab(1);
    this.getTasks();
  }


  getTasks(){
      this.loading = true;
      this.apiService.getList<Task>(ApiPath.ClientTasksByContract + "/" + this.contractId, this.tableFilter, true)
        .subscribe((data) => {
          this.subheader.backLink = true;
          this.tasks = data.content?.map(v => new Task(v)) ?? [];
          this.tasks.forEach((v) => {
            v.status = v.taskServices.filter((y) => !y.done).length > 0 &&
            v.status == "FINISHED" ? "FINISHED_UNCOMPLETELY" : v.status;
          });
          if (!this.isFirst) {
            this.subheader.breadcrumbs.push({
              name: text(['Договор № ', 'Tratado № ']) + this.tasks[0].contract?.number?.toString(),
              link: "",
            });
          }
          this.plannedTask = this.tasks.filter((v) => v.status != "NOT_PLANNED");
          this.isFirst = true;
          this.loading = false;
        });
  }

  getColor(status: string){
    switch (status) {
      case "PLANNED": return "#ed672d";
      case "CANCELLED": return "#c72626";
      case "FINISHED": return "#4abf75";
      case "IN_PROGRESS": return "#7d8be3";
      case "NOT_PLANNED": return "#45b9d9";
      case "FINISHED_UNCOMPLETELY": return "#d9c045";
      default: return "";
    }
  }

  getIcon(status: string){
    switch (status) {
      case "PLANNED": return iconsByName.planned;
      case "CANCELLED": return iconsByName.cancel;
      case "FINISHED": return iconsByName.success2;
      case "IN_PROGRESS": return iconsByName.inProgress;
      case "NOT_PLANNED": return iconsByName.notPlanned;
      case "FINISHED_UNCOMPLETELY": return iconsByName.uncompletely;
      default: return iconsByName.default;
    }
  }


  back(){
    this.router.navigate(["/client/inspection-log"],{relativeTo: this.route}).then(r => r);
  }

  switchSort() {
    if (this.sortAsc) {
      this.sortTitle = text(['По возрастанию', 'Ascendente']);
      this.sortIcon = iconsByName.ascBlue;
      this.sortAsc = false;
      this.tableFilter.sort = "planDate:ASC,beginDate:ASC";
    } else {
      this.sortTitle = text(['По убыванию', 'Descendente']);
      this.sortIcon = iconsByName.descBlue;
      this.sortAsc = true;
      this.tableFilter.sort = "planDate:DESC,beginDate:DESC";
    }
    this.getTasks();
  }

  changeMode(value: boolean){
    this.hideNotPlanned = value;
    this.getTasks()
  }

  protected readonly text = text;
  protected readonly iconsByName = iconsByName;
  protected readonly tuskStatusesMap = tuskStatusesMap;
  protected readonly correctFormattedDate = correctFormattedDate;
}
