export enum ApiPath {
  Default = "",
  User = "dictionaries/user",
  Country ="dictionaries/country",
  Currency ="dictionaries/currency",
  Document ="dictionaries/document-type",
  ObjectType ="dictionaries/objective-type",
  Contract = "contract",
  Measure = "dictionaries/measure",
  Client = "dictionaries/client",
  Objective = "dictionaries/objective",
  Tariff = "dictionaries/tariff",
  ServiceType = "dictionaries/service-type",
  DefectType = "dictionaries/defect-type",
  Task = "task",
  TaskEngineer = "task/engineer",
  UploadFile = "contract/files/upload",
  DownloadFile = "contract/files/download",
  DownloadFileDefects = "task/engineer/files/download",
  DeleteFile = "contract/files/delete",

}

export enum UrlPath {
  Country ="directories/country",
  Currency ="directories/currency",
  Document ="directories/document-type",
  ObjectType ="directories/objective-type",
  Measure = "directories/measure",
  Client = "directories/client",
  Objective = "directories/objective",
  Tariff = "directories/tariff",
  ServiceType = "directories/service-type",
  DefectType = "directories/defect-type",
  TaskRegistry = "tasks",
}


