import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {LoginService} from "../../../services/login.service";
import {NgIf} from "@angular/common";
import {ButtonComponent} from "../../elements/button/button.component";
import {Router} from "@angular/router";
import {NotificationService} from "../../../services/notification.service";
import {NotificationForm, NotificationType} from "../../../model/notification";
import {text} from "../../../function/localization";
import {LocalizationService} from "../../../services/localization.service";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    ButtonComponent
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  constructor(private loginService: LoginService,
              private router: Router,
              public notificationService: NotificationService) {
  }
  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  showError: boolean = false;
  showSuccess: boolean = false;
  loginError: string | undefined;


  get username() {
    return this.loginForm.get('username');
  }

  get password() {
    return this.loginForm.get('password');
  }


  login() {
    this.loginService.login(this.username?.value ?? '', this.password?.value ?? '').subscribe({
      next: data => {
        this.loginService.updData(data);
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Успешный вход", "Inicio de sesión exitoso"]),
          status: NotificationType.Success,
          text: text(["Вы вошли как ", "Has iniciado sesión como "]) + `${this.username?.value}`
        }));
        this.router.navigate(['']).then(r => r);
      },
      error: error => {
        if (error.code == 401) {
          this.notificationService.callNotification(new NotificationForm({
            label: text(["Ошибка", "Error"]),
            status: NotificationType.Error,
            text: text(["Неправильный логин или пароль", "Usuario o contraseña incorrectos"]),
          }));
        } else {
          this.notificationService.callNotification(new NotificationForm({
            label: text(["Ошибка", "Error"]),
            status: NotificationType.Error,
            text: text(["Код ошибки ", "Código de error "]) + `${error.status}`
          }));
        }
      },
    })
  }

  protected readonly text = text;
}
