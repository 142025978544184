import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {NgIf} from "@angular/common";
import {ModalService} from "../../../services/modal.service";
import {ModalType} from "../../../model/modalOptions";
import {FormComponent} from "../form/form.component";
import {FormTemplate} from "../../../model/form";
import {formTemplates} from "../../../model/templates";
import {NotificationService} from "../../../services/notification.service";
import {NotificationForm, NotificationType} from "../../../model/notification";
import {ButtonComponent} from "../button/button.component";
import {text} from "../../../function/localization";

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [
    NgIf,
    FormComponent,
    ButtonComponent,
  ],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent implements OnInit {
  constructor(protected modalService: ModalService,
              protected notificationService: NotificationService) { }


  cancel(){
    this.modalService.setModal(false, null);
  }

  setObj(value: any){
    this.modalService.setModalBuffer(value);
    this.cancel();
    this.notificationService.callNotification(new NotificationForm({
      label: text(["Запись успешно сохранена", "Registro guardado exitosamente"]),
      status: NotificationType.Success,
      text: ``
    }));
  }

  approve(){
    this.modalService.setModalBuffer(true);
    this.cancel();
    this.notificationService.callNotification(new NotificationForm({
      label: text(["Запись удалена", "Registro eliminado"]),
      status: NotificationType.Warning,
      text: ``
    }));
  }

  ngOnInit() {}

  setClass(){
    const type = this.modalService.getOptions()?.type;
    switch (type) {
      case ModalType.Form: {
        return "";
      }
      case ModalType.Table: {
        return ""
      }
      case ModalType.Confirm: {
        return "modal-window-confirm";
      }
      default: return "";
    }
  }

  protected readonly ModalType = ModalType;
  protected readonly FormTemplate = FormTemplate;
  protected readonly formTemplates = formTemplates;
    protected readonly text = text;
}
