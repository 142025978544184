<div class="page-login-container">
    <div *ngIf="!chooseMode" class="login-box">
      <form [formGroup]="loginForm" (keyup.enter)="login()">
        <div class="form-group">
          <div class="form-item">
            <div class="input-login">
              <label>{{ text(["Логин", "Inicio de sesión"]) }}</label>
              <input id="username" formControlName="username" required>
            </div>
          </div>
          <div class="form-item">
            <div class="input-login">
              <label>{{ text(["Пароль", "Contraseña"]) }}</label>
              <input type="password" id="password" name="password" formControlName="password" required
                     autocomplete="on">
            </div>
          </div>
          <div class="form-item justify-content-center mt-2">
            <app-button styleClass="width: 100%" (click)="login()" [disabled]="loginForm.invalid">
              {{ text(["Войти", "Entrar"]) }}
            </app-button>
          </div>
        </div>
<!--        <div class="save-pass-container">-->
<!--          <app-checkbox />-->
<!--          <div class="save-pass-text">-->
<!--            Запомнить пароль-->
<!--          </div>-->
<!--        </div>-->
        <div *ngIf="showError" class="alert alert-danger">
          {{ text(["Ошибка входа: ", "Error de Inicio de sesión:"]) }} {{ loginError }}
        </div>
        <div *ngIf="showSuccess" class="alert alert-success">
          {{ text(["Успешный вход", "Inicio de sesión exitoso"]) }}
        </div>
      </form>
    </div>
    <div *ngIf="chooseMode" class="choose-mode-container">
      <div class="choose-card choose-card-blue" (click)="loginAsClient()">
        <div class="circle">
          <app-icon [icon]="iconsByName.bluePerson"/>
        </div>
        <div class="title-choose">{{ text(["Клиент", "Cliente"]) }}</div>
        <div class="button-choose">
          <app-button>{{ text(["Вход", "Entrada"]) }}</app-button>
        </div>
      </div>
      <div class="choose-card choose-card-orange" (click)="loginAsAdmin()">
        <div class="circle choose-icon-orange">
          <app-icon [icon]="iconsByName.clocks"/>
        </div>
        <div class="title-choose">{{ text(["Оператор", "Operador"]) }}</div>
        <div class="button-choose">
          <app-button [styleClass]="'button-style-orange'">{{ text(["Вход", "Entrada"]) }}</app-button>
        </div>
      </div>
    </div>
</div>
