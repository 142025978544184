import {Component, Input, OnInit} from '@angular/core';
import {HeaderService} from "../../services/header.service";
import {Header, headers, HeadersName} from "../../model/header";
import {MenuComponent} from "../menu/menu.component";
import {LoginInfoComponent} from "../elements/login-info/login-info.component";
import {NgIf} from "@angular/common";
import {ButtonComponent} from "../elements/button/button.component";
import {RouterLink} from "@angular/router";
import {NotificationService} from "../../services/notification.service";
import {NotificationForm, NotificationType} from "../../model/notification";
import {text} from "../../function/localization";
import {MenuService} from "../../services/menu.service";
import {IconComponent} from "../elements/icon/icon.component";
import {iconsByName} from "../../model/icon";
import {MobileMenuService} from "../../services/mobile-menu.service";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    MenuComponent,
    LoginInfoComponent,
    NgIf,
    ButtonComponent,
    RouterLink,
    IconComponent
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit{
  constructor( protected headerService: HeaderService,
               public notificationService: NotificationService,
               protected mobileMenuService: MobileMenuService) {}

 title: string = text(['Главное меню', 'Menú principal']);

  openMenu: boolean = false;


  ngOnInit() {
    // this.headerService.setCurrentHeader(headers[HeadersName.Login]);
  }

  cancel() {
    this.notificationService.callNotification(new NotificationForm({
      label: text(["Редактирование отменено", "Edición cancelada"]),
      status: NotificationType.Info,
      text: text(["Изменения не сохранены", "Los cambios no se guardaron"]),
    }));
  }

  getHeaderIcon() {
    const x = this.mobileMenuService.getActiveInterface();
    switch (x) {
      case 0:
        return iconsByName.mobileHome;
      case 1:
        return iconsByName.spanner;
      case 2:
        return iconsByName.contract75;
      default:
        return iconsByName.headerMobileMenu;
    }
  }

  getHeaderIconColor() {
    const x = this.mobileMenuService.getActiveInterface();
    switch (x) {
      case 0:
        return '#B070E6';
      case 1:
        return '#3ABEB1';
      case 2:
        return '#4DCA76';
      case 3:
        return '#B070E6';
      default:
        return '';
    }
  }

  getHeaderTitle() {
    const x = this.mobileMenuService.getActiveInterface();
    switch (x) {
      case 0:
        return text(['Главное меню', 'Menú principal']);
      case 1:
        return text(['Журнал осмотров', 'Registro de inspecciones']);
      case 2:
        return text(['Правовая информация', 'Información legal']);
      default:
        return text(['Главное меню', 'Menú principal']);
    }
  }

    protected readonly text = text;
  protected readonly iconsByName = iconsByName;
}
