import {Component, OnInit} from '@angular/core';
import {HeaderService} from "../../../../services/header.service";
import {ApiService} from "../../../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LegalMenu, legalMenuItems} from "../../../../model/legalMenu";
import {NgForOf, NgIf} from "@angular/common";
import {DirectoriesService} from "../../../../services/directories.service";
import {LegalMenuService} from "../../../../services/legalMenu.service";
import {defaultFilter, TableFilter} from "../../../../model/table";
import {ApiPath, UrlPath} from "../../../../model/global";
import {Contract} from "../../../../model/contract";
import {ClientSubheader} from "../../../../model/clientSubheader";
import {LegalContractComponent} from "../../../elements/legal-contract/legal-contract.component";
import {LegalContractPageComponent} from "./pages/legal-contract-page/legal-contract-page.component";
import {getPropValue} from "../../../../function/getValue";
import {ButtonComponent} from "../../../elements/button/button.component";
import {IconComponent} from "../../../elements/icon/icon.component";
import {iconsByName} from "../../../../model/icon";
import {CellItemComponent} from "../../../elements/cell-item/cell-item.component";
import {getAddress} from "../../../../function/getAddress";
import {headers} from "../../../../model/header";
import {MenuService} from "../../../../services/menu.service";
import {ClientSubheaderComponent} from "../../../elements/client-subheader/client-subheader.component";
import {text} from "../../../../function/localization";
import {correctFormattedDate, formatDate} from "../../../../function/formatDate";

@Component({
  selector: 'app-legal-information',
  standalone: true,
  imports: [
    NgForOf,
    LegalContractComponent,
    NgIf,
    LegalContractPageComponent,
    ButtonComponent,
    IconComponent,
    CellItemComponent,
    ClientSubheaderComponent
  ],
  templateUrl: './legal-information.component.html',
  styleUrl: './legal-information.component.scss'
})
export class LegalInformationComponent implements OnInit{
  constructor(public headerService: HeaderService,
              protected apiService: ApiService,
              private route: ActivatedRoute,
              private router: Router,
              protected legalMenuService: LegalMenuService,
              public menuService: MenuService,) {}

  tableFilter: TableFilter = new TableFilter({
    size: 100,
    query: "",
    sort: "",
    page: 0,
    totalPages: 0,
    totalElements: 0,
  });

  subheader: ClientSubheader = {
    title: text(['Правовая информация', 'Información legal']),
    breadcrumbs: [
      {
        name: text(['Главное меню', 'Menú principal']),
        link: "/client",
      },
      {
        name: text(['Правовая информация', 'Información legal']),
        link: "",
      },
    ],
    icon: iconsByName.contract75,
    backLink: true,
    color: "rgba(77, 202, 118, 1)",
  }

  contracts: Contract[] = [];
  contract: Contract = new Contract();

  ngOnInit() {
    this.headerService.setCurrentHeader(headers[1]);
    this.menuService.setActiveTab(2);
    this.getContractsData();
  }

  getContractsData() {
    this.apiService.getList(ApiPath.ClientContract, this.tableFilter)
      .subscribe((data: any) => {
        this.contracts = data ?? [];
        if (data && data.length == 1) {
          this.contract = data[0];
          this.openLegalContract(this.contract);
        }
      });
  }

  openLegalContract(con: Contract) {
    if (con != undefined) {
      const add = "/" + con.id;
      this.router.navigate(["/" + UrlPath.LegalContractPage + add], {relativeTo: this.route}).then(r => r);
    }
  }

  back(){
    this.router.navigate(["/client"],{relativeTo: this.route}).then(r => r);
  }

  getColor() {
    return "rgba(77, 202, 118, 1)";
  }


  protected readonly LegalMenu = LegalMenu;
  protected readonly legalMenuItems = legalMenuItems;
  protected readonly iconsByName = iconsByName;
  protected readonly getAddress = getAddress;
  protected readonly text = text;
  protected readonly formatDate = formatDate;
  protected readonly Date = Date;
  protected readonly correctFormattedDate = correctFormattedDate;
}
