<div class="menu-container">
  <div
    *ngFor="let item of menu" class="menu-item" [class]="{ 'menu-item-active': item.id == menuService.getActiveTab() }"
    [routerLink]="item.link" (click)="changeTab(item.id)"
  >
    {{ item.label }}
  </div>
</div>
<div class="mobile-menu-container">
  <div
    *ngFor="let item of menu, index as i"
    [routerLink]="item.link"
    (click)="setHeaderIcon(i)"
    class="mobile-menu-el-container"
  >
    <div class="mobile-menu-el">
      <div class="mobile-menu-el-icon" [style]="'background: ' + getColor(i)">
        <app-icon [icon]="getIcon(i)" class="icon"/>
      </div>
      {{ item.label }}
    </div>
    <hr>
  </div>
</div>
