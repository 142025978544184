import {Component, OnInit} from '@angular/core';
import {FormComponent} from "../../../../elements/form/form.component";
import {NgIf} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {HeaderService} from "../../../../../services/header.service";
import {ApiService} from "../../../../../services/api.service";
import {Header, headers, HeadersName} from "../../../../../model/header";
import {FormTemplate} from "../../../../../model/form";
import {User} from "../../../../../model/user";
import {Country} from "../../../../../model/country";
import {Contract} from "../../../../../model/contract";
import {ApiPath} from "../../../../../model/global";
import {OurFile} from "../../../../../model/ourFile";
import {NotificationForm, NotificationType} from "../../../../../model/notification";
import {NotificationService} from "../../../../../services/notification.service";
import {formTemplates, TemplatesNames} from "../../../../../model/templates";
import {ServiceType} from "../../../../../model/serviceType";
import {FilesFieldComponent} from "../../../../elements/files-field/files-field.component";
import {FileService} from "../../../../../services/file.service";
import {setValueInto} from "../../../../../function/setValue";
import {text} from "../../../../../function/localization";

@Component({
  selector: 'app-contract-page',
  standalone: true,
  imports: [
    FormComponent,
    NgIf,
    FilesFieldComponent
  ],
  templateUrl: './contract-page.component.html',
  styleUrl: './contract-page.component.scss'
})
export class ContractPageComponent implements OnInit{
  constructor( private route: ActivatedRoute,
               protected headerService: HeaderService,
               protected apiService: ApiService,
               private router: Router,
               public notificationService: NotificationService,
               private fileService: FileService) {}

  contractId:  null | string = null;
  header: Header = new Header(headers[HeadersName.Form]);
  formTemplate: FormTemplate = new FormTemplate(formTemplates[TemplatesNames.contracts]);
  loading: boolean = false;
  files: File[] = [];
  filesToDel: number[] = [];
  contract: Contract = new Contract();

  ngOnInit() {
    this.contractId = this.route.snapshot.paramMap.get('id') ?? 'add';
    this.header.buttonBackUrl = "/contracts"
    // this.contract.files = this.mockFiles;
    if (this.contractId === 'add') {
      this.header.title = text(["Новая запись", "Nuevo registro"]);
      this.formTemplate.object = this.contract;
      this.headerService.setCurrentHeader(this.header);
    } else {
      this.loading = true;
      this.apiService.getObj<Contract>(ApiPath.Contract, Number(this.contractId)).subscribe((data) => {
        this.contract = new Contract(data) ?? new Contract();
        this.header.title = text(["Договор №", "Contrato №"]) + this.contract.number;
        // if (this.contract.terminated) {
        //   this.header.subTitle = "<h5>Активная запись — <span class='font-color__green'>нет</span> Статус <span class='font-color__red'>Не действует</span> </h5>";
        // } else {
        //   this.header.subTitle = "<h5>Активная запись — <span class='font-color__green'>да</span> Статус <span class='font-color__green'>Действует</span> </h5>";
        // }
        this.formTemplate.id = Number(this.contractId);
        this.formTemplate.object = new Contract(this.contract);
        this.headerService.setCurrentHeader(this.header);
        // setTimeout(() => {
          this.loading = false;
        // }, 3000);
      });
    }
  }

  cancel(){
    this.router.navigate([this.header.buttonBackUrl],{relativeTo: this.route}).then(r => r);
  }

  setFiles(files: File[]){
    this.files = files;
  }

  setFileToDel(ids: number[]){
    this.filesToDel = ids;
  }

  saveFiles(id: number){
    this.fileService.upload(this.files, id).subscribe({
      next: () => {
         const title = this.files.length > 1 ? text(["Файлы загружены", "Archivos cargados"]) :
           text(["Файл загружен", "Archivo cargado"]);
         const thisText = this.files.length > 1 ?
           text(["Файлы успешно загружены", "Archivos cargados con éxito"]) :
           text(["Файл успешно загружен", "Archivo cargado con éxito"]);
        this.notificationService.callNotification(new NotificationForm({
          label: title,
          status: NotificationType.Success,
          text: thisText
        }));
        this.loading = false;
      },
      error: (err) => {
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Ошибка при загрузке файлов", "Error al cargar archivos"]),
          status: NotificationType.Error,
          text: text(["Код ошибки ", "Código de error "]) + `${err?.status ?? JSON.stringify(err)}`
        }));
        this.loading = false;
      }
    });
  }

  deleteFile(id: number){
    this.fileService.delete(id).subscribe({
      next: () => {
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Файл удалён", "Archivo eliminado"]),
          status: NotificationType.Warning,
          text: text(["Файл успешно удалён", "Archivo eliminado con éxito"]),
        }));
      },
      error: (err) => {
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Ошибка при удалении файла", "Error al eliminar un archivo"]),
          status: NotificationType.Error,
          text: text(["Код ошибки ", "Código de error "]) + `${err?.status ?? JSON.stringify(err)}`
        }));
      }
    });
  }

  saveContract(contract: Contract): void {
    // this.loading = true;
    let cont = new Contract(contract);
    if (contract.objective?.client.user.login.length == 0) {
      cont = setValueInto("objective.client.user.login", contract.objective?.client.user.email, cont);
    }
    if (contract.objective?.client.user.roles.length == 0) {
      cont = setValueInto("objective.client.user.roles", ["ROLE_CLIENT"], cont);
    }
    this.apiService.saveObj<Contract>(ApiPath.Contract, cont).subscribe({
      next: (response) => {
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Запись успешно сохранена", "Registro guardado exitosamente"]),
          status: NotificationType.Success,
          text:
            text(["Договор ", "Contrato "]) +
            `${contract.number}` +
            text([" успешно сохранён", " guardado exitosamente"]),
        }));
        if (response.id && this.files.length > 0) {
          this.saveFiles(response.id);
        }
        this.filesToDel.forEach(id => {
          this.deleteFile(id);
        });
        this.cancel();
      },
      error: (err) => {
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Ошибка", "Error"]),
          status: NotificationType.Error,
          text: text(["Код ошибки ", "Código de error "]) + err.status + " " + err.error.message,
        }));
        // this.loading = false;
      },
      complete:() => {
        this.files = [];
        this.filesToDel = [];
        this.loading = false;
      }
    });
  }
}
