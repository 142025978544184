export class PlaceKit {
  results: PlaceKitAddress[];
  maxResults: number;
  resultsCount: number;
  query: string;
  constructor(value?: PlaceKit) {
    this.results = value?.results.map(v => new PlaceKitAddress(v)) ?? [];
    this.maxResults = value?.maxResults ?? 0;
    this.resultsCount = value?.resultsCount ?? 0;
    this.query = value?.query ?? "";
  }
}


export class PlaceKitAddress {
  street: PlaceKitAddressStreet;
  name: string;
  highlight: string;
  city: string;
  county: string;
  administrative: string;
  country: string;
  administrativecode: string;
  countrycode: string;
  countycode: string;
  zipcode: string[];
  population: number | null;
  lat: number | null;
  lng: number | null;
  coordinates: string;
  type: string;

  constructor(value?: PlaceKitAddress) {
    this.street = new PlaceKitAddressStreet(value?.street);
    this.name = value?.name ?? "";
    this.highlight = value?.highlight ?? "";
    this.city = value?.city ?? "";
    this.county = value?.county ?? "";
    this.administrative = value?.administrative ?? "";
    this.country = value?.country ?? "";
    this.administrativecode = value?.administrativecode ?? "";
    this.countrycode = value?.countrycode ?? "";
    this.countycode = value?.countycode ?? "";
    this.zipcode = value?.zipcode ?? [];
    this.population = value?.population ?? null;
    this.lat = value?.lat ?? null;
    this.lng = value?.lng ?? null;
    this.coordinates = value?.coordinates ?? "";
    this.type = value?.type ?? "";
  }

  // getAddress() {
  //   const address: string[] = [];
  //   if (this.country != null) {
  //     address.push(this.country);
  //   }
  //   if (this.county != null) {
  //     address.push(this.county);
  //   }
  //   if (this.street.name != null) {
  //     address.push(this.street.name);
  //   }
  //   if (this.street.number != null) {
  //     address.push(this.street.number);
  //   }
  //   return address.filter(v => v != "").join(", ");
  // }
  getAddress() {
    const address: string[] = [];
    if (this.street.name != null) {
      address.push(this.street.name);
    }
    if (this.street.number != null) {
      address.push(this.street.number);
    }
    if (this.street.suffix != null) {
      address.push(this.street.suffix);
    }
    if (this.zipcode != null) {
      const x = this.zipcode.toString();
      address.push(x);
    }
    if (this.city != null) {
      address.push(this.city);
    }
    if (this.administrative != null) {
      address.push(this.administrative);
    }
    if (this.country != null) {
      address.push(this.country);
    }
    return address.filter(v => v != "").join(", ");
  }
}

export class PlaceKitAddressStreet {
  number?: string;
  suffix?: string;
  name?: string;
  constructor(value?: PlaceKitAddressStreet) {
    this.number = value?.number ?? "";
    this.suffix = value?.suffix ?? "";
    this.name = value?.name ?? "";
  }
}

