import {Component, OnInit} from '@angular/core';
import {FormComponent} from "../../../../../elements/form/form.component";
import {ActivatedRoute, Router} from "@angular/router";
import {HeaderService} from "../../../../../../services/header.service";
import {ApiService} from "../../../../../../services/api.service";
import {Header, headers, HeadersName} from "../../../../../../model/header";
import {FormTemplate} from "../../../../../../model/form";
import {DocumentType} from "../../../../../../model/documents";
import {Client} from "../../../../../../model/client";
import {ApiPath} from "../../../../../../model/global";
import {formTemplates, TemplatesNames} from "../../../../../../model/templates";
import {NotificationService} from "../../../../../../services/notification.service";
import {NotificationForm, NotificationType} from "../../../../../../model/notification";
import {getFio} from "../../../../../../function/getFio";
import {text} from "../../../../../../function/localization";

@Component({
  selector: 'app-customers-page',
  standalone: true,
  imports: [
    FormComponent
  ],
  templateUrl: './client-page.component.html',
  styleUrl: './client-page.component.scss'
})
export class ClientPageComponent implements OnInit{
  constructor( private route: ActivatedRoute,
               protected headerService: HeaderService,
               protected apiService: ApiService,
               private router: Router,
               public notificationService: NotificationService) {}

  customerId: null | string = null;
  header: Header = new Header(headers[HeadersName.Admin]);
  formTemplate: FormTemplate = new FormTemplate(formTemplates[TemplatesNames.clients]);
  loading: boolean = false;
  client: Client = new Client();

  ngOnInit() {
    this.customerId = this.route.snapshot.paramMap.get('id') ?? 'add';
    this.header.buttonBackUrl = "/admin/directories";
    if (this.customerId === 'add') {
      this.header.title = text(["Новая запись", "Nuevo registro"]);
      this.formTemplate.object = this.client;
      this.loading = false;
      this.headerService.setCurrentHeader(this.header);
    } else {
      this.loading = true;
      this.apiService.getObj<Client>(ApiPath.Client, Number(this.customerId)).subscribe((data) => {
        this.client = new Client(data) ?? new Client();
        this.header.title =
          text(["Редактирование пользователя ", "Editar usuario "]); +  getFio(this.client, "user", true);
        // this.header.subTitle = "<h5>Активная запись — <span class='font-color__green'>да</span></h5>";
        this.formTemplate.object = new Client(this.client);
        this.headerService.setCurrentHeader(this.header);
        this.loading = false;
      });
    }
  }


  cancel(){
    this.router.navigate([this.header.buttonBackUrl],{relativeTo: this.route}).then(r => r);
  }

  saveClient(client: Client): void {
    this.apiService.saveObj<Client>(ApiPath.Client, client).subscribe({
      next: () => {
        const clientName = client.user?.login ?? "";
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Запись успешно сохранена", "Registro guardado exitosamente"]),
          status: NotificationType.Success,
          text: text(["Клиент ", "Cliente "])
            + `\"${clientName}\"`
            + text(["успешно сохранён", "guardado exitosamente"])
        }));
        this.cancel();
      },
      error: (err) => {
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Ошибка", "Error"]),
          status: NotificationType.Error,
          text: text(["Код ошибки ", "Código de error "]) + err.status + " " + err.error.message,
        }));
      },
    });
  }



}
