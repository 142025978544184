import {Component, Input} from '@angular/core';
import {text} from "../../../function/localization";
import {iconsByName} from "../../../model/icon";
import {IconComponent} from "../icon/icon.component";
import {TableFilter} from "../../../model/table";

@Component({
  selector: 'app-no-items',
  standalone: true,
  imports: [
    IconComponent
  ],
  templateUrl: './no-items.component.html',
  styleUrl: './no-items.component.scss'
})
export class NoItemsComponent {
  @Input() title: string[] = [];

  protected readonly text = text;
  protected readonly iconsByName = iconsByName;
}
