export class Option {
  id: number;
  name: string | string[];
  code: string;
  selected?: boolean = false;
  bool?: boolean;
  constructor(value: Option) {
    this.id = value.id ?? 0;
    this.name = value.name ?? [];
    this.code = value.code ?? "";
    this.selected = value.selected ?? false;
    this.bool = value.bool ?? false;
  }
}

export function getOptionsListFromEnum(dto: any, map?: Map<any, string[]>): Option[] {
  let options: Option[] = [];
  let i: number = 0;
  if (map) {

    let j: number = 0;
    Object.keys(dto).forEach((key: string) => {
      if (isNaN(Number(key))) {
        const name = map.get(j) ?? "!";

        options.push(new Option({
          id: i,
          name: name,
          code: key,
        }));
        i++;
        j++;
      }
    });
  } else {
    Object.keys(dto).forEach((key: string) => {
      if (isNaN(Number(key))) {
        options.push(new Option({
          id: i,
          name: dto[key],
          code: key,
        }));
        i++;
      }
    });
  }
  return options;
}
