import {Component, OnInit} from '@angular/core';
import {FormComponent} from "../../../../elements/form/form.component";
import {ActivatedRoute, Router} from "@angular/router";
import {HeaderService} from "../../../../../services/header.service";
import {ApiService} from "../../../../../services/api.service";
import {Header, headers, HeadersName} from "../../../../../model/header";
import {FormTemplate} from "../../../../../model/form";
import {DocumentType} from "../../../../../model/documents";
import {ApiPath} from "../../../../../model/global";
import {Tariff} from "../../../../../model/tariff";
import {formTemplates, TemplatesNames} from "../../../../../model/templates";
import {NotificationForm, NotificationType} from "../../../../../model/notification";
import {NotificationService} from "../../../../../services/notification.service";
import {text} from "../../../../../function/localization";

@Component({
  selector: 'app-tariffs-page',
  standalone: true,
  imports: [
    FormComponent
  ],
  templateUrl: './tariffs-page.component.html',
  styleUrl: './tariffs-page.component.scss'
})
export class TariffsPageComponent implements OnInit{
  constructor( private route: ActivatedRoute,
               protected headerService: HeaderService,
               protected apiService: ApiService,
               private router: Router,
               public notificationService: NotificationService) {}

  tariffId: null | string = null;
  header: Header = new Header(headers[HeadersName.Form]);
  formTemplate: FormTemplate = new FormTemplate(formTemplates[TemplatesNames.tariffs]);
  loading: boolean = false;
  tariff: Tariff = new Tariff();

  ngOnInit() {

    this.tariffId = this.route.snapshot.paramMap.get('id') ?? 'add';
    this.header.buttonBackUrl = "/directories"
    if (this.tariffId === 'add') {
      this.header.title = text(["Новая запись", "Nuevo registro"]);
      this.formTemplate.object = this.tariff;
      this.loading = false;
      this.headerService.setCurrentHeader(this.header);
    } else {
      this.loading = true;
      this.apiService.getObj<Tariff>(ApiPath.Tariff, Number(this.tariffId)).subscribe((data) => {
        this.tariff = new Tariff(data) ?? new Tariff();
        this.header.title = text(['Редактирование тарифа "', 'Editar tarifa "']) + this.tariff.name + '"';
        // if (this.tariff.active) {
        // this.header.subTitle = "<h5>Активная запись — <span class='font-color__green'>да</span></h5>";
        // } else {
        //   this.header.subTitle = "<h5>Активная запись — <span class='font-color__red'>нет</span></h5>";
        // }
        this.formTemplate.object = new Tariff(this.tariff);
        this.headerService.setCurrentHeader(this.header);
        this.loading = false;
      });
    }
  }


  cancel(){
    this.router.navigate([this.header.buttonBackUrl],{relativeTo: this.route}).then(r => r);
  }

  saveTariff(tariff: Tariff): void {
    this.apiService.saveObj<Tariff>(ApiPath.Tariff, tariff).subscribe({
      next: () => {
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Запись успешно сохранена", "Registro guardado exitosamente"]),
          status: NotificationType.Success,
          text: text(["Тариф ", "Tarifa "])
            + `\"${tariff.name}\"`
            + text([" успешно сохранён", " guardada exitosamente"]),
        }));
        this.cancel();
      },
      error: (err) => {
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Ошибка", "Error"]),
          status: NotificationType.Error,
          text: text(["Код ошибки ", "Código de error "]) + err.status + " " + err.error.message,
        }));
      },
    });

  }
}
