import {AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {MapStyle, config, Marker, Map, Popup} from '@maptiler/sdk';
import '@maptiler/sdk/dist/maptiler-sdk.css';
import {MapSettings} from "../../../model/map";

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [],
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss'
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() setting: MapSettings = new MapSettings();
  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;
  map: Map | undefined;

  ngOnInit(): void {
    config.apiKey = environment.mapTailerKey;
  }

  ngAfterViewInit() {
    this.map = new Map({
      container: this.mapContainer.nativeElement,
      style: MapStyle.STREETS,
      center: this.setting.center,
      zoom: this.setting.zoom,
      apiKey: config.apiKey,
    });
    this.setting.markers.forEach((m) => {
      if (this.map != undefined) {
        new Marker({color: "#FF0000", })
          .setLngLat(m.lngLat)
          .setPopup(new Popup().setHTML("<p>" + m.label + "</p>"))
          .addTo(this.map);
      }
    })
  };

  ngOnDestroy() {
    this.map?.remove();
  }
}
