import { Injectable } from '@angular/core';
import {LanguageList} from "../model/language";
import {SaveStorage} from "./save-storage.service";
import {Location} from "@angular/common";

export function startupServiceFactory(startupService: LocalizationService): Function {
  return () => startupService.initSetUp();
}

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  constructor(protected saveStorage: SaveStorage,
              private location: Location) {}

  codes: string[] = ["ru", "es"];
  languagesLabels: LanguageList[] = [
    {
      code: "ru",
      labels: ["Русский", "Ruso"],
      active: false
    },
    {
      code: "es",
      labels: ["Испанский", "Español"],
      active: false
    },
  ];

  locationLabels: LanguageList[] = [
    {
      code: "ru",
      labels: ["Россия", "Rusia"],
      active: false
    },
    {
      code: "es",
      labels: ["Испания", "España"],
      active: false
    },
  ];


  getLocal() {
    const navigatorLanguages = navigator.languages
      .map(v => v.split('-')[0].toLocaleLowerCase()) || [];
    let lang: number | undefined;
    let flag = false;
    navigatorLanguages.forEach(l => {
      const index = this.codes.findIndex(v => v == l);
      if (index > -1 && !flag) {
        lang = index;
        flag = true;
      }
    });
    return lang ?? 0;
  }


  setLanguage(idx: number){
    this.saveStorage.setLanguage(idx);
  }

  getActiveLang(){
    const idx = this.saveStorage.getLanguage();
    return idx ?? this.getLocal();
  }

  getLangCode() {
    return this.codes[this.getActiveLang()] ?? this.codes[0];
  }

  getLangCodeParams() {
    const code = this.codes[this.getActiveLang()] ?? this.codes[0];
    return code + "_" + code.toUpperCase();
  }

  setLocation(idx: number) {
    this.saveStorage.setLocation(idx);
  }

  getLocation(){
    const idx = this.saveStorage.getLocation();
    return idx ?? this.getActiveLang();
  }

  getLocCode() {
    return this.codes[this.getLocation()] ?? this.codes[0];
  }

  initSetUp(){
    const idxLang = this.saveStorage.getLanguage();
    const idxLoc = this.saveStorage.getLocation();
    this.setLanguage(this.getActiveLang());
    this.setLocation(this.getLocation());
    if (idxLang == null || idxLoc == null) {
      this.location.replaceState(this.location.path());
      window.location.reload();
    }
  }
}
