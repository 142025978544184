import {TaskWeak} from "./task";
import {User} from "./user";


export class Calendar {
  onlyDay: boolean = false;
  date: Date = new Date();
  engineerId: number = -1;

  constructor(value?: Calendar){
    this.onlyDay = value?.onlyDay ?? false;
    this.date = value?.date ?? new Date();
    this.engineerId = value?.engineerId ?? -1;
  }
}
