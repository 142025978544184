import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {LoginInfo, LoginInfoDto} from "../model/login";
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Buffer} from "buffer";
import {Header} from "../model/header";
import {TokenStorageService} from "./token.storage.service";
import {Router} from "@angular/router";
import {UsersRole} from "../model/user";
const headers = new HttpHeaders({
  'Content-Type': 'application/json'
});
@Injectable({
  providedIn: 'root'
})
export class LoginService{
  constructor(private httpClient: HttpClient,
              protected tokenStorageService: TokenStorageService,
              private router: Router,) {
  }

  getLoginInfo(): LoginInfo | undefined {
    return this.tokenStorageService.getLoginInfo();
  }

  getUserRole(client: boolean){
    if (this.getLoginInfo()) {
      if (client) {
        return !!this.getLoginInfo()?.roles.find(v => v == "ROLE_CLIENT");
      } else {
        return !!this.getLoginInfo()?.roles.find(v => (v == "ROLE_OPERATOR") ||
          (v == "ROLE_ENGINEER") || (v == "ROLE_SUPER_ADMIN"));
      }
    } else return false;
  }

  logout() {
    this.tokenStorageService.logout();
    setTimeout(() => {
      this.router.navigate(['/login']).then(() => {
        window.location.reload();
      });
    }, 200);
  }

  jwtParse(token: string) {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString()) ?? null;
  }

  updData(loginInfoDto: LoginInfoDto) {
    this.tokenStorageService.saveLoginInfo(this.jwtParse(loginInfoDto.token), loginInfoDto.token);
  }

  login(username: string, password: string): Observable<LoginInfoDto> {
      return this.httpClient.post<LoginInfoDto>(environment.apiUrl + '/authentication',
        { username: username, password: password }, {headers} );
  }

}

