import {Injectable, OnInit} from '@angular/core';
import placekit, {PKOptions} from "@placekit/client-js";
import {environment} from "../../environments/environment";
import {PlaceKit} from "../model/placeKitAddress";
import {LocalizationService} from "./localization.service";

@Injectable({
  providedIn: 'root'
})
export class PlaceKitService {
  constructor(protected localizationService: LocalizationService) {}

  private pk = placekit(environment.placeKitKey);

  options: PKOptions = {
    maxResults: 10,
    language: this.localizationService.getLangCode(),
    countries: [this.localizationService.getLocCode()]
  };

  async searchAddress(search: string) {
    let placeKit = new PlaceKit();
    await this.pk.search(search, this.options).then((res: any) => {
      placeKit = new PlaceKit(res);
    });
    return placeKit;
  }
}
