import {text} from "./localization";

export function isFixedPrice(bool?: boolean){
  if (bool != undefined) {
    return bool ? text(["Фиксированная", "Fijo"]) : text(["Зависит от объёма", "Depende del volumen"]);
  } return null;
}

export function isPeriodical(bool?: boolean){
  if (bool != undefined) {
    if (bool) {
      return text(["Переодическая", "Peródica"]);
    } else {
      return text(["Разовая", "Una sola vez"]);
    }
  } return null;
}
