import {text} from "../function/localization";

export class Header {
  id?: number;
  name: string = "default";
  title?: string = "";
  subTitle?: string = "";
  align?: string = "";
  menu?: boolean = false;
  clientMenu?: boolean = false;
  login?: boolean = false;
  buttonBack?: boolean = false;
  buttonBackUrl?: string = "/";
  constructor(value: Header) {
    this.id = value.id;
    this.title = value.title ?? "";
    this.align = value.align ?? "";
    this.menu = value.menu ?? false;
    this.clientMenu = value.clientMenu ?? false;
    this.login = value.login ?? false;
    this.buttonBack = value.buttonBack ?? false;
    this.buttonBackUrl = value.buttonBackUrl ?? "";
  }
}

export const headers: Header[] = [
  {
    id: 0,
    name: "Login",
    align: "left",
    menu: false,
    login: false,
  },
  {
    id: 1,
    name: "Client",
    align: "center",
    menu: false,
    clientMenu: true,
    login: true,
  },
  {
    id: 2,
    name: "Admin",
    align: "left",
    menu: true,
    login: true,
  }
];

export enum HeadersName  {
  Login,
  Client,
  Admin,
}
