import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {User} from "../model/user";
import {ResponsePage} from "../model/responsePage";
import {TableFilter} from "../model/table";
import {Task} from "../model/task";


@Injectable({
  providedIn: 'root'
})
export class ApiTask {
  constructor(private httpClient: HttpClient) {
  }

  getTasksByPeriod(dateFrom: string, dateTo: string) {
    const params = {
      dateFrom: dateFrom,
      dateTo: dateTo,
    };
    return this.httpClient.get<Task[]>(
      `${environment.apiUrl}/task/find/by-period`,
      {
        params: params
      }
    );
  }

  getTasksByEngineer(dateFrom: string, dateTo: string) {
    const params = {
      planDateFrom : dateFrom,
      planDateTo : dateTo,
    };
    return this.httpClient.get<Task[]>(
      `${environment.apiUrl}/task/engineer`,
      {
        params: params
      }
    );
  }
}
