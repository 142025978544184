import {text} from "../function/localization";

export class DirectoriesFieldsMenu {
  id: number;
  name: string;
  label: string;

  constructor(value: DirectoriesFieldsMenu) {
    this.id = value.id ?? 0;
    this.name = value.name ?? "";
    this.label = value.label ?? "";
  }
}

export const dataFieldsDirectories : DirectoriesFieldsMenu[] = [
  {
    id: 0,
    name: text(["Основная информация", "Información principal"]),
    label: "",
  },
  {
    id: 1,
    name: text(["Дополнительные услуги", "Servicios adicionales"]),
    label: "",
  },
  {
    id: 2,
    name: text(["Журнал осмотров", "Registro de inspecciones"]),
    label: "",
  },
];

