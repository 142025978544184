import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormComponent} from "../../../../../elements/form/form.component";
import {HeaderService} from "../../../../../../services/header.service";
import {Header, headers, HeadersName} from "../../../../../../model/header";
import {FormTemplate} from "../../../../../../model/form";
import {ApiService} from "../../../../../../services/api.service";
import {User} from "../../../../../../model/user";
import {ApiPath} from "../../../../../../model/global";
import {NgIf} from "@angular/common";
import {LoadingComponent} from "../../../../../elements/loading/loading.component";
import {NotificationService} from "../../../../../../services/notification.service";
import {NotificationForm, NotificationType} from "../../../../../../model/notification";
import {formTemplates, TemplatesNames} from "../../../../../../model/templates";
import {text} from "../../../../../../function/localization";

@Component({
  selector: 'app-user-page',
  standalone: true,
  imports: [
    FormComponent,
    NgIf,
    LoadingComponent
  ],
  templateUrl: './user-page.component.html',
  styleUrl: './user-page.component.scss'
})
export class UserPageComponent implements OnInit{
  constructor( private route: ActivatedRoute,
               protected headerService: HeaderService,
               protected apiService: ApiService,
               private router: Router,
               public notificationService: NotificationService) {

  }
  userId:  null | string = null;
  header: Header = new Header(headers[HeadersName.Admin]);
  formTemplate: FormTemplate = new FormTemplate(formTemplates[TemplatesNames.users]);
  loading: boolean = false;

  user: User = new User();

  ngOnInit() {
    this.userId = this.route.snapshot.paramMap.get('id') ?? 'add';
    this.header.buttonBackUrl = "/admin/admin"
    if (this.userId === 'add') {
      this.header.title = text(["Новая запись", "Nuevo registro"]);
      this.formTemplate.object = this.user;
      this.headerService.setCurrentHeader(this.header);
    } else {
      this.loading = true;
      this.apiService.getObj<User>(ApiPath.User, Number(this.userId)).subscribe((data) => {
        this.user = new User(data) ?? new User();
        this.header.title = this.user.getFIO();
        // if (this.user.active) {
        //   this.header.subTitle = "<h5>Активная запись — <span class='font-color__green'>да</span></h5>";
        // } else {
        //   this.header.subTitle = "<h5>Активная запись — <span class='font-color__red'>нет</span></h5>";
        // }
        this.formTemplate.object = new User(this.user);
        this.headerService.setCurrentHeader(this.header);
        // setTimeout(() => {
        this.loading = false;
        // }, 3000);

      });
    }
  }

  cancel(){
    this.router.navigate([this.header.buttonBackUrl],{relativeTo: this.route}).then(r => r);
  }

  saveUser(user: User): void {
    this.apiService.saveObj<User>(ApiPath.User, user).subscribe({
      next: () => {
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Запись успешно сохранена", "Registro guardado exitosamente"]),
          status: NotificationType.Success,
          text:
            text(["Пользователь", "Usuario"])
            + `${user.login}`
            + text(["успешно сохранён", "guardado exitosamente"]),
        }));
        this.cancel();
      },
      error: (err) => {
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Ошибка", "Error"]),
          status: NotificationType.Error,
          text: text(["Код ошибки ", "Código de error "]) + err.status + " " + err.error.message,
        }));
      }
    });

  }

}
