import {Component, OnInit} from '@angular/core';
import {FormComponent} from "../../../../elements/form/form.component";
import {ActivatedRoute, Router} from "@angular/router";
import {HeaderService} from "../../../../../services/header.service";
import {ApiService} from "../../../../../services/api.service";
import {Header, headers, HeadersName} from "../../../../../model/header";
import {FormTemplate} from "../../../../../model/form";
import {DocumentType} from "../../../../../model/documents";
import {ApiPath} from "../../../../../model/global";
import {Measure} from "../../../../../model/measure";
import {formTemplates, TemplatesNames} from "../../../../../model/templates";
import {NotificationForm, NotificationType} from "../../../../../model/notification";
import {NotificationService} from "../../../../../services/notification.service";
import {text} from "../../../../../function/localization";

@Component({
  selector: 'app-units-page',
  standalone: true,
  imports: [
    FormComponent
  ],
  templateUrl: './measure-page.component.html',
  styleUrl: './measure-page.component.scss'
})
export class MeasurePageComponent implements OnInit{
  constructor( private route: ActivatedRoute,
               protected headerService: HeaderService,
               protected apiService: ApiService,
               private router: Router,
               public notificationService: NotificationService) {}

  unitId: null | string = null;
  header: Header = new Header(headers[HeadersName.Form]);
  formTemplate: FormTemplate = new FormTemplate(formTemplates[TemplatesNames.measure]);
  loading: boolean = false;
  unit: Measure = new Measure();

  ngOnInit() {

    this.unitId = this.route.snapshot.paramMap.get('id') ?? 'add';
    this.header.buttonBackUrl = "/directories"
    if (this.unitId === 'add') {
      this.header.title = text(["Новая запись", "Nuevo registro"]);
      this.formTemplate.object = this.unit;
      this.loading = false;
      this.headerService.setCurrentHeader(this.header);
    } else {
      this.loading = true;
      this.apiService.getObj<Measure>(ApiPath.Measure, Number(this.unitId)).subscribe((data) => {
        this.unit = new Measure(data) ?? new Measure();
        this.header.title =
          text(['Редактирование единицы измерения "', 'Editar unidad de medida "']) + this.unit.name + '"';
        // this.header.subTitle = "<h5>Активная запись — <span class='font-color__green'>да</span></h5>";
        this.formTemplate.object = new Measure(this.unit);
        this.headerService.setCurrentHeader(this.header);
        this.loading = false;
      });
    }
  }


  cancel(){
    this.router.navigate([this.header.buttonBackUrl],{relativeTo: this.route}).then(r => r);
  }

  saveUnit(unit: Measure): void {
    this.apiService.saveObj<Measure>(ApiPath.Measure, unit).subscribe({
      next: () => {
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Запись успешно сохранена", "Registro guardado exitosamente"]),
          status: NotificationType.Success,
          text: text(["Единица измерения ", "Unidad de medida "])
            + `\"${unit.name}\"`
            + text([" успешно сохранена ", " guardada exitosamente"])
        }));
        this.cancel();
      },
      error: (err) => {
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Ошибка", "Error"]),
          status: NotificationType.Error,
          text: text(["Код ошибки ", "Código de error "]) + err.status + " " + err.error.message,
        }));
      },
    });
  }
}
