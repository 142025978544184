import {Component, OnInit} from '@angular/core';
import {FormComponent} from "../../../../../elements/form/form.component";
import {NgIf} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {HeaderService} from "../../../../../../services/header.service";
import {ApiService} from "../../../../../../services/api.service";
import {DirectoriesService} from "../../../../../../services/directories.service";
import {Header, headers, HeadersName} from "../../../../../../model/header";
import {FormTemplate} from "../../../../../../model/form";
import {Currency} from "../../../../../../model/currencies";
import {ObjectsTypeService} from "../../../../../../services/objects-type.service";
import {ObjectiveType} from "../../../../../../model/objectiveType";
import {ApiPath} from "../../../../../../model/global";
import {formTemplates, TemplatesNames} from "../../../../../../model/templates";
import {NotificationService} from "../../../../../../services/notification.service";
import {NotificationForm, NotificationType} from "../../../../../../model/notification";
import {text} from "../../../../../../function/localization";

@Component({
  selector: 'app-objects-page',
  standalone: true,
  imports: [
    FormComponent,
    NgIf
  ],
  templateUrl: './objects-type-page.component.html',
  styleUrl: './objects-type-page.component.scss'
})
export class ObjectsTypePageComponent implements OnInit{
  constructor( private route: ActivatedRoute,
               protected headerService: HeaderService,
               protected apiService: ApiService,
               private router: Router,
               protected objectTypeService: ObjectsTypeService,
               public notificationService: NotificationService) {}

  objectPageId: null | string = null;
  header: Header = new Header(headers[HeadersName.Admin]);
  formTemplate: FormTemplate = new FormTemplate(formTemplates[TemplatesNames.objectivesTypes]);
  loading: boolean = false;
  objectsType: ObjectiveType = new ObjectiveType();

  ngOnInit() {

    this.objectPageId = this.route.snapshot.paramMap.get('id') ?? 'add';
    this.header.buttonBackUrl = "/admin/directories"
    if (this.objectPageId === 'add') {
      this.header.title = text(["Новая запись", "Nuevo registro"]);
      this.formTemplate.object = this.objectsType;
      this.loading = false;
      this.headerService.setCurrentHeader(this.header);
    } else {
      this.loading = true;
      this.apiService.getObj<ObjectiveType>(ApiPath.ObjectType, Number(this.objectPageId)).subscribe((data) => {
        this.objectsType = new ObjectiveType(data) ?? new ObjectiveType();
        this.header.title = text(['Редактирование объекта "', 'Editar objeto "']) + this.objectsType.name + '"';
        // this.header.subTitle = "<h5>Активная запись — <span class='font-color__green'>да</span></h5>";
        this.formTemplate.object = new ObjectiveType(this.objectsType);
        this.headerService.setCurrentHeader(this.header);
        this.loading = false;
      });
    }
  }

  cancel(){
    this.router.navigate([this.header.buttonBackUrl],{relativeTo: this.route}).then(r => r);
  }

  saveObjectType(objectType: ObjectiveType): void {
    this.apiService.saveObj<ObjectiveType>(ApiPath.ObjectType, objectType).subscribe({
      next: () => {
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Запись успешно сохранена", "Registro guardado exitosamente"]),
          status: NotificationType.Success,
          text: text(["Тип объекта ", "Tipo de objeto "])
            + `\"${objectType.name}\"`
            + text([" успешно сохранён", " guardado exitosamente"]),
        }));
        this.cancel();
      },
      error: (err) => {
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Ошибка", "Error"]),
          status: NotificationType.Error,
          text: text(["Код ошибки ", "Código de error "]) + err.status + " " + err.error.message,
        }));
      },
    });
  }
}
