import {Component, OnInit} from '@angular/core';
import {FormComponent} from "../../../../elements/form/form.component";
import {ActivatedRoute, Router} from "@angular/router";
import {HeaderService} from "../../../../../services/header.service";
import {ApiService} from "../../../../../services/api.service";
import {NotificationService} from "../../../../../services/notification.service";
import {Header, headers, HeadersName} from "../../../../../model/header";
import {FormTemplate} from "../../../../../model/form";
import {formTemplates, TemplatesNames} from "../../../../../model/templates";
import {Client} from "../../../../../model/client";
import {Task} from "../../../../../model/task";
import {ApiPath} from "../../../../../model/global";
import {NotificationForm, NotificationType} from "../../../../../model/notification";
import {LoginService} from "../../../../../services/login.service";
import {formatDate} from "../../../../../function/formatDate";
import {DefectType} from "../../../../../model/defectType";
import {Defect} from "../../../../../model/defect";
import {OurFile} from "../../../../../model/ourFile";
import {text} from "../../../../../function/localization";

@Component({
  selector: 'app-task-page',
  standalone: true,
  imports: [
    FormComponent
  ],
  templateUrl: './task-page.component.html',
  styleUrl: './task-page.component.scss'
})
export class TaskPageComponent implements OnInit{
  constructor( private route: ActivatedRoute,
               protected headerService: HeaderService,
               protected apiService: ApiService,
               private router: Router,
               public notificationService: NotificationService,
               protected loginService: LoginService) {}

  tuskId: null | string = null;
  header: Header = new Header(headers[HeadersName.Form]);
  formTemplate: FormTemplate = new FormTemplate(formTemplates[TemplatesNames.taskRegistry]);
  loading: boolean = false;
  tuskRegistry: Task = new Task();

  ngOnInit() {
    this.tuskId = this.route.snapshot.paramMap.get('id') ?? 'add';
    this.header.buttonBackUrl = "/tasks"
    if (this.tuskId === 'add') {
      this.header.title = text(["Новая запись", "Nuevo registro"]);
      this.formTemplate.object = this.tuskRegistry;
      this.loading = false;
      this.headerService.setCurrentHeader(this.header);
    } else {
      this.loading = true;
      this.apiService.getObj<Task>(ApiPath.Task, Number(this.tuskId)).subscribe((data) => {
        this.tuskRegistry = new Task(data) ?? new Task();
        this.checkDate();
        // this.header.title = "Задание на осмотр " + formatDate(new Date(this.tuskRegistry.planDate ?? ""));
        // this.header.subTitle = "<h5>Активная запись — <span class='font-color__green'>да</span></h5>";
        this.formTemplate.object = new Task(this.tuskRegistry);
        this.headerService.setCurrentHeader(this.header);
        this.loading = false;
      });
    }
  }


  formatStatus(tuskRegistry: Task){
    // const isEngineer = this.loginService.getLoginInfo()?.roles.find(v => v == 'ROLE_ENGINEER');
    tuskRegistry.status = "PLANNED";
    return tuskRegistry;
  }


  cancel(){
    this.router.navigate([this.header.buttonBackUrl],{relativeTo: this.route}).then(r => r);
  }

  save(tuskRegistry: Task): void {
    const task: Task = this.formatStatus(tuskRegistry);
    this.apiService.saveObj<Task>(ApiPath.Task, task).subscribe({
      next: () => {
        const tuskNumber = tuskRegistry.contract?.number ?? "";
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Запись успешно сохранена", "Registro guardado exitosamente"]),
          status: NotificationType.Success,
          text: text(["Задача по договору ", "Tarea del contrato "])
            + `\"${tuskNumber}\"`
            + text([" успешно сохранена", " guardada exitosamente"]),
        }));
        this.cancel();
      },
      error: (err) => {
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Ошибка", "Error"]),
          status: NotificationType.Error,
          text: text(["Код ошибки ", "Código de error "]) + err.status + " " + err.error.message,
          // text: text(["Код ошибки ", "Código de error "]) + `${err?.result ?? JSON.stringify(err.message)}`,
        }));
      },
    });
  }

  checkDate() {
    if (this.tuskRegistry.planDate == null) {
      this.header.title = text(["Задание на осмотр ", "Tarea de inspección "]);
    } else {
      this.header.title =
        text(["Задание на осмотр ", "Tarea de inspección "]) + formatDate(new Date(this.tuskRegistry.planDate ?? ""));
    }
  }

}
