import {Component, Input} from '@angular/core';
import {Objective} from "../../../model/objective";
import {getAddress} from "../../../function/getAddress";

@Component({
  selector: 'app-legal-contract',
  standalone: true,
  imports: [],
  templateUrl: './legal-contract.component.html',
  styleUrl: './legal-contract.component.scss'
})
export class LegalContractComponent {
  @Input() number: number | null = null;
  @Input() beginDate: string | Date | null = null;
  @Input() endDate: string | Date | null = null;
  @Input() objective: Objective | null = null;
  // @Input() address: string = "";
  // @Input() flatNumber: number | null = null;
  @Input() active: boolean = false;


  checkActive() {
      if (this.active != null) {
        if (this.active) {
          return "Действующий"
        } else return "Не действует";
      } else return "";
  }


  protected readonly getAddress = getAddress;
}
