import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {LoginService} from "../../../services/login.service";
import {NgIf} from "@angular/common";
import {ButtonComponent} from "../../elements/button/button.component";
import {Router} from "@angular/router";
import {NotificationService} from "../../../services/notification.service";
import {NotificationForm, NotificationType} from "../../../model/notification";
import {text} from "../../../function/localization";
import {CheckboxComponent} from "../../elements/checkbox/checkbox.component";
import {IconComponent} from "../../elements/icon/icon.component";
import {iconsByName} from "../../../model/icon";
import {HeaderService} from "../../../services/header.service";
import {headers} from "../../../model/header";
import {UsersRole} from "../../../model/user";
import {InterfaceService} from "../../../services/interface-service";
import {SaveStorage} from "../../../services/save-storage.service";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    ButtonComponent,
    CheckboxComponent,
    IconComponent
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit{
  constructor(public loginService: LoginService,
              private router: Router,
              public notificationService: NotificationService,
              private headerService: HeaderService,
              protected interfaceService: InterfaceService,
              public saveStorage: SaveStorage,) {
  }
  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  showError: boolean = false;
  showSuccess: boolean = false;
  loginError: string | undefined;
  isLogin: boolean = false;
  chooseMode: boolean = false;
  isClient: boolean = false;
  isAdmin: boolean = false;

  ngOnInit() {
    this.headerService.setCurrentHeader(headers[0]);
  }


  get username() {
    return this.loginForm.get('username');
  }

  get password() {
    return this.loginForm.get('password');
  }


  login() {

    this.loginService.login(this.username?.value ?? '', this.password?.value ?? '').subscribe({
      next: data => {
        this.loginService.updData(data);
        this.isClient = this.loginService.getUserRole(true);
        this.isAdmin = this.loginService.getUserRole(false);
        if (this.isClient || this.isAdmin) {
          if (this.isClient && this.isAdmin) {
            this.chooseMode = true;
          } else {
            if (this.isAdmin) {
              this.loginAsAdmin();
            } else {
              if (this.isClient) {
                this.loginAsClient();
              }
            }
          }
        } else {
          this.notificationService.callNotification(new NotificationForm({
            label: text(["Ошибка", "Error"]),
            status: NotificationType.Error,
            text: text(["У Вас не установлена роль. Обратитесь к администратору ",
              "No tienes un rol instalado. Contacta con tu administrador "])}));
        }
        this.notificationService.callNotification(new NotificationForm({
          label: text(["Успешный вход", "Inicio de sesión exitoso"]),
          status: NotificationType.Success,
          text: text(["Вы вошли как ", "Has iniciado sesión como "]) + `${this.username?.value}`
        }));
      },
      error: error => {
        if (error.code == 401) {
          this.notificationService.callNotification(new NotificationForm({
            label: text(["Ошибка", "Error"]),
            status: NotificationType.Error,
            text: text(["Неправильный логин или пароль", "Usuario o contraseña incorrectos"]),
          }));
        } else {
          this.notificationService.callNotification(new NotificationForm({
            label: text(["Ошибка", "Error"]),
            status: NotificationType.Error,
            text: text(["Код ошибки ", "Código de error "]) + `${error.status}`
          }));
        }
      },
    });
  }

  loginAsClient(){
    this.interfaceService.setActiveInterface(1);
    this.router.navigate(['client']).then(r => r);
    this.saveStorage.setInterfaceService(1);
  }

  loginAsAdmin(){
    this.interfaceService.setActiveInterface(0);
    this.router.navigate(['admin']).then(r => r);
    this.saveStorage.setInterfaceService(0);
  }

  protected readonly text = text;
  protected readonly iconsByName = iconsByName;
  protected readonly UsersRole = UsersRole;
}
