import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PlaceKitService} from "../../../services/place-kit.service";
import {PlaceKit, PlaceKitAddress} from "../../../model/placeKitAddress";
import {FormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {CheckboxComponent} from "../checkbox/checkbox.component";
import {ClickedOutsideDirectiveDirective} from "../../directive/clicked-outside-directive.directive";
import {AutoSetRule, RelationRule} from "../../../model/relationRule";
import {text} from "../../../function/localization";

@Component({
  selector: 'app-autocomplete',
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    NgForOf,
    CheckboxComponent,
    ClickedOutsideDirectiveDirective
  ],
  templateUrl: './autocomplete.component.html',
  styleUrl: './autocomplete.component.scss'
})
export class AutocompleteComponent implements OnInit {
  constructor(private placeKitService: PlaceKitService) {}

  @Input() value: string | null = null;
  @Input() autoSetRules: AutoSetRule[] = [];
  @Output() emitAddress = new EventEmitter<string>();
  @Output() emitAutoSetRules = new EventEmitter<AutoSetRule[]>();

  searchResult: PlaceKitAddress[] = [];

  handleInput: boolean = false;

  searchString: string = "";

  isSelected: boolean = true;

  ngOnInit() {
    this.searchString = this.value ?? "";
  }


  search(query: string) {
    this.emitAddress.emit(this.searchString);
    if (!this.handleInput) {
      if (query.length > 2) {
        this.isSelected = false;
        this.placeKitService.searchAddress(query).then((res) => {
          this.searchResult = res.results.map(v => new PlaceKitAddress(v));
        });
      } else {
        this.isSelected = true;
      }
    }
  }

  selectItem(item: PlaceKitAddress) {
    this.isSelected = true;
    this.searchString = item.getAddress();
    this.searchResult = [];
    this.autoSetRules.forEach(v => {
      if (v.determinantKey == "latitude") {
        v.value = item.lat;
      }
      if (v.determinantKey == "longitude") {
        v.value = item.lng;
      }
    })
    this.emitAddress.emit(this.searchString);
    this.emitAutoSetRules.emit(this.autoSetRules);
  }

  changeMode(value: boolean) {
    this.handleInput = value;
    this.reset();
  }

  reset() {
    this.searchResult = [];
    this.isSelected = true;
  }

  isSearch() {
    return !this.handleInput && this.searchString.length > 2 && !this.isSelected;
  }

  protected readonly text = text;
}
