import {Injectable} from '@angular/core';
import {LoginInfo, LoginInfoDto} from "../model/login";

const LOGIN_INFO_TOKEN = 'token';
const LOGIN_INFO = 'login-info';
const PASSWORD = 'password';

@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {

    logout(): void {
        window.sessionStorage.clear();
    }

    public saveLoginInfo(loginInfo: LoginInfo | null, token: string): void {
      window.sessionStorage.removeItem(LOGIN_INFO);
      window.sessionStorage.setItem(LOGIN_INFO, JSON.stringify(loginInfo));
      window.sessionStorage.removeItem(LOGIN_INFO_TOKEN);
      window.sessionStorage.setItem(LOGIN_INFO_TOKEN, JSON.stringify(token));
    }

    public getToken(): string | undefined {
      const tokenDto = window.sessionStorage.getItem(LOGIN_INFO_TOKEN);
      if (tokenDto) {
        return JSON.parse(tokenDto);
      }
      return undefined;
    }

    public getLoginInfo(): LoginInfo | undefined {
        const loginInfo = window.sessionStorage.getItem(LOGIN_INFO);
        if (loginInfo) {
            return JSON.parse(loginInfo);
        }
        return undefined;
    }

    public savePassword(password: string) {
      window.sessionStorage.removeItem(PASSWORD);
    }

}
