import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {text} from "../../../function/localization";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-input-password',
  standalone: true,
  imports: [
    FormsModule,
    NgIf
  ],
  templateUrl: './input-password.component.html',
  styleUrl: './input-password.component.scss'
})
export class InputPasswordComponent implements OnInit {
  @Input() value: string = "";
  @Input() confirm: boolean = false;
  @Input() passwordIncorrect: boolean = false;
  @Output() emitChange = new EventEmitter();

  secondValue: string = '';
  bottomColor: number | null = null;
  strong: number = 0;

  ngOnInit() {
    this.checkPassword();
  }

  checkPassword() {
    this.bottomColor = 0;
    const pass = this.value;

    const bottomReg = "qwertyuiopasdfghjklzxcvbnm";
    const topReg = "QWERTYUIOPLKJHGFDSAZXCVBNM";
    const numbers = "0123456789";
    const symbols = "!@#$%^&*()_-+=\\|/.,:;[]{}";

    let upSym = false;
    let botSym = false;
    let numSym = false;
    let symSym = false;

    let strong = 0;

    for (let i = 0; i < pass.length; i++) {
      if (!upSym && bottomReg.indexOf(pass[i]) != -1) {
        upSym = true;
      }
      if (!botSym && topReg.indexOf(pass[i]) != -1) {
        botSym = true;
      }
      if (!numSym && numbers.indexOf(pass[i]) != -1) {
        numSym = true;
      }
      if (!symSym && symbols.indexOf(pass[i]) != -1) {
        symSym = true;
      }
    }
    if (upSym) {
      strong++;
    }
    if (botSym) {
      strong++;
    }
    if (numSym) {
      strong++;
    }
    if (symSym) {
      strong++;
    }

    if (pass.length < 8 && pass.length == 0) {
      this.bottomColor = 9;
      return '';
    } else {
      if (pass.length < 8) {
        this.bottomColor = 0;
        return "(" + text(['ненадежный', 'poco seguro'])  + ")";
      } else {
        this.strong = strong;
        switch (strong) {
          case 0:
            this.bottomColor = 1;
            return "(" + text(['слабый', 'débil']) + ")";
          case 1:
            this.bottomColor = 2;
            return "(" + text(['легкий', 'fácil']) + ")";
          case 2:
            this.bottomColor = 3;
            return "(" + text(['средний', 'promedio']) + ")";
          case 3:
            this.bottomColor = 4;
            return "(" + text(['хороший', 'bueno']) + ")";
          case 4:
            this.bottomColor = 5;
            return "(" + text(['отличный', 'excelente']) + ")";
          default:
            return '';
        }
      }
    }

  }

  getBottomColor() {
    this.checkPassword();
    if (this.bottomColor != null) {
      switch (this.bottomColor) {
        case 0:
          return "full-red";
        case 1:
          return "small-red";
        case 2:
          return "medium-orange";
        case 3:
          return "medium-yellow";
        case 4:
          return "medium-green";
        case 5:
          return "full-green";
        case 9:
          return '';
        default: return '';
      }
    } else return ""
  }

  coincidence() {
    if (this.value.length != 0 && this.secondValue.length != 0) {
      if (this.value != this.secondValue) {
        return text(['Пароли не совпадают', 'Las contraseñas no coinciden'])
      } else return '';
    } else return "";
  }

  sendPassword() {
    if (((this.bottomColor ?? 0) > 1) && (this.value == this.secondValue)) {
      this.emitChange.emit(this.value);
    } else {
      this.emitChange.emit(null);
    }
  }


  protected readonly text = text;
}
