<div *ngIf="loading" class="loading-container">
  <app-loading />
</div>
<div *ngIf="!loading" class="table-container">
  <table >
    <colgroup>
      <col *ngFor="let column of tableTemplate.columns"/>
    </colgroup>
    <thead>
    <tr class="header-tr" style="height: 1px;">
      <th class="header-th title-style-4" *ngFor="let column of tableTemplate.columns">
        <div class="header-block" [class]="{'header-block-no-sort': !column.filtering}">
          <div *ngIf="column.filtering != undefined" class="header-block-icons">
            <app-filter
              [field]="column.key"
              [filtering]="column.filtering"
              [onChange]="refreshSort"
              [tableFilter]="tableFilter"
              (tableFilterEmit)="tableFilterEmit.emit($event)"
            />
          </div>
          <div class="header-block-label" [class]="{'header-block-label-no-sort': !column.filtering}">
            {{ column.label }}
          </div>
        </div>
      </th>
    </tr>
    </thead>
    <tbody *ngIf="tableTemplate.content.length > 0; else noDate" class="table-body">
    <tr
      *ngFor="let row of tableTemplate.content; index as i"
      class="table-row" [class]="getStyleToRow(row)"
      (click)="clickOnRow(row)"
    >
      <th
        *ngFor="let header of tableTemplate.columns"
        [style.width]="header.width"
      >
        <div *ngIf="header.key == 'index'">
          {{ i + 1 }}
        </div>
<!--        <div class="cell" *ngIf="header.key == 'selectors'; else value">-->
<!--          <app-checkbox/>-->
<!--        </div>-->
        <div
          *ngIf="header.columnType == ColumnType.Checkbox; else value"
          [style.text-align]="header.align"
          class="cell cell-checkbox"
          (click)="$event.stopPropagation()"
        >
          <app-checkbox
            [value]="formatingValue(header.key, header.columnType, row)"
            [disable]="disable"
            (valueOutput)="clickOnCheckbox(i, $event)"/>
        </div>
        <div *ngIf="header.columnType == ColumnType.Actions" (click)="$event.stopPropagation()">
          <div class="delete-trash" (click)="deleteRow.emit(row)">
            <app-icon [icon]="iconsByName.trash"/>
          </div>
        </div>
        <ng-template #value>
          <div class="cell" [style.text-align]="header.align">
            {{ formatingValue(header.key, header.columnType, row, header.labelKey) }}
          </div>
        </ng-template>
      </th>
    </tr>
    </tbody>
    <ng-template #noDate>
      <tfoot>
        <tr>
          <th [colSpan]="tableTemplate.columns.length" class="noData">
            {{ text(["Записи отсутствуют", "No hay registros"]) }}
          </th>
        </tr>
      </tfoot>
    </ng-template>
  </table>
</div>
