import {Measure} from "./measure";

export class ServiceType {
  id?:               number;
  name:              string;
  description?:       string;
  measure: Measure = new Measure;

  constructor(value?: ServiceType) {
    if(value?.id != undefined) this.id = value?.id;
    this.name = value?.name ?? "";
    this.description = value?.description;
    this.measure = value?.measure ?? new Measure;
  }
}
