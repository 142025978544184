import {text} from "../function/localization";

export class Menu {
  id: number;
  name: string;
  label: string;
  link: string;
  constructor(value: Menu) {
    this.id = value.id ?? 0;
    this.name = value.name ?? "";
    this.label = value.label ?? "";
    this.link = value.link ?? "/";
  }
}

export const menuItems: Menu[] = [
  {
    id: 0,
    name: "",
    label: text(["Договоры", "Contratos"]),
    link: "/contracts"
  },
  {
    id: 1,
    name: "",
    label: text(["Журнал заданий", "Registro de tareas"]),
    link: "/tasks"
  },
  {
    id: 2,
    name: "",
    label: text(["Справочники", "Directorio"]),
    link: "/directories"
  },
  {
    id: 3,
    name: "",
    label: text(["Администрирование", "Administración"]),
    link: "/admin"
  }
];

export enum MenuNames {
  Contracts,
  Tasks,
  Directories,
  Admin
}
