<div class="input-big-password-container">
  <div class="input-password-container">
    <div class="input-password-title-container">
      <div class="input-password-title">{{ text(['Пароль', 'Contraseña']) }}</div>
      <div class="input-password-asterisk">*</div>
      <div class="input-password-coincidence" [class]="getBottomColor()">{{ checkPassword() }}</div>
    </div>
    <div class="input-container">
      <input
        type="password"
        class="input-password"
        [(ngModel)]="value"
        (change)="sendPassword()"

      />
    </div>
    <div class="bottom-color" [class]="getBottomColor()"></div>
    <div class="error-container" *ngIf="passwordIncorrect && (value.length == 0)">
      {{ text(['Обязательное поле', 'Campo obligatorio']) }}
    </div>
    <div class="error-container" *ngIf="(value != secondValue) && (value.length != 0) && (secondValue.length != 0)">
      {{ text(['Пароли не совпадают', 'Las contraseñas no coinciden']) }}
    </div>
    <div class="error-container" *ngIf="passwordIncorrect && (value == secondValue) && (secondValue.length > 0)">
      {{ text(['Ваш пароль слишком простой', 'Tu contraseña es demasiado simple']) }}
    </div>

  </div>
  <div class="input-password-container">
    <div class="input-password-title-container">
      <div class="input-password-title">
        {{ text(['Повторите пароль', 'Repita la contraseña']) }}
      </div>
      <div class="input-password-asterisk">*</div>
    </div>
    <div class="input-container">
      <input
        type="password"
        class="input-password"
        [(ngModel)]="secondValue"
        (change)="sendPassword()"
      />
    </div>
    <div class="error-container" *ngIf="passwordIncorrect && (secondValue.length == 0)">
      {{ text(['Обязательное поле', 'Campo obligatorio']) }}
    </div>
    <div class="error-container" *ngIf="(value != secondValue) && (secondValue.length != 0) && (value.length != 0)">
      {{ text(['Пароли не совпадают', 'Las contraseñas no coinciden']) }}
    </div>
    <div class="error-container" *ngIf="passwordIncorrect && (value == secondValue) && (secondValue.length > 0)">
      {{ text(['Ваш пароль слишком простой', 'Tu contraseña es demasiado simple']) }}
    </div>
  </div>
</div>
