import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ApiPath} from "../model/global";
import {environment} from "../../environments/environment";
import {Contract} from "../model/contract";

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(private httpClient: HttpClient) {}
  fileUrl: any = null;
  upload(file: File[], id: number): Observable<Contract> {
    const formData = new FormData();
    file.forEach(v => {
      formData.append("file", v);
    })
    return this.httpClient.post<Contract>( `${environment.apiUrl}/${ApiPath.UploadFile}/${id}`, formData);
  }

  download(id: number): Observable<any>{
    return this.httpClient.get(`${environment.apiUrl}/${ApiPath.DownloadFile}/${id}`, {observe: 'response', responseType: 'blob' });
  }

  downloadFileDefect(taskId: number, defectId: number, defectFileId: number,): Observable<any>{
    return this.httpClient.get(`${environment.apiUrl}/${ApiPath.DownloadFileDefects}/${defectFileId}`,
      {observe: 'response', responseType: 'blob' });
  }

  delete(id: number): Observable<any> {
    return this.httpClient.delete( `${environment.apiUrl}/${ApiPath.DeleteFile}/${id}`);
  }
}
