import {Option} from "./option";
import {Country} from "./country";

export enum UsersRole {
  ROLE_OPERATOR,
  ROLE_ENGINEER,
  ROLE_CLIENT,
  ROLE_SUPER_ADMIN,
}


export const roleLabelMap = new Map<UsersRole, string[]>([
  [UsersRole.ROLE_OPERATOR, ["Оператор", "Operador"]],
  [UsersRole.ROLE_ENGINEER, ["Инженер", "Ingeniero"]],
  [UsersRole.ROLE_CLIENT, ["Клиент", "Cliente"]],
  [UsersRole.ROLE_SUPER_ADMIN, ["Суперадмин", "Superadmin"]],
]);

export class User {
  id?: number;
  login: string = "";
  surname: string = "";
  name: string = "";
  patronymic: string = "";
  birthDate: Date = new Date();
  roles: UsersRole[] = [];
  phone: string = "";
  email: string = "";
  country: Country = new Country();
  active: boolean = true;
  password?: string;
  constructor(value?: User) {
    if (value?.id !== undefined) this.id = value.id;
    this.login = value?.login ?? "";
    this.surname = value?.surname ?? "";
    this.name = value?.name ?? "";
    this.patronymic = value?.patronymic ?? "";
    this.birthDate = value?.birthDate ?? new Date();
    this.roles = value?.roles ?? [];
    this.phone = value?.phone ?? "";
    this.email = value?.email ?? "";
    this.country = value?.country ?? new Country();
    this.active = value?.active ?? true;
    this.password = value?.password;
  }
  getFIO () {
    const surname = this.surname ?? "";
    const name = this.name ?? "";
    const patronymic = this.patronymic ?? "";
    const login = this.login ? "(" + this.login +")" : "";
    return surname + " " + name + " " + patronymic + login;
  }
}
