<div *ngIf="display" class="filter-container" appClickedOutsideDirective (clickedOutside)="this.open = false">
  <div class="filter-menu">
    <app-tool
      *ngIf="filtering?.sorting"
      (click)="getSorting($event)"
      [disable]="sort == Sort.None"
      [icon]="getSortIcon()"
      [text]="text(['Сортировка', 'Clasificación'])"
      [indexIndicator]="getIndexIndicator()"
      class="filter-menu-item"
    />
    <app-tool
      *ngIf="filtering?.filtering"
      (click)="open = !open"
      [disable]="!isActive()"
      [dot]="isActive()"
      [icon]="iconsByName.filter"
      [text]="text(['Фильтр', 'Filtro'])"
      class="filter-menu-item"
    />
  </div>
  <div
    *ngIf="open"
    class="filter-context-menu-container"
    [class]="{'wide-container': filtering?.wide,
     'filter-context-left': filtering?.position == FilteringPosition.left}"
  >
      <div class="sorting-container">
        <div class="sort-title">
          <h5>{{ filtering?.title ?? "" }}</h5>
          <app-icon (click)="open = false" [icon]="iconsByName.crossClose" class="cross-close-filter"/>
        </div>
        <div *ngIf="filtering?.filteringType == FilteringType.DropDownListMultiselect" class="sort-item-container">
          <app-drop-down-list-multiselect
            [method]="filtering?.method ?? ApiPath.Default"
            [value]="searchItems"
            [labelKey]="filtering?.labelKey ?? 'name'"
            [isClearSelected]="true"
            [specifyLabelType]="filtering?.specifyLabelType ?? SpecifyLabelType.Default"
            [optionList]="filtering?.options ?? []"
            [presetIds]="searchItems"
            [filter]="filtering?.tableFilter ?? defaultFilter"
            (emitChange)="setTableFilter($event)"
          />
        </div>
        <div class="sort-item-container" *ngIf="filtering?.filteringType == FilteringType.Date">
          <h5>Дата с:</h5>
          <div class="sort-data-container">
            <input
              type="date"
              [(ngModel)]="dateStart"
              (ngModelChange)="setTableFilter($event)"
            />
            <app-icon (click)="dateStart = null; setTableFilter(dateStart)" [icon]="iconsByName.crossClose" class="cross-close-filter"/>
          </div>
          <h5>Дата по:</h5>
          <div class="sort-data-container">
          <input
            type="date"
            [(ngModel)]="dateEnd"
            (ngModelChange)="setTableFilter($event)"
            />
            <app-icon (click)="dateEnd = null; setTableFilter(dateEnd)" [icon]="iconsByName.crossClose" class="cross-close-filter"/>
          </div>
      </div>
  </div>
</div>
