import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {User} from "../model/user";
import {ResponsePage} from "../model/responsePage";
import {TableFilter} from "../model/table";
import {Header, headers} from "../model/header";


@Injectable({
  providedIn: 'root'
})
export class LegalMenuService {
  constructor(private httpClient: HttpClient) {}
  private activeLegalMenu: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  getActiveMenu() {
    return this.activeLegalMenu.value;
  }


  setActiveMenu(value: number) {
    this.activeLegalMenu.next(value);
  }
}
