import {Component, OnInit} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {ButtonComponent} from "../../elements/button/button.component";
import {PaginationComponent} from "../../elements/pagination/pagination.component";
import {TableComponent} from "../../elements/table/table.component";
import {defaultFilter, TableFilter, TableTemplate} from "../../../model/table";
import {SectionComponent} from "../../elements/section/section.component";
import {headers, HeadersName} from "../../../model/header";
import {HeaderService} from "../../../services/header.service";
import {ChangeDetection} from "@angular/cli/lib/config/workspace-schema";
import {dataDirectories, DirectoriesMenu} from "../../../model/directories";
import {ApiService} from "../../../services/api.service";
import {ApiPath, UrlPath} from "../../../model/global";
import {Country} from "../../../model/country";
import {MenuNames} from "../../../model/menu";
import {MenuService} from "../../../services/menu.service";
import {Currency} from "../../../model/currencies";
import {DocumentType} from "../../../model/documents";
import {User} from "../../../model/user";
import {ActivatedRoute, Router} from "@angular/router";
import {DirectoriesService} from "../../../services/directories.service";
import {ObjectiveType} from "../../../model/objectiveType";
import {Measure} from "../../../model/measure";
import {Client} from "../../../model/client";
import {Objective} from "../../../model/objective";
import {Tariff} from "../../../model/tariff";
import {tablesTemplates, TemplatesNames} from "../../../model/templates";
import {ServiceType} from "../../../model/serviceType";
import {DefectType} from "../../../model/defectType";
import {Task} from "../../../model/task";
import {SaveStorage} from "../../../services/save-storage.service";
import {text} from "../../../function/localization";


@Component({
  selector: 'app-directories',
  standalone: true,
  imports: [
    ButtonComponent,
    NgForOf,
    PaginationComponent,
    TableComponent,
    SectionComponent,
    NgIf
  ],
  templateUrl: './directories.component.html',
  styleUrl: './directories.component.scss'
})


export class DirectoriesComponent implements OnInit {
  constructor(protected headerService: HeaderService,
              protected getService: ApiService,
              protected menuService: MenuService,
              private router: Router,
              private route: ActivatedRoute,
              protected directoriesService: DirectoriesService,
              public saveStorage: SaveStorage) {}

  directories: DirectoriesMenu[] = dataDirectories;
  tableTemplate: TableTemplate = new TableTemplate();
  loading: boolean = false;
  tableFilter: TableFilter = defaultFilter;
  title: string = text(["Страны", "Países"]);
  refreshSort: number = 0;

  changePageDirectory(newTableFilter: TableFilter) {
    this.tableFilter = newTableFilter;
    switch (this.directoriesService.getActiveDirectory()) {
      case 0:
        this.saveStorage.setTableFilter(TemplatesNames.countries, this.tableFilter);
        break;
      case 1:
        this.saveStorage.setTableFilter(TemplatesNames.currency, this.tableFilter);
        break;
      case 2:
        this.saveStorage.setTableFilter(TemplatesNames.documents, this.tableFilter);
        break;
      case 3:
        this.saveStorage.setTableFilter(TemplatesNames.objectivesTypes, this.tableFilter);
        break;
      case 4:
        this.saveStorage.setTableFilter(TemplatesNames.measure, this.tableFilter);
        break;
      case 5:
        this.saveStorage.setTableFilter(TemplatesNames.clients, this.tableFilter);
        break;
      case 6:
        this.saveStorage.setTableFilter(TemplatesNames.objectives, this.tableFilter);
        break;
      case 7:
        this.saveStorage.setTableFilter(TemplatesNames.tariffs, this.tableFilter);
        break;
      case 8:
        this.saveStorage.setTableFilter(TemplatesNames.serviceType, this.tableFilter);
        break;
      case 9:
        this.saveStorage.setTableFilter(TemplatesNames.defectsType, this.tableFilter);
        break;
      default:
        this.saveStorage.setTableFilter(TemplatesNames.countries, this.tableFilter);
        break;
    }
    this.changeDirectory(this.directoriesService.getActiveDirectory());
  }

  changeDirectory(index: number) {
    this.directoriesService.setActiveDirectory(index);
    const i = index;
    switch (index) {
      case 0:
        this.tableTemplate = tablesTemplates[TemplatesNames.countries];
        this.getDataDirectory<Country>(ApiPath.Country, TemplatesNames.countries);
        this.title = text(["Страны", "Países"]);
        break;
      case 1:
        this.tableTemplate = tablesTemplates[TemplatesNames.currency];
        this.getDataDirectory<Currency>(ApiPath.Currency, TemplatesNames.currency);
        this.title = text(["Валюты", "Monedas"]);
        break;
      case 2:
        this.tableTemplate = tablesTemplates[TemplatesNames.documents];
        this.getDataDirectory<DocumentType>(ApiPath.Document, TemplatesNames.documents);
        this.title = text(["Типы документов", "Tipos de documentos"]);
        break;
      case 3:
        this.tableTemplate = tablesTemplates[TemplatesNames.objectivesTypes];
        this.getDataDirectory<ObjectiveType>(ApiPath.ObjectType, TemplatesNames.objectivesTypes);
        this.title = text(["Типы объектов", "Tipos de objetos"]);
        break;
      case 4:
        this.tableTemplate = tablesTemplates[TemplatesNames.measure];
        this.getDataDirectory<Measure>(ApiPath.Measure, TemplatesNames.measure);
        this.title = text(["Единицы измерения", "Unidades de medida"]);
        break;
      case 5:
        this.tableTemplate = tablesTemplates[TemplatesNames.clients];
        this.getDataDirectory<Client>(ApiPath.Client, TemplatesNames.clients);
        this.title = text(["Клиенты", "Clientes"]);
        break;
      case 6:
        this.tableTemplate = tablesTemplates[TemplatesNames.objectives];
        this.getDataDirectory<Objective>(ApiPath.Objective, TemplatesNames.objectives);
        this.title = text(["Объекты", "Objetos"]);
        break;
      case 7:
        this.tableTemplate = tablesTemplates[TemplatesNames.tariffs];
        this.getDataDirectory<Tariff>(ApiPath.Tariff, TemplatesNames.tariffs);
        this.title = text(["Тарифы", "Tarifas"]);
        break;
      case 8:
        this.tableTemplate = tablesTemplates[TemplatesNames.serviceType];
        this.getDataDirectory<ServiceType>(ApiPath.ServiceType, TemplatesNames.serviceType);
        this.title = text(["Типы услуг", "Tipos de servicios"]);
        break;
      case 9:
        this.tableTemplate = tablesTemplates[TemplatesNames.defectsType];
        this.getDataDirectory<DefectType>(ApiPath.DefectType, TemplatesNames.defectsType);
        this.title = text(["Типы дефектов", "Tipos de defectos"]);
        break;
      default:
        this.tableTemplate = tablesTemplates[TemplatesNames.countries];
        this.getDataDirectory<Country>(ApiPath.Country, TemplatesNames.countries);
        this.title = text(["Страны", "Países"]);
        break;
    }
  };



  getDataDirectory<T>(path: string, name: TemplatesNames) {
    this.loading = true;
    this.tableFilter = this.saveStorage.getTableFilter(name);
    this.getService.getList<T>(path, this.tableFilter)
      .subscribe((data) => {
        this.tableTemplate.content = data.content ?? [];
        // TODO уёбищное решение, когда с методом всё устаканится — переделать
        this.tableFilter.size = data.page.size;
        this.tableFilter.totalElements = data.page.totalElements;
        this.tableFilter.totalPages = data.page.totalPages;
        this.loading = false;
      });
  }


  ngOnInit() {
    this.headerService.setCurrentHeader(headers[HeadersName.Menu]);
    this.menuService.setActiveTab(MenuNames.Directories);
    this.changeDirectory(this.directoriesService.getActiveDirectory())
  }

  // openCardDirectory(obj: any, activeDirectory: number) {
  //   switch (activeDirectory) {
  //     case 0:
  //       this.router.navigate(['/directories/countries/' + obj.id], {relativeTo: this.route}).then(r => r);
  //       break;
  //     case 1:
  //       this.router.navigate(['/directories/currencies/' + obj.id], {relativeTo: this.route}).then(r => r);
  //       break;
  //     case 2:
  //       this.router.navigate(['/directories/documents/' + obj.id], {relativeTo: this.route}).then(r => r);
  //       break;
  //     case 3:
  //       this.router.navigate(['/directories/objectives-type/' + obj.id], {relativeTo: this.route}).then(r => r);
  //       break;
  //     case 4:
  //       this.router.navigate(['/directories/measure/' + obj.id], {relativeTo: this.route}).then(r => r);
  //       break;
  //     case 5:
  //       this.router.navigate(['/directories/clients/' + obj.id], {relativeTo: this.route}).then(r => r);
  //       break;
  //     case 6:
  //       this.router.navigate(['/directories/objectives/' + obj.id], {relativeTo: this.route}).then(r => r);
  //       break;
  //     case 7:
  //       this.router.navigate(['/directories/tariffs/' + obj.id], {relativeTo: this.route}).then(r => r);
  //       break;
  //     case 8:
  //       this.router.navigate(['/directories/services-type/' + obj.id], {relativeTo: this.route}).then(r => r);
  //       break;
  //     case 9:
  //       this.router.navigate(['/directories/defects-type/' + obj.id], {relativeTo: this.route}).then(r => r);
  //       break;
  //     default:
  //       this.router.navigate(['/directories/countries/' + obj.id], {relativeTo: this.route}).then(r => r);
  //       break;
  //   }
  // }

  // createCardDirectories(activeDirectory: number) {
  //   switch (activeDirectory) {
  //     case 0:
  //       this.router.navigate(['/directories/countries/add'],{relativeTo: this.route}).then(r => r);
  //       break;
  //     case 1:
  //       this.router.navigate(['/directories/currencies/add'],{relativeTo: this.route}).then(r => r);
  //       break;
  //     case 2:
  //       this.router.navigate(['/directories/documents/add'], {relativeTo: this.route}).then(r => r);
  //       break;
  //     case 3:
  //       this.router.navigate(['/directories/objectives-type/add'], {relativeTo: this.route}).then(r => r);
  //       break;
  //     case 4:
  //       this.router.navigate(['/directories/measure/add'], {relativeTo: this.route}).then(r => r);
  //       break;
  //     case 5:
  //       this.router.navigate(['/directories/clients/add'], {relativeTo: this.route}).then(r => r);
  //       break;
  //     case 6:
  //       this.router.navigate(['/directories/objectives/add'], {relativeTo: this.route}).then(r => r);
  //       break;
  //     case 7:
  //       this.router.navigate(['/directories/tariffs/add'], {relativeTo: this.route}).then(r => r);
  //       break;
  //     case 8:
  //       this.router.navigate(['/directories/services-type/add'], {relativeTo: this.route}).then(r => r);
  //       break;
  //     case 9:
  //       this.router.navigate(['/directories/defects-type/add'], {relativeTo: this.route}).then(r => r);
  //       break;
  //     default:
  //       this.router.navigate(['/directories/countries/add'],{relativeTo: this.route}).then(r => r);
  //   }
  // }

  openCard(activeDirectory: number, obj?: any) {
    const add = obj ? ((obj.id != undefined) ? "/" + obj.id : null) : "/add";
    if (add != undefined) {
      switch (activeDirectory) {
        case 0:
          this.router.navigate(["/" + UrlPath.Country + add], {relativeTo: this.route}).then(r => r);
          break;
        case 1:
          this.router.navigate(["/" + UrlPath.Currency + add], {relativeTo: this.route}).then(r => r);
          break;
        case 2:
          this.router.navigate(["/" + UrlPath.Document + add], {relativeTo: this.route}).then(r => r);
          break;
        case 3:
          this.router.navigate(["/" + UrlPath.ObjectType + add], {relativeTo: this.route}).then(r => r);
          break;
        case 4:
          this.router.navigate(["/" + UrlPath.Measure + add], {relativeTo: this.route}).then(r => r);
          break;
        case 5:
          this.router.navigate(["/" + UrlPath.Client + add], {relativeTo: this.route}).then(r => r);
          break;
        case 6:
          this.router.navigate(["/" + UrlPath.Objective + add], {relativeTo: this.route}).then(r => r);
          break;
        case 7:
          this.router.navigate(["/" + UrlPath.Tariff + add], {relativeTo: this.route}).then(r => r);
          break;
        case 8:
          this.router.navigate(["/" + UrlPath.ServiceType + add], {relativeTo: this.route}).then(r => r);
          break;
        case 9:
          this.router.navigate(["/" + UrlPath.DefectType + add], {relativeTo: this.route}).then(r => r);
          break;
        default:
          this.router.navigate(["/" + UrlPath.Country + add], {relativeTo: this.route}).then(r => r);
          break;
      }
    } else {
      //TODO ОБРАБОТКА ВНЕШТАТНОЙ СИТУАЦИИ!!!!!!!
    }
  }

  updFilter(filter: TableFilter){
    this.tableFilter = filter;
    if (this.tableFilter.sortRefresh) {
      this.refreshSort++;
      this.tableFilter.sortRefresh = false;
    }
    const i = this.directoriesService.getActiveDirectory();
    this.setSaveStorage(i);
  }


  setSaveStorage(i: number) {
    switch (i) {
      case 0:
        this.saveStorage.setTableFilter(TemplatesNames.countries, this.tableFilter);
        break
      case 1:
        this.saveStorage.setTableFilter(TemplatesNames.currency, this.tableFilter);
        break
      case 2:
        this.saveStorage.setTableFilter(TemplatesNames.documents, this.tableFilter);
        break
      case 3:
        this.saveStorage.setTableFilter(TemplatesNames.objectivesTypes, this.tableFilter);
        break
      case 4:
        this.saveStorage.setTableFilter(TemplatesNames.measure, this.tableFilter);
        break
      case 5:
        this.saveStorage.setTableFilter(TemplatesNames.clients, this.tableFilter);
        break
      case 6:
        this.saveStorage.setTableFilter(TemplatesNames.objectives, this.tableFilter);
        break
      case 7:
        this.saveStorage.setTableFilter(TemplatesNames.tariffs, this.tableFilter);
        break
      case 8:
        this.saveStorage.setTableFilter(TemplatesNames.serviceType, this.tableFilter);
        break
      case 9:
        this.saveStorage.setTableFilter(TemplatesNames.defectsType, this.tableFilter);
        break
      default:
        this.saveStorage.setTableFilter(TemplatesNames.countries, this.tableFilter);
        break
    }
  }

  refresh(){
    this.refreshSort++;
    this.tableFilter = new TableFilter(defaultFilter);
    const i = this.directoriesService.getActiveDirectory();
    this.setSaveStorage(i);
  }

  protected readonly ChangeDetection = ChangeDetection;
  protected readonly Event = Event;
  protected readonly text = text;
}
