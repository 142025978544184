import {Option} from "./option";
import {ApiPath} from "./global";
import {SpecifyLabelType} from "../function/getSpecifyLabel";
import {ModalOptions} from "./modalOptions";
import {TableFilter, TableTemplate} from "./table";
import {DisableRules} from "./disableRules";
import {AutoSetRule, RelationRule} from "./relationRule";

export enum ErrorType {
  Default,
  Required,
  MinLength,
  MinDate,
  MaxDate,
}
export class ErrorRule {
  errorType: ErrorType = ErrorType.Default;
  errorText: string = "Ошибка";
  minLength? : number;
  beginDateKey?: string;
  endDateKey?: string;

  constructor(value?: ErrorRule) {
    if (value) {
      this.errorType = value?.errorType ?? ErrorType.Default;
      this.errorText = value?.errorText ?? "";
      this.minLength = value?.minLength;
      this.beginDateKey = value?.beginDateKey;
      this.endDateKey = value?.endDateKey;
    }
  }
}
export class FieldError {
  isError: boolean;
  errorRules?: ErrorRule[];
  constructor(value?: FieldError) {
      this.isError = value?.isError ?? false;
      this.errorRules = value?.errorRules ?? [];
  }
}
export class FormFiled {
  type?: FormTypeFiled;
  key: string = "";
  label?: string;
  options?: Option[];
  optionsKey?: string;
  sortOpt?: number | null = 0;
  method?: ApiPath = ApiPath.Default;
  labelKey?: string = "name";
  specifyLabelType?: SpecifyLabelType = SpecifyLabelType.Default;
  relationRules?: RelationRule[] = [];
  modalOptions?: ModalOptions | null = null;
  fieldError?: FieldError;
  specifyOutput?: boolean = false;
  menuId?: number;
  menuLabel?: string;
  tableTemplate?: TableTemplate = new TableTemplate();
  checkboxKey?: string;
  readonly?: boolean;
  disable?: boolean = false;
  disableRules?: DisableRules;
  filterKey?: string;
  filterCondition?: boolean;
  tableFilter?: TableFilter;
  radioSelection?: boolean;
  setActive?: boolean;
  search?: boolean;
  displayKey?: string;
  displayValue?: any;
  autoSetRules?: AutoSetRule[];

  constructor(value?: FormFiled) {
    if (value) {
      this.type = value?.type ?? FormTypeFiled.String;
      this.key = value?.key ?? "";
      this.label = value?.label ?? "";
      this.options = value?.options ?? [];
      this.optionsKey = value?.optionsKey;
      this.sortOpt = value?.sortOpt ?? 0;
      this.fieldError = value?.fieldError;
      this.method = value?.method;
      this.relationRules = value?.relationRules;
      this.modalOptions = value?.modalOptions;
      this.labelKey = value?.labelKey;
      this.specifyOutput = value?.specifyOutput;
      this.menuId = value?.menuId;
      this.menuLabel = value?.menuLabel;
      this.tableTemplate = value?.tableTemplate ?? new TableTemplate();
      this.checkboxKey = value?.checkboxKey;
      this.readonly = value?.readonly;
      this.disable = value?.disable;
      this.disableRules = value?.disableRules;
      this.filterKey = value?.filterKey;
      this.filterCondition = value?.filterCondition;
      this.tableFilter = value?.tableFilter;
      this.radioSelection = value?.radioSelection;
      this.setActive = value?.setActive;
      this.search = value?.search;
      this.displayKey = value?.displayKey;
      this.displayValue = value?.displayValue;
      this.autoSetRules = value?.autoSetRules;
    }
  }
}

export class FormGroup {
  type: FormTypeGroup;
  title: string;
  fields: FormFiled[];
  groupId?: number = 0;

  constructor(value: FormGroup) {
    this.type = value.type ?? FormTypeGroup.Default;
    this.title = value.title ?? null;
    this.fields = value.fields ?? [];
    this.groupId = value.groupId ?? 0;
  }
}

export class FormTemplate {
  id?: number;
  object: any;
  method: string;
  groups: FormGroup[];
  disable?: boolean;
  constructor(value?: FormTemplate ) {
      this.object = value?.object ?? null;
      this.method = value?.method ?? "";
      this.groups = value?.groups ?? [];
      this.disable = value?.disable ?? false;
  }
}

export enum FormTypeGroup {
  Header,
  Default,
  Wide,
  Double,
  Menu,
  Table
}

export enum FormTypeFiled {
  String,
  Date,
  Time,
  Number,
  List,
  Switch,
  Password,
  Directories,
  Files,
  DropDownList,
  Form,
  Info,
  SmartDropDownList,
  DropDownListMultiselect,
  MenuItem,
  Table,
  TextArea,
  Text,
  Autocomplete,
}


