<div class="autocomplete-container" appClickedOutsideDirective>
  <input #placesRef="ngx-places"
         [ngModel]="searchString"
         [options]="options"
         (onAddressChange)="handleAddressChange($event)"
         [placeholder]="text(['Введите адрес', 'Introduzca la dirección'])"
         ngx-gp-autocomplete
  />
</div>

