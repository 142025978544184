import {Option} from "./option";
import {text} from "../function/localization";

export enum Period {
  MONTH,
  WEEK
}

export const PeriodMap = new Map<string, string>([
  ["MONTH", text(["Месяц", "Mes"])],
  ["WEEK", text(["Неделя", "Semana"])],
]);


export const PeriodOptions: Option[] = [
  {
    id: 0,
    name: text(["Месяц", "Mes"]),
    code: "MONTH",
  },
  {
    id: 1,
    name: text(["Неделя", "Semana"]),
    code: "WEEK",
  },
];

export function getPeriodLabel(key: string){
  return PeriodMap.get(key);
}
