<div class="input-field-container" xmlns="http://www.w3.org/1999/html" xmlns="http://www.w3.org/1999/html">
  <div class="input-field" [class]="{'noPadding': noPadding }">
    <label>{{ field.label}}<span *ngIf="isRequired(field)" class="asterisk"> *</span></label>
    <div *ngIf="field.type == FormTypeFiled.String" class="input-field-inner">
      <input
        (focusout)="detectError()"
        type="text"
        [(ngModel)]="value"
        (ngModelChange)="saveValue.emit(value)"
        [disabled]="(field.disable ?? false) || disable"
      />
    </div>
    <div *ngIf="field.type == FormTypeFiled.Date" class="input-field-inner">
      <input
        (focusout)="detectError()"
        type="date"
        [(ngModel)]="value"
        (ngModelChange)="saveValue.emit(value)"
        [disabled]="(field.disable ?? false) || disable"
      />
    </div>
    <div *ngIf="field.type == FormTypeFiled.Time" class="input-field-inner">
      <input
        (focusout)="detectError()"
        type="datetime-local"
        [(ngModel)]="value"
        (ngModelChange)="saveValue.emit(value)"
        [disabled]="(field.disable ?? false) || disable"
      />
    </div>
    <div *ngIf="field.type == FormTypeFiled.List" class="input-field-inner">
      <app-option-switcher
        [options]="field.options ?? []"
        [radioSelection]="field.radioSelection ?? false"
        [setActive]="field.setActive ?? false"
        [value]="value"
        (emitChange)="saveValue.emit($event)"
        (emitSetActive)="setActive($event)"
      />
    </div>
    <div *ngIf="field.type == FormTypeFiled.Number" class="input-field-inner">

    </div>
    <div *ngIf="field.type == FormTypeFiled.Switch" class="input-field-inner">
      <app-switch
        [value]="value"
        (emitValue)="saveValue.emit($event)"
      />
    </div>
    <div *ngIf="field.type == FormTypeFiled.Password" class="input-field-inner">
      <input
        (focusout)="detectError()"
        type="password"
        [(ngModel)]="value"
        (ngModelChange)="saveValue.emit(value)"
      />
    </div>
    <div *ngIf="field.type == FormTypeFiled.DropDownList" class="input-field-inner">
      <app-drop-down-list
        [method]="field.method ?? ApiPath.Default"
        [labelKey]="field.labelKey ?? 'name'"
        [value]="value"
        [specifyLabelType]="field.specifyLabelType ?? SpecifyLabelType.Default"
        [optionList]="field.options ?? []"
        [specifyOutput]="field.specifyOutput ?? false"
        [disable]="field.disable"
        [tableFilter]="field.tableFilter ?? defaultFilter"
        [optionsKey]="field.optionsKey ?? ''"
        [search]="field.search ?? false"
        (change)="detectError()"
        (emitChange)="saveValue.emit($event);"
      />
<!--      (change)="detectError()"-->

<!--      <app-drop-down-list-->
<!--        [method]="field.method ?? ApiPath.Default"-->
<!--        [labelKey]="field.labelKey ?? 'name'"-->
<!--        [selectedItem]="value"-->
<!--        [specifyLabelType]="field.specifyLabelType ?? SpecifyLabelType.Default"-->
<!--        [optionList]="field.options ?? []"-->
<!--        (emitChange)="saveValue.emit($event)"-->
<!--      />-->
    </div>
    <div *ngIf="field.type == FormTypeFiled.Files" class="input-field-inner">
      <app-files-field
        [value]="value"
        [id]="objId ?? 0"
        [readonly]="field.readonly ?? false"
        [obj]="obj"
        (updObj)="updObj.emit($event)"
        (emitFiles)="emitFiles.emit($event)"
        (emitFilesToDel)="emitFilesToDel.emit($event)"
      />
    </div>
    <div *ngIf="field.type == FormTypeFiled.Info" class="input-field-inner">
      <input
        [value]="getFio(value)"
        disabled
      />
    </div>
    <div *ngIf="field.type == FormTypeFiled.Text" class="input-field-inner">
      <p>
        {{ value }}
      </p>
    </div>
    <div *ngIf="field.type == FormTypeFiled.SmartDropDownList" class="input-field-inner">
      <app-smart-drop-down-list
        [method]="field.method ?? ApiPath.Default"
        [labelKey]="field.labelKey ?? 'name'"
        [selectedItem]="value"
        [specifyLabelType]="field.specifyLabelType ?? SpecifyLabelType.Default"
        [relationRules]="field.relationRules"
        [modalOptions]="field.modalOptions"
        [search]="field.search ?? false"
        (emitChange)="saveValue.emit($event)"
      />
    </div>
    <div *ngIf="field.type == FormTypeFiled.DropDownListMultiselect" class="input-field-inner">
      <app-drop-down-list-multiselect
        [method]="field.method ?? ApiPath.Default"
        [labelKey]="field.labelKey ?? 'name'"
        [value]="value"
        [specifyLabelType]="field.specifyLabelType ?? SpecifyLabelType.Default"
        (change)="detectError()"
        (emitChange)="saveValue.emit($event)"
      />
    </div>
    <div
      *ngIf="field.type == FormTypeFiled.MenuItem"
      (click)="setActive(field.menuId)"
      [class]="{'input-field-menu-active': activeTabId == field.menuId}"
      class="input-field-menu"
    >
      {{ field.menuLabel }}
    </div>
    <div
      *ngIf="field.type == FormTypeFiled.Table" class="input-field-inner">
      <app-service-table
        [checkboxKey]="field.checkboxKey ?? null"
        [tableTemplate]="field.tableTemplate ?? tablesTemplates[0]"
        [value]="value"
        [activeTabId]="activeTabId"
        [modalOptions]="field.modalOptions"
        [disable]="(field.disable ?? false) || disable"
        [filterCondition]="field.filterCondition"
        [filterKey]="field.filterKey ?? ''"
        [isCreateNew]="field.modalOptions?.isCreateNew ?? false"
        (emitChange)="saveValue.emit($event)"
      />
    </div>
    <div *ngIf="field.type == FormTypeFiled.TextArea" class="input-field-inner-big">
      <textarea
        (focusout)="detectError()"
        type="text"
        [(ngModel)]="value"
        [disabled]="(field.disable ?? false) || disable"
        (ngModelChange)="saveValue.emit(value)"
      ></textarea>
    </div>
    <div *ngIf="field.type == FormTypeFiled.Autocomplete" class="input-field-inner">
      <app-autocomplete
        [value]="value"
        [autoSetRules]="field.autoSetRules ?? []"
        (emitAddress)="saveValue.emit($event)"
        (emitAutoSetRules)="emitAutoSetRules.emit($event)"
      />
    </div>
    <p *ngIf="field.fieldError?.isError" class="input-field-error">{{ errorText }}</p>
  </div>
</div>
