import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TableFilter} from "../../../model/table";
import {NgForOf, NgIf} from "@angular/common";
import {IconComponent} from "../icon/icon.component";
import {iconsByName} from "../../../model/icon";
import {text} from "../../../function/localization";

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [
    NgForOf,
    IconComponent,
    NgIf
  ],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss'
})
export class PaginationComponent implements OnInit{
  @Input() tableFilter: TableFilter = new TableFilter();
  @Output() tableFilterOut = new EventEmitter<TableFilter>();

  updEmit(){
    this.tableFilterOut.emit(this.tableFilter);
  }


  sizes: number[] = [10, 25, 50];

  ngOnInit() {
  }
  changeSize(size: number) {
    this.tableFilter.size = size;
    this.tableFilter.page = 0;
    this.updEmit();
  }

  changeActive(value: string){
    switch (value) {
      case "next": {
        if (this.tableFilter.page != this.tableFilter.totalPages - 1) {
          this.tableFilter.page = this.tableFilter.page + 1;
        }
        break;
      }
      case "prev": {
        if (this.tableFilter.page != 0) {
          this.tableFilter.page = this.tableFilter.page - 1;
        }
        break;
      }
      case "start": {
        break;
      }
      case "end": {
        break;
      }
      case "...": {
        break;
      }
      default: {
        const i = Number(value);
        if (i) {
          this.tableFilter.page = i - 1;
        }
        break;
      }
    }
    this.updEmit();
  }



  getTotalPagesAsArray() {
    let pages: string[] = [];
    const range: number = 12;
    for (let i = 0; i < this.tableFilter.totalPages; i++) {
      if ( (i == 0) ||
        (
          (
            (i < this.tableFilter.page + range / 2 - 1 ) &&
            (i > this.tableFilter.page - range / 2)
          ) ||
          (this.tableFilter.page < range / 2 && i < range) ||
          (this.tableFilter.page > this.tableFilter.totalPages - range / 2  && i > this.tableFilter.totalPages - range -1 )
        ) ||
        (i == this.tableFilter.totalPages - 1)
      ) {
        pages.push((i + 1).toString());
      } else if (
        (i == this.tableFilter.page - Math.round( range / 2)) ||
        (i == this.tableFilter.page + Math.round( range / 2)) ||
        ((i == this.tableFilter.page + range) && (this.tableFilter.page < Math.round(range / 2) - 1)) ||
        ((i == this.tableFilter.totalPages - this.tableFilter.page) &&
          (this.tableFilter.page > this.tableFilter.totalPages - Math.round(range / 2) - 1))
      ) {
        pages.push("...");
      }
    }
    return pages;
  }

  setChangeSize(){
    let newSizes: number[] = [];
    let end: boolean = false;
    this.sizes.forEach(value => {
      if ((value < this.tableFilter.totalElements) && !end) {
        newSizes.push(value);
      } else {
        if (!end ) {
          newSizes.push(value);
          end = true;
        }
      }
    });
    return newSizes;
  }


  protected readonly Number = Number;
  protected readonly iconsByName = iconsByName;
    protected readonly text = text;
}
