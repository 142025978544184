import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {ApiPath} from "../../../model/global";
import {LoadingComponent} from "../loading/loading.component";
import {NgIf} from "@angular/common";
import {ModalService} from "../../../services/modal.service";
import {ModalOptions, ModalType} from "../../../model/modalOptions";

@Component({
  selector: 'app-photo',
  standalone: true,
  imports: [
    LoadingComponent,
    NgIf
  ],
  templateUrl: './photo.component.html',
  styleUrl: './photo.component.scss'
})
export class PhotoComponent implements OnInit{
  @Input() id: number | null = null;
  constructor(protected apiService: ApiService, protected modalService: ModalService,) {}
  imageToShow: any;
  loading: boolean = false;
  modalOptions: ModalOptions = new ModalOptions();

  ngOnInit() {
    this.loading = true;
    this.apiService.getImg(ApiPath.DefectFile, Number(this.id)).subscribe((baseImage) => {
      this.createImageFromBlob(baseImage);
      this.loading = false;
    });
  }

  fullScreen() {
    this.modalOptions.key = "photo-" + this.id;
    this.modalOptions.type = ModalType.ImagesFullScreen
    this.modalService.setModalActive(this.modalOptions.key);
    this.modalService.setModal(this.imageToShow, this.modalOptions);
  }


  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.imageToShow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
