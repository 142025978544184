import {APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {authInterceptor} from "./interceptors/auth.interceptor";
import {provideAnimations} from "@angular/platform-browser/animations";
import {UsersService} from "./services/users.service";
import {LocalizationService, startupServiceFactory} from "./services/localization.service";
import {Loader} from "@googlemaps/js-api-loader";

export let appConfig: ApplicationConfig;
appConfig = {
  providers: [
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes),
    provideHttpClient(),
    provideAnimations(),
    provideHttpClient(withInterceptors([authInterceptor])),
    UsersService,
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyBA-ykb7iPp626suCycRTakfq2nIUGtfyI',
        libraries: ['places'],
        region: 'ES'
        // language: 'Ru',
      })
    },
    { provide: APP_INITIALIZER, useFactory: startupServiceFactory, deps: [LocalizationService], multi: true }]
};
