import {ColumnType, Sort, TableFilter, TableTemplate} from "./table";
import {getOptionsListFromEnum} from "./option";
import {roleLabelMap, UsersRole} from "./user";
import {ApiPath} from "./global";
import {ErrorType, FormTemplate, FormTypeFiled, FormTypeGroup} from "./form";
import {SpecifyLabelType} from "../function/getSpecifyLabel";
import {ModalOptions, ModalType} from "./modalOptions";
import {TuskStatusesOptions} from "./task";
import {PeriodOptions} from "./period";
import {AdditionalServices} from "./additionalServices";
import {FilteringPosition, FilteringType} from "./filtering";
import {ClientStatus, clientStatusMap} from "./client";
import {text} from "../function/localization";

export enum TemplatesNames {
  users,
  countries,
  currency,
  documents,
  objectives,
  clients,
  tariffs,
  measure,
  contracts,
  objectivesTypes,
  serviceType,
  defectsType,
  taskRegistry,
  servicesAndTariffs,
  identifiedDefects,
  contractsAdditionalServices,
  delete,
  taskDate,
}


export let tablesTemplates: TableTemplate[];
tablesTemplates = [
  {
    id: 0,
    name: text(["Пользователи", "Usuarios"]),
    columns: [
      {
        label: text(["Логин", "Inicio de sesión"]),
        key: "login",
        sort: Sort.None,
        columnType: ColumnType.Default,
      },
      {
        label: text(["Фамилия", "Apellido"]),
        key: "surname",
        sort: Sort.None,
        columnType: ColumnType.Default,
      },
      {
        label: text(["Имя", "Nombre"]),
        key: "name",
        sort: Sort.None,
        columnType: ColumnType.Default,
      },
      {
        label: text(["Отчество", "Segundo nombre"]),
        key: "patronymic",
        sort: Sort.None,
        columnType: ColumnType.Default,
      },
      {
        label: text(["Дата рождения", "Fecha de nacimiento"]),
        key: "birthDate",
        align: "center",
        sort: Sort.None,
        columnType: ColumnType.Date,
      },
      {
        label: text(["Роли", "Roles"]),
        key: "roles",
        sort: Sort.None,
        columnType: ColumnType.Role,
      },
      {
        label: text(["Телефон", "Teléfono"]),
        key: "phone",
        sort: Sort.None,
        columnType: ColumnType.Default,
      },
      {
        label: text(["Email", "Correo electrónico"]),
        key: "email",
        sort: Sort.None,
        columnType: ColumnType.Default,
      },
      {
        label: text(["Страна", "País"]),
        key: "country.name",
        sort: Sort.None,
        columnType: ColumnType.Default,
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 1,
    name: "",
    columns: [
      {
        label: text(["Название страны", "Nombre del país"]),
        key: "name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Налоговая ставка, %", "Tasa de impuesto, %"]),
        key: "taxRate",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 2,
    name: "",
    columns: [
      {
        label: text(["Название валюты", "Nombre de la moneda"]),
        key: "name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Код валюты", "Código de la moneda"]),
        key: "code",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 3,
    name: "",
    columns: [
      {
        label: text(["Название типа документа", "Nombre del tipo de documento"]),
        key: "name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 4,
    name: "",
    columns: [
      {
        label: text(["Тип объекта", "Tipo de objeto"]),
        key: "objectiveType.name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Адрес объекта", "Dirección del objeto"]),
        key: "address",
        sort: Sort.None,
        columnType: ColumnType.Address,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      // {
      //   label: text([
      //     "Идентификатор объекта в адресном справочнике",
      //     "Identificador del objeto en el directorio de direcciones"
      //   ]),
      //   key: "addressId",
      //   sort: Sort.None,
      //   columnType: ColumnType.Default,
      //   filtering: {
      //     sorting: true,
      //     filtering: false,
      //     filteringType: FilteringType.DropDownListMultiselect,
      //     method: ApiPath.Contract,
      //     title: text(["Договор", "Contrato"]),
      //     labelKey: "number",
      //     position: FilteringPosition.left,
      //     wide: true,
      //     paramsKey: "contractIds",
      //     objKey: "id",
      //     tableFilter: new TableFilter({
      //       size: 10,
      //       query: "",
      //       sort: "number:ASC",
      //       page: 0,
      //       totalPages: 0,
      //       totalElements: 0,
      //     }),
      //   }
      // },
      {
        label: text(["Широта", "Latitud"]),
        key: "latitude",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Долгота", "Longitud"]),
        key: "longitude",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Клиент", "Cliente"]),
        key: "client.user.login",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Номер квартиры", "Número de apartamento"]),
        key: "flatNumber",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Примечание", "Nota"]),
        key: "description",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 5,
    name: "",
    columns: [
      {
        label: text(["Пользователь", "Usuario"]),
        key: "user.login",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Тип документа", "Tipo de documento"]),
        key: "documentType.name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Номер документа", "Número de documento"]),
        key: "documentNumber",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Эккаунт Telegram", "Cuenta de Telegram"]),
        key: "telegramAccount",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Эккаунт WhatsApp", "Cuenta de WhatsApp"]),
        key: "whatsappAccount",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Эккаунт Viber", "Cuenta de Viber"]),
        key: "viberAccount",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 6,
    name: "",
    columns: [
      {
        label: text(["Название тарифа", "Nombre de la tarifa"]),
        key: "name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Единица измерения", "Unidad de medida"]),
        key: "measure.name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Базовая цена", "Precio base"]),
        key: "basePrice",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text([
          "Период, в рамках которого повторяются услуги тарифа",
          "Período de repetición de servicios"
        ]),
        key: "period",
        sort: Sort.None,
        columnType: ColumnType.Period,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text([
          "Количество повторений в рамках периода",
          "Número de repeticiones en el período"
        ]),
        key: "repetitionsNumber",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Описание тарифа", "Descripción de la tarifa"]),
        key: "description",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Типы услуг", "Tipos de servicios"]),
        key: "serviceTypes",
        labelKey: "name",
        sort: Sort.None,
        columnType: ColumnType.ServiceTypes,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 7,
    name: "",
    columns: [
      {
        label: text(["Название единицы измерения", "Nombre de la unidad de medida"]),
        key: "name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 8,
    name: text(["Договоры", "Contratos"]),
    columns: [
      {
        label: text(["Номер договора", "Número de contrato"]),
        key: "number",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:DESC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["ФИО клиента", "Nombre completo del cliente"]),
        key: "objective.client.user",
        sort: Sort.None,
        columnType: ColumnType.Fio,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Телефон клиента", "Teléfono del cliente"]),
        key: "objective.client.user.phone",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Дата начала действия", "Fecha de inicio"]),
        key: "beginDate",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Date,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Дата окончания действия", "Fecha de finalización"]),
        key: "endDate",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Date,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Адрес объекта", "Dirección del objeto"]),
        key: "objective",
        sort: Sort.None,
        columnType: ColumnType.Address,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Валюта", "Moneda"]),
        key: "currency.name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Налоговая ставка, %", "Tasa de impuesto, %"]),
        key: "taxRate",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Тариф", "Tarifa"]),
        key: "tariff.name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 9,
    name: text(["Типы объектов", "Tipos de objetos"]),
    columns: [
      {
        label: text(["Название типа объекта", "Nombre del tipo de objeto"]),
        key: "name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      }
    ],
    content: [],
    disable: false,
  },
  {
    id: 10,
    name: "",
    columns: [
      {
        label: text(["Название типа услуги", "Nombre del tipo de servicio"]),
        key: "name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Описание типа услуг", "Descripción del tipo de servicio"]),
        key: "description",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Информация о единице измерения", "Información sobre la unidad de medida"]),
        key: "measure.name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 11,
    name: "",
    columns: [
      {
        label: text(["Название типа дефекта", "Nombre del tipo de defecto"]),
        key: "name",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: false,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 12,
    name: "",
    columns: [
      {
        label: text(["Информация о договоре", "Información del contrato"]),
        key: "contract.number",
        sort: Sort.None,
        columnType: ColumnType.Default,
        filtering: {
          sorting: true,
          filtering: true,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Contract,
          title: text(["Договор", "Contrato"]),
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "contractIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Статус", "Estado de la tarea"]),
        key: "status",
        sort: Sort.None,
        columnType: ColumnType.Status,
        filtering: {
          title: text(["Статус", "Estatus"]),
          sorting: true,
          filtering: true,
          filteringType: FilteringType.DropDownListMultiselect,
          options: TuskStatusesOptions,
          specifyLabelType: SpecifyLabelType.EnumOption,
          labelKey: "number",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "statuses",
          objKey: "code",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "number:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Дата, с которой надо выполнить задание", "Fecha de inicio de la tarea"]),
        key: "beginDate",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Date,
        filtering: {
          sorting: true,
          filtering: true,
          filteringType: FilteringType.Date,
          dateStartKey: "beginDateFrom",
          dateEndKey: "beginDateTo",
        }
      },
      {
        label: text(["Дата, до которой надо выполнить задание", "Fecha límite para completar la tarea"]),
        key: "endDate",
        sort: Sort.None,
        columnType: ColumnType.Date,
        align: "center",
        filtering: {
          sorting: true,
          filtering: true,
          filteringType: FilteringType.Date,
          dateStartKey: "endDateFrom",
          dateEndKey: "endDateTo",
        }
      },
      {
        label: text(["Плановая дата выполнения задания", "Fecha planificada para la tarea"]),
        key: "planDate",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Date,
        filtering: {
          sorting: true,
          filteringType: FilteringType.Default,
        }
      },
      {
        label: text(["Инженер", "Ingeniero"]),
        key: "engineer",
        sort: Sort.None,
        columnType: ColumnType.Fio,
        filtering: {
          title: text(["Инженер", "Ingeniero"]),
          sorting: true,
          filtering: true,
          filteringType: FilteringType.DropDownListMultiselect,
          specifyLabelType: SpecifyLabelType.ShorFio,
          method: ApiPath.User,
          labelKey: "",
          position: FilteringPosition.left,
          wide: true,
          paramsKey: "engineerIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "surname:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
            role: "ROLE_ENGINEER",
          }),
        }
      },
      // {
      //   label: text(["Описание задания", "Descripción de la tarea"]),
      //   key: "description",
      //   sort: Sort.None,
      //   columnType: ColumnType.Default,
      //   filtering: {
      //     sorting: true,
      //     filteringType: FilteringType.Default,
      //   }
      // },
      {
        label: text([
          "Дата и время начала выполнения задания инженером",
          "Fecha y hora de inicio de la tarea por el ingeniero"
        ]),
        key: "startTime",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Date,
        filtering: {
          filteringType: FilteringType.Date,
          title: "Дата с:",
          sorting: true,
        }
      },
      {
        label: text([
          "Дата и время окончания выполнения задания инженером",
          "Fecha y hora de finalización de la tarea por el ingeniero"
        ]),
        key: "endTime",
        sort: Sort.None,
        columnType: ColumnType.Date,
        align: "center",
        filtering: {
          sorting: true,
          filteringType: FilteringType.Date,
        }
      },
      {
        label: text(["Объект", "Objeto"]),
        key: "contract.objective",
        sort: Sort.None,
        columnType: ColumnType.Address,
        filtering: {
          sorting: true,
          filtering: true,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Objective,
          title: text(["Объект", "Objeto"]),
          labelKey: "address",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "objectiveIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "address:ASC",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
      {
        label: text(["Клиент", "Cliente"]),
        key: "contract.objective.client.user",
        sort: Sort.None,
        columnType: ColumnType.Fio,
        filtering: {
          sorting: true,
          filtering: true,
          filteringType: FilteringType.DropDownListMultiselect,
          method: ApiPath.Client,
          specifyLabelType: SpecifyLabelType.ShorFio,
          title: text(["Клиент", "Cliente"]),
          labelKey: "user",
          position: FilteringPosition.right,
          wide: true,
          paramsKey: "clientIds",
          objKey: "id",
          tableFilter: new TableFilter({
            size: 10,
            query: "",
            sort: "",
            page: 0,
            totalPages: 0,
            totalElements: 0,
          }),
        }
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 13,
    name: text(["Услуги тарифа", "Servicios de tarifa"]),
    columns: [
      {
        label: text(["Наименование услуги", "Nombre del servicio"]),
        key: "serviceType.name",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Default,
      },
      {
        label: text(["Статус выполнения", "Estado de ejecución"]),
        key: "done",
        sort: Sort.None,
        width: "50px",
        align: "center",
        columnType: ColumnType.Checkbox,
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 14,
    name: text(["Дефекты", "Defectos"]),
    columns: [
      {
        label: text(["Тип дефекта", "Tipo de defecto"]),
        key: "defectType.name",
        sort: Sort.None,
        columnType: ColumnType.Default,
      },
      {
        label: text(["Описание дефекта", "Descripción del defecto"]),
        key: "description",
        sort: Sort.None,
        columnType: ColumnType.Default,
      },
      {
        label: text(["Статус выполнения", "Estado de ejecución"]),
        key: "eliminated",
        sort: Sort.None,
        width: "50px",
        align: "center",
        columnType: ColumnType.Checkbox,
      },
    ],
    content: [],
    disable: false,
  },
  {
    id: 15,
    name: text(["Дополнительные услуги", "Servicios adicionales"]),
    columns: [
      {
        label: text(["Тип услуги", "Tipo de servicio"]),
        key: "isPeriodical",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.isPeriodical,
      },
      {
        label: text(["Наименование услуги", "Nombre del servicio"]),
        key: "serviceType.name",
        sort: Sort.None,
        columnType: ColumnType.Default,
      },
      {
        label: text(["Дата, с которой надо выполнить услугу", "Fecha de inicio del servicio"]),
        key: "beginDate",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Date,
      },
      {
        label: text(["Дата, до которой надо выполнить услугу", "Fecha límite del servicio"]),
        key: "endDate",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Date,
      },
      {
        label: text(["Тип стоимости", "Tipo de costo"]),
        key: "isFixedPrice",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.IsFixedPrice,
      },
      {
        label: text(["Количество единиц услуги", "Cantidad de unidades de servicio"]),
        key: "serviceUnitAmount",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Default,
      },
      {
        label: text(["Цена за единицу", "Precio por unidad"]),
        key: "unitPrice",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Default,
      },
      {
        label: text(["Период, в рамках которого повторяется услуга", "Período de repetición del servicio"]),
        key: "period",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Period,
      },
      {
        label: text(["Количество повторений в рамках периода", "Número de repeticiones en el período"]),
        key: "repetitionsNumber",
        sort: Sort.None,
        align: "center",
        columnType: ColumnType.Default,
      },
      {
        label: "",
        key: "delete",
        sort: Sort.None,
        width: "2rem",
        align: "center",
        columnType: ColumnType.Actions
      }
    ],
    content: [],
    disable: false,
  },
  {
    id: 16,
    name: "",
    columns: [

    ],
    content: [],
    disable: false,
  },
  {
    id: 17,
    name: "",
    columns: [

    ],
    content: [],
    disable: false,
  },
];

export const formTemplates: FormTemplate[] = [
  {
    id: 0,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Header,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "login",
            label: text(["Логин", "Inicio de sesión"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
        ]
      },
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "surname",
            label: text(["Фамилия", "Apellido"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "name",
            label: text(["Имя", "Nombre"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "patronymic",
            label: text(["Отчество", "Segundo nombre"]),
          },
          {
            type: FormTypeFiled.Date,
            key: "birthDate",
            label: text(["Дата рождения", "Fecha de nacimiento"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "phone",
            label: text(["Телефон", "Teléfono"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "email",
            label: text(["Email", "Correo electrónico"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.Password,
            key: "password",
            label: text(["Пароль", "Contraseña"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.Password,
            key: "password",
            label: text(["Подтверждение пароля", "Confirmación de contraseña"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
        ]
      },
      {
        type: FormTypeGroup.Wide,
        title: "",
        fields: [
          {
            type: FormTypeFiled.List,
            key: "roles",
            label: text(["Роли", "Roles"]),
            options: getOptionsListFromEnum(UsersRole, roleLabelMap),
          },
        ]
      }
    ]
  },
  {
    id: 1,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "name",
            label: text(["Наименование", "Denominación"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "taxRate",
            label: text(["Налоговая ставка, %", "Tasa de impuesto, %"]),
          },
        ]
      },

    ]
  },
  {
    id: 2,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "name",
            label: text(["Название валюты", "Nombre de la moneda"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "code",
            label: text(["Код валюты", "Código de la moneda"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
        ]
      },
    ]
  },
  {
    id: 3,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "name",
            label: text(["Название типа документа", "Nombre del tipo de documento"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
        ]
      },
    ]
  },
  {
    id: 4,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.DropDownList,
            key: "objectiveType",
            label: text(["Тип объекта", "Tipo de objeto"]),
            method: ApiPath.ObjectType,
            labelKey: "name",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
        ]
      },
      {
        type: FormTypeGroup.Wide,
        title: "",
        fields: [
          {
            type: FormTypeFiled.Autocomplete,
            key: "address",
            label: text(["Адрес", "Dirección"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            },
            autoSetRules: [
              {
                determinantKey: "latitude",
              },
              {
                determinantKey: "longitude",
              },
            ]
          },
        ]
      },
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "flatNumber",
            label: text(["Номер квартиры / апартаментов", "Número de apartamento"]),
            fieldError: {
              isError: false,
              errorRules: [],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "latitude",
            label: text(["Широта", "Latitud"]),
          },
          {
            type: FormTypeFiled.String,
            key: "longitude",
            label: text(["Долгота", "Longitud"]),
          },
        ]
      },
      {
        type: FormTypeGroup.Wide,
        title: "",
        fields: [
          {
            type: FormTypeFiled.TextArea,
            key: "description",
            label: text(["Примечание", "Nota"]),
            fieldError: {
              isError: false,
              errorRules: [],
            }
          },
        ]
      },
      {
        type: FormTypeGroup.Wide,
        title: "",
        fields: [
          {
            type: FormTypeFiled.List,
            key: "client",
            label: text(["Статус клиента", "Estado del cliente"]),
            radioSelection: true,
            setActive: true,
            options: getOptionsListFromEnum(ClientStatus, clientStatusMap),
          },
        ]
      },
      {
        type: FormTypeGroup.Wide,
        title: "",
        groupId: 0,
        fields: [
          {
            type: FormTypeFiled.DropDownList,
            key: "client",
            label: text(["Статус клиента", "Estado del cliente"]),
            method: ApiPath.Client,
            labelKey: "user",
            specifyLabelType: SpecifyLabelType.ShorFio,
            search: true,
            tableFilter: new TableFilter({
              size: 10,
              query: "",
              sort: "",
              page: 0,
              totalPages: 0,
              totalElements: 0,
            }),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
        ]
      },
      {
        type: FormTypeGroup.Default,
        title: "",
        groupId: 1,
        fields: [
          {
            type: FormTypeFiled.String,
            key: "client.user.surname",
            label: text(["Фамилия", "Apellido"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "client.user.name",
            label: text(["Имя", "Nombre"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "client.user.patronymic",
            label: text(["Отчество", "Segundo nombre"]),
          },
          {
            type: FormTypeFiled.Date,
            key: "client.user.birthDate",
            label: text(["Дата рождения", "Fecha de nacimiento"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "client.user.phone",
            label: text(["Телефон", "Teléfono"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "client.user.email",
            label: text(["Email", "Correo electrónico"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.DropDownList,
            key: "client.user.country",
            label: text(["Страна", "País"]),
            method: ApiPath.Country,
            search: true,
            tableFilter: new TableFilter({
              size: 10,
              query: "",
              sort: "",
              page: 0,
              totalPages: 0,
              totalElements: 0,
            }),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.Password,
            key: "client.user.password",
            label: text(["Пароль", "Contraseña"]),
          },
          {
            type: FormTypeFiled.Password,
            key: "client.user.password",
            label: text(["Подтверждение пароля", "Confirmación de contraseña"]),
          },
        ]
      },
      {
        type: FormTypeGroup.Default,
        title: "",
        groupId: 1,
        fields: [
          {
            type: FormTypeFiled.DropDownList,
            key: "client.documentType",
            label: text(["Тип документа", "Tipo de documento"]),
            method: ApiPath.Document,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "client.documentNumber",
            label: text(["Номер документа", "Número de documento"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "client.telegramAccount",
            label: text(["Эккаунт Telegram", "Cuenta de Telegram"]),
          },
          {
            type: FormTypeFiled.String,
            key: "client.whatsappAccount",
            label: text(["Эккаунт WhatsApp", "Cuenta de WhatsApp"]),
          },
          {
            type: FormTypeFiled.String,
            key: "client.ViberAccount",
            label: text(["Эккаунт Viber", "Cuenta de Viber"]),
          },
        ]
      },
    ]
  },
  {
    id: 5,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.DropDownList,
            key: "user",
            label: text(["Пользователь", "Usuario"]),
            method: ApiPath.User,
            labelKey: "login",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.DropDownList,
            key: "documentType",
            label: text(["Тип документа", "Tipo de documento"]),
            method: ApiPath.Document,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "documentNumber",
            label: text(["Номер документа", "Número de documento"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "telegramAccount",
            label: "Telegram",
          },
          {
            type: FormTypeFiled.String,
            key: "whatsappAccount",
            label: "Whatsapp",
          },
          {
            type: FormTypeFiled.String,
            key: "ViberAccount",
            label: "Viber",
          },
        ]
      },
    ]
  },
  {
    id: 6,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "name",
            label: text(["Название тарифа", "Nombre de la tarifa"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.DropDownList,
            key: "measure",
            label: text(["Единица измерения", "Unidad de medida"]),
            method: ApiPath.Measure,
          },
          {
            type: FormTypeFiled.String,
            key: "basePrice",
            label: text(["Базовая цена", "Precio base"]),
          },
          {
            type: FormTypeFiled.DropDownList,
            key: "period",
            label: text(["Период, в рамках которого повторяются услуги тарифа", "Período de repetición de servicios"]),
            options: PeriodOptions,
            specifyLabelType: SpecifyLabelType.EnumOption,
            specifyOutput: true,
            optionsKey: "code",
          },
          {
            type: FormTypeFiled.String,
            key: "repetitionsNumber",
            label: text(["Количество повторений в рамках периода", "Número de repeticiones en el período"]),
          },
          {
            type: FormTypeFiled.String,
            key: "description",
            label: text(["Описание тарифа", "Descripción de la tarifa"]),
          },
          {
            type: FormTypeFiled.DropDownListMultiselect,
            key: "serviceTypes",
            label: text(["Типы услуг", "Tipos de servicios"]),
            method: ApiPath.ServiceType,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
        ]
      },
    ]
  },
  {
    id: 7,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "name",
            label: text(["Название единицы измерения", "Nombre de la unidad de medida"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
        ]
      },
    ]
  },
  {
    id: 8,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Header,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "number",
            label: text(["Номер договора", "Número de contrato"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "sum1",
            label: text(["Сумма до налогов", "Importe antes de impuestos"]),
          },
          {
            type: FormTypeFiled.String,
            key: "sum2",
            label: text(["Сумма после налогов", "Importe después de impuestos"]),
          },
        ]
      },
      {
        type: FormTypeGroup.Menu,
        title: "",
        fields: [
          {
            type: FormTypeFiled.MenuItem,
            key: "",
            menuLabel: text(["Основная информация", "Información principal"]),
            menuId: 0
          },
          {
            type: FormTypeFiled.MenuItem,
            key: "",
            menuLabel: text(["Дополнительные услуги", "Servicios adicionales"]),
            menuId: 1
          },
          {
            type: FormTypeFiled.MenuItem,
            key: "",
            menuLabel: text(["Журнал осмотров", "Registro de inspecciones"]),
            menuId: 2
          },
        ]
      },
      {
        type: FormTypeGroup.Default,
        title: "",
        groupId: 0,
        fields: [
          {
            type: FormTypeFiled.SmartDropDownList,
            key: "objective",
            label: text(["Объект(клиент)", "Objeto (cliente)"]),
            method: ApiPath.Objective,
            labelKey: "",
            specifyLabelType: SpecifyLabelType.AddressClient,
            search: true,
            modalOptions: new ModalOptions({
              key: "objective",
              type: ModalType.Form,
              method: ApiPath.Objective,
              title: "Редактирование объекта",
              titleAdd: "Создание объекта",
              formTemplate: new FormTemplate(),
              templatesNames: TemplatesNames.objectives
            }),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.Date,
            key: "beginDate",
            label: text(["Дата начала действия", "Fecha de inicio"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
                {
                  errorType: ErrorType.MaxDate,
                  errorText: text([
                    "Дата начала не может быть позже даты окончания",
                    "La fecha de inicio no puede ser posterior a la fecha de finalización"
                  ]),
                  endDateKey: "endDate",
                }
              ],
            }
          },
          {
            type: FormTypeFiled.Date,
            key: "endDate",
            label: text(["Дата окончания действия", "Fecha de finalización"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
                {
                  errorType: ErrorType.MinDate,
                  errorText: text([
                    "Дата окончания не может быть раньше даты начала",
                    "La fecha de finalización no puede ser anterior a la fecha de inicio"
                  ]),
                  beginDateKey: "beginDate",
                }
              ],
            }
          },
          {
            type: FormTypeFiled.DropDownList,
            key: "tariff",
            label: text(["Тариф", "Tarifa"]),
            method: ApiPath.Tariff,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.DropDownList,
            key: "currency",
            label: text(["Валюта", "Moneda"]),
            labelKey: "name",
            method: ApiPath.Currency,
            fieldError: {
              isError: false,
              errorRules: [
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "taxRate",
            label: text(["Налоговая ставка, %", "Tasa de impuesto, %"]),
            fieldError: {
              isError: false,
              errorRules: [
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "tariffMeasuresAmount",
            label: text(["Количество единиц", "Número de unidades"]),
            fieldError: {
              isError: false,
              errorRules: [
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "measurePrice",
            label: text(["Цена за единицу", "Precio por unidad"]),
            fieldError: {
              isError: false,
              errorRules: [
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "nfc",
            label: "NFC",

          },
        ]
      },
      {
        type: FormTypeGroup.Wide,
        title: "",
        groupId: 0,
        fields: [
          {
            type: FormTypeFiled.Switch,
            key: "terminated",
            label: text(["Договор расторгнут", "Contrato cancelado"]),
            fieldError: {
              isError: false,
              errorRules: [
              ],
            }
          },
          {
            type: FormTypeFiled.Files,
            key: "files",
            label: text(["Файлы договора", "Archivos del contrato"]),
          },
        ]
      },
      {
        type: FormTypeGroup.Table,
        title: "",
        groupId: 1,
        fields: [
          {
            type: FormTypeFiled.Table,
            key: "additionalServices",
            label: "",
            checkboxKey: "done",
            modalOptions: new ModalOptions({
              key: "additionalServices",
              type: ModalType.Form,
              title: "Редактирование услуги",
              titleAdd: "Создание услуги",
              isCreateNew: true,
              obj: new AdditionalServices(),
              formTemplate: new FormTemplate(),
              templatesNames: TemplatesNames.contractsAdditionalServices
            }),
            tableTemplate: tablesTemplates[TemplatesNames.contractsAdditionalServices]
          },
        ]
      },
    ],
  },
  {
    id: 9,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "name",
            label: text(["Название типа объекта", "Nombre del tipo de objeto"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
        ]
      },
    ]
  },
  {
    id: 10,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "name",
            label: text(["Название типа услуги", "Nombre del tipo de servicio"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "description",
            label: text(["Описание типа услуги", "Descripción del tipo de servicio"]),
          },
          {
            type: FormTypeFiled.DropDownList,
            key: "measure",
            label: text(["Единица измерения", "Unidad de medida"]),
            method: ApiPath.Measure,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
        ]
      },
    ]
  },
  {
    id: 11,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.String,
            key: "name",
            label: text(["Обязательное поле", "Nombre del tipo de defecto"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
        ]
      },
    ]
  },
  {
    id: 12,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Menu,
        title: "",
        fields: [
          {
            type: FormTypeFiled.MenuItem,
            key: "",
            menuLabel: text(["Основная информация", "Información principal"]),
            menuId: 0
          },
          {
            type: FormTypeFiled.MenuItem,
            key: "",
            menuLabel: text(["Услуги и рекомендации", "Servicios y recomendaciones"]),
            menuId: 1
          },
          {
            type: FormTypeFiled.MenuItem,
            key: "",
            menuLabel: text(["Выявленные дефекты", "Defectos detectados"]),
            menuId: 2
          },
        ]
      },
      {
        type: FormTypeGroup.Default,
        title: "",
        groupId: 0,
        fields: [
          {
            type: FormTypeFiled.DropDownList,
            key: "contract",
            label: text(["Договор", "Contrato"]),
            method: ApiPath.Contract,
            labelKey: "number",
            disable: true,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.DropDownList,
            key: "status",
            label: text(["Статус задания", "Campo Estado de la tarea"]),
            options: TuskStatusesOptions,
            optionsKey: "code",
            specifyLabelType: SpecifyLabelType.EnumOption,
            specifyOutput: true,
            disable: false,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.DropDownList,
            key: "engineer",
            label: text(["Инженер", "Ingeniero"]),
            method: ApiPath.User,
            specifyLabelType: SpecifyLabelType.Fio,
            labelKey: "",
            tableFilter: new TableFilter({
              size: 100,
              query: "",
              sort: "",
              page: 0,
              totalPages: 0,
              totalElements: 0,
              role: "ROLE_ENGINEER",
            }),
          },
          {
            type: FormTypeFiled.Date,
            key: "beginDate",
            label: text(["Дата, с которой надо выполнить задание", "Fecha de inicio de la tarea"]),
            disable: true,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.Date,
            key: "endDate",
            label: text(["Дата, до которой надо выполнить задание", "Fecha límite para completar la tarea"]),
            disable: true,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.Date,
            key: "planDate",
            label: text(["Плановая дата выполнения задания", "Fecha planificada para la tarea"]),
          },
          {
            type: FormTypeFiled.String,
            key: "nfc",
            label: text(["NFC-метка после выполнения задания", "Etiqueta NFC tras la finalización de la tarea"]),
            disable: true,
          },
          // {
          //   type: FormTypeFiled.String,
          //   key: "description",
          //   label: text(["Описание задания", "Descripción de la tarea"]),
          //   disable: true,
          // },
          {
            type: FormTypeFiled.Time,
            key: "startTime",
            label: text([
              "Дата и время начала выполнения задания инженером",
              "Fecha y hora de inicio de la tarea por el ingeniero"
            ]),
            disable: true,
          },
          {
            type: FormTypeFiled.Time,
            key: "endTime",
            label: text([
              "Дата и время окончания выполнения задания инженером",
              "Fecha y hora de finalización de la tarea por el ingeniero"
            ]),
            disable: true,
          },
        ]
      },
      {
        type: FormTypeGroup.Table,
        title: "",
        groupId: 1,
        fields: [
          {
            type: FormTypeFiled.Table,
            key: "taskServices",
            label: text(["Услуги тарифа", "Servicios de tarifa"]),
            checkboxKey: "done",
            disable: true,
            filterKey: "isAdditional",
            filterCondition: false,
            tableTemplate: tablesTemplates[TemplatesNames.servicesAndTariffs]
          },
          {
            type: FormTypeFiled.Table,
            key: "taskServices",
            label: text(["Дополнительные услуги", "Servicios adicionales"]),
            checkboxKey: "done",
            disable: true,
            filterKey: "isAdditional",
            filterCondition: true,
            tableTemplate: tablesTemplates[TemplatesNames.servicesAndTariffs]
          },
          {
            type: FormTypeFiled.TextArea,
            key: "recommendation",
            disable: true,
            label: text(["Рекомендации", "Recomendación"]),
          },
        ]
      },
      {
        type: FormTypeGroup.Table,
        title: "",
        groupId: 2,
        fields: [
          {
            type: FormTypeFiled.Table,
            key: "defects",
            label: text(["Дефекты:", "Defectos:"]),
            checkboxKey: "eliminated",
            disable: true,
            modalOptions: new ModalOptions({
              key: "defects",
              type: ModalType.Form,
              title: text(["Редактирование дефекта", "Edición de defectos"]),
              titleAdd: text(["Создание дефекта", "Creación de un defecto"]),
              formTemplate: new FormTemplate(),
              templatesNames: TemplatesNames.identifiedDefects
            }),
            tableTemplate: tablesTemplates[TemplatesNames.identifiedDefects]
          },
        ]
      },
    ]
  },
  {
    id: 13,
    object: null,
    method: "",
    groups: []
  },
  {
    id: 14,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Wide,
        title: "",
        fields: [
          {
            type: FormTypeFiled.DropDownList,
            key: "defectType",
            label: text(["Тип дефекта", "Tipo de defecto"]),
            method: ApiPath.DefectType,
            labelKey: "name",
            fieldError: {
              isError: false,
              errorRules: [],
            }
          },
          {
            type: FormTypeFiled.TextArea,
            key: "description",
            label: text(["Описание дефекта", "Descripción del defecto"]),
            fieldError: {
              isError: false,
              errorRules: [],
            }
          },
          {
            type: FormTypeFiled.Files,
            key: "files",
            label: text(["Фото", "Foto"]),
            readonly: true
          },
        ]
      },
    ]
  },
  {
    id: 15,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.DropDownList,
            key: "isPeriodical",
            label: text(["Тип услуги", "Tipo de servicio"]),
            specifyLabelType: SpecifyLabelType.Bool,
            labelKey: "name",
            specifyOutput: true,
            optionsKey: "bool",
            options: [
              {
                id: 0,
                name: text(["Периодическая", "Periódica"]),
                code: "periodical",
                bool: true,
              },
              {
                id: 1,
                name: text(["Разовая", "Una sola vez"]),
                code: "oneTime",
                bool: false,
              }
            ],
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.DropDownList,
            key: "serviceType",
            label: text(["Наименование услуги", "Nombre del servicio"]),
            method: ApiPath.ServiceType,
            labelKey: "name",
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
        ]
      },
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.Date,
            key: "beginDate",
            label: text(["Дата, с которой надо выполнить услугу", "Fecha de inicio del servicio"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.Date,
            key: "endDate",
            label: text(["Дата, до которой надо выполнить услугу", "Fecha límite del servicio"]),
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
        ]
      },
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.DropDownList,
            key: "isFixedPrice",
            label: text(["Тип стоимости", "Tipo de costo"]),
            specifyOutput: true,
            optionsKey: "bool",
            specifyLabelType: SpecifyLabelType.Bool,
            labelKey: "name",
            options: [
              {
                id: 0,
                name: "Фиксированная",
                code: "fixed",
                bool: true,
              },
              {
                id: 1,
                name: "Зависит от объёма",
                code: "byWorkload",
                bool: false,
              }
            ],
            relationRules: [
              {
                relatedKey: "serviceUnitAmount",
                determinantKey: "isFixedPrice",
                determinantCondition: true,
                relatedValue: 1
              },
              {
                relatedKey: "serviceUnitAmount",
                determinantKey: "isFixedPrice",
                determinantCondition: false,
                relatedValue: null
              }
            ],
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "serviceUnitAmount",
            label: text(["Количество единиц услуги", "Cantidad de unidades de servicio"]),
            fieldError: {
              isError: false,
              errorRules: [
              ],
            },
            disableRules: {
              relatedKey: "serviceUnitAmount",
              determinantKey: "isFixedPrice",
              determinantCondition: true,
              relatedValue: 1
            }
          },
          {
            type: FormTypeFiled.String,
            key: "unitPrice",
            label: text(["Цена за единицу услуги", "Precio por unidad de Servicio"]),
            fieldError: {
              isError: false,
              errorRules: [
              ],
            }
          },
        ]
      },
      {
        type: FormTypeGroup.Default,
        title: "",
        fields: [
          {
            type: FormTypeFiled.DropDownList,
            key: "period",
            label: text(["Период, в рамках которого повторяется услуга", "Período de repetición del servicio"]),
            options: PeriodOptions,
            specifyLabelType: SpecifyLabelType.EnumOption,
            specifyOutput: true,
            optionsKey: "code",
            displayKey: "isPeriodical",
            displayValue: true,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
          {
            type: FormTypeFiled.String,
            key: "repetitionsNumber",
            label: text(["Количество повторений в рамках периода", "Número de repeticiones en el período"]),
            displayKey: "isPeriodical",
            displayValue: true,
            fieldError: {
              isError: false,
              errorRules: [
                {
                  errorType: ErrorType.Required,
                  errorText: text(["Обязательное поле", "Campo obligatorio"]),
                },
              ],
            }
          },
        ]
      },
    ]
  },
  {
    id: 16,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Wide,
        title: "",
        fields: [
          {
            type: FormTypeFiled.Text,
            key: "test",
            label: text([
              "Вы уверены, что хотите удалить эту услугу из списка дополнительных услуг?",
              "¿Está seguro de que desea eliminar este Servicio de la lista de extras?"
            ]),

          },
        ]
      },
    ]
  },
  {
    id: 17,
    object: null,
    method: "",
    groups: [
      {
        type: FormTypeGroup.Wide,
        title: "",
        fields: [
          {
            type: FormTypeFiled.DropDownList,
            key: "engineer",
            label: text(["Инженер для назначения", "Ingeniero para asignación"]),
            method: ApiPath.User,
            specifyLabelType: SpecifyLabelType.Fio,
            labelKey: "",
            tableFilter: new TableFilter({
              size: 100,
              query: "",
              sort: "",
              page: 0,
              totalPages: 0,
              totalElements: 0,
              role: "ROLE_ENGINEER",
            }),
          },
        ]
      },
    ]
  },
];

