import { Routes } from '@angular/router';
import { LoginComponent } from './components/pages/login/login.component';
import {AuthGuardService} from "./services/auth-guard.service";
import {MainClientComponent} from "./components/pages/client-pages/main-client/main-client.component";
import {
  ClientInspectionLogComponent
} from "./components/pages/client-pages/client-inspection-log/client-inspection-log.component";
import {
  ClientInspectionPageComponent
} from "./components/pages/client-pages/client-inspection-log/pages/client-inspection-page/client-inspection-page.component";
import {ContractsComponent} from "./components/pages/admin-pages/contracts/contracts.component";
import {TasksComponent} from "./components/pages/admin-pages/task/tasks.component";
import {TaskPageComponent} from "./components/pages/admin-pages/task/pages/task-page/task-page.component";
import {DirectoriesComponent} from "./components/pages/admin-pages/directories/directories.component";
import {
  ContractPageComponent
} from "./components/pages/admin-pages/contracts/pages/contract-page/contract-page.component";
import {AdminComponent} from "./components/pages/admin-pages/admin/admin.component";
import {UserPageComponent} from "./components/pages/admin-pages/admin/page/user-page/user-page.component";
import {
  CountryPageComponent
} from "./components/pages/admin-pages/directories/pages/country-page/country-page.component";
import {
  CurrencyPageComponent
} from "./components/pages/admin-pages/directories/pages/currency-page/currency-page.component";
import {
  DocumentPageComponent
} from "./components/pages/admin-pages/directories/pages/document-page/document-page.component";
import {
  ObjectsTypePageComponent
} from "./components/pages/admin-pages/directories/pages/objects-type-page/objects-type-page.component";
import {
  MeasurePageComponent
} from "./components/pages/admin-pages/directories/pages/measure-page/measure-page.component";
import {ClientPageComponent} from "./components/pages/admin-pages/directories/pages/client-page/client-page.component";
import {
  ObjectivePageComponent
} from "./components/pages/admin-pages/directories/pages/objective-page/objective-page.component";
import {
  TariffsPageComponent
} from "./components/pages/admin-pages/directories/pages/tariffs-page/tariffs-page.component";
import {
  ServiceTypePageComponent
} from "./components/pages/admin-pages/directories/pages/service-type-page/service-type-page.component";
import {
  DefectTypePageComponent
} from "./components/pages/admin-pages/directories/pages/defect-type-page/defect-type-page.component";
import {LegalInformationComponent} from "./components/pages/client-pages/legal-information/legal-information.component";
import {LegalContractPageComponent} from "./components/pages/client-pages/legal-information/pages/legal-contract-page/legal-contract-page.component";
import {
  ClientInspectionContractLogComponent
} from "./components/pages/client-pages/client-inspection-log/pages/client-inspection-contract-log/client-inspection-contract-log.component";


export const routes: Routes = [
  { path: '', component: LoginComponent, pathMatch: 'full'},
  { path: 'login', component: LoginComponent},
  { path: 'client', canActivate: [AuthGuardService], component: MainClientComponent },
  { path: 'client/inspection-log', canActivate: [AuthGuardService], component: ClientInspectionLogComponent },
  { path: 'client/inspection-log/contract/:id/task/:number', canActivate: [AuthGuardService], component: ClientInspectionPageComponent },
  { path: 'client/inspection-log/contract/:id', canActivate: [AuthGuardService], component: ClientInspectionContractLogComponent },
  { path: 'client/legal-information', canActivate: [AuthGuardService], component: LegalInformationComponent },
  { path: 'client/legal-information/:id', canActivate: [AuthGuardService], component: LegalContractPageComponent },
  { path: 'admin', canActivate: [AuthGuardService], component: ContractsComponent },
  { path: 'admin/tasks', canActivate: [AuthGuardService], component: TasksComponent},
  { path: 'admin/tasks', canActivate: [AuthGuardService], component: TasksComponent},
  { path: 'admin/tasks/:id', canActivate: [AuthGuardService], component: TaskPageComponent},
  { path: 'admin/directories', canActivate: [AuthGuardService], component: DirectoriesComponent},
  { path: 'admin/contracts', canActivate: [AuthGuardService], component: ContractsComponent},
  { path: 'admin/contracts/:id', canActivate: [AuthGuardService], component: ContractPageComponent},
  { path: 'admin/admin', canActivate: [AuthGuardService], component: AdminComponent},
  { path: 'admin/admin/user/:id', canActivate: [AuthGuardService], component: UserPageComponent},
  { path: 'admin/directories/country/:id', canActivate: [AuthGuardService], component: CountryPageComponent},
  { path: 'admin/directories/currency/:id', canActivate: [AuthGuardService], component: CurrencyPageComponent},
  { path: 'admin/directories/document-type/:id', canActivate: [AuthGuardService], component: DocumentPageComponent},
  { path: 'admin/directories/objective-type/:id', canActivate: [AuthGuardService], component: ObjectsTypePageComponent},
  { path: 'admin/directories/measure/:id', canActivate: [AuthGuardService], component: MeasurePageComponent},
  { path: 'admin/directories/client/:id', canActivate: [AuthGuardService], component: ClientPageComponent},
  { path: 'admin/directories/objective/:id', canActivate: [AuthGuardService], component: ObjectivePageComponent},
  { path: 'admin/directories/tariff/:id', canActivate: [AuthGuardService], component: TariffsPageComponent},
  { path: 'admin/directories/service-type/:id', canActivate: [AuthGuardService], component: ServiceTypePageComponent},
  { path: 'admin/directories/defect-type/:id', canActivate: [AuthGuardService], component: DefectTypePageComponent},

];
