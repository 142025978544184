<div class="pagination-container">
  <div class="pagination-tools text-m title-style-5">
    <div class="info">
      {{ text(["Всего записей:", "Total de registros:"]) }} {{ this.tableFilter.totalElements }}
    </div>
    <div *ngIf="setChangeSize().length > 1" class="size-changer">
      {{ text(["Размер:", "Tamaño:"]) }}
      <div class="size-item"
           *ngFor="let size of setChangeSize()"
           (click)="changeSize(size)"
           [class]="{ 'size-item-active': size == tableFilter.size }"
      >{{ size }}
      </div>
    </div>
  </div>
  <div class="pagination-cells">
    <div class="pagination-item-arrow" (click)="changeActive('prev')">
      <app-icon [icon]="iconsByName.arrow"/>
    </div>
    <div *ngFor="let number of getTotalPagesAsArray()" >
      <div (click)="changeActive(number)"
           class="pagination-item title-style-5"
           [class]="{ 'pagination-item-active': tableFilter.page == Number(number) - 1 }">
        {{ number }}
      </div>
    </div>
    <div class="pagination-item-arrow arrow-reverse" (click)="changeActive('next')">
      <app-icon [icon]="iconsByName.arrow"/>
    </div>
  </div>
</div>
