import {Component, HostListener, Input} from '@angular/core';
import {Icon, icons, iconsByName} from "../../../model/icon";
import {IconComponent} from "../icon/icon.component";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-tool',
  standalone: true,
  imports: [
    IconComponent,
    NgIf
  ],
  templateUrl: './tool.component.html',
  styleUrl: './tool.component.scss'
})
export class ToolComponent {
  @Input() icon: Icon = icons[0];
  @Input() text: string = "";
  @Input() styles: string = "";
  @Input() disable: boolean = false;
  @Input() dot: boolean = false;
  @Input() indexIndicator: number = 0;

  @HostListener("mouseleave") onMouseLeave() {
    this.isCursorIn = false;
    setTimeout(() => {
      if (!this.isCursorIn) {
        this.tooltip = false;
      }
    }, 200)
  }

  @HostListener("mouseover") onMouseCome() {
    if (!this.isCursorIn) {
      this.isCursorIn = true;
      setTimeout(() => {
        if (this.isCursorIn) {
          this.tooltip = true;
        }
      }, 700)
    }
  }


  isCursorIn: boolean = false;
  tooltip: boolean = false;


  protected readonly iconsByName = iconsByName;
}
