import {text} from "../function/localization";

export class LegalMenu {
  id: number;
  label: string;
  constructor(value: LegalMenu) {
    this.id = value.id ?? 0;
    this.label = value.label ?? "";
  }
}

export const legalMenuItems: LegalMenu[] = [
  {
    id: 0,
    label: text(["Действующие договоры", "Acuerdos vigentes"]),
  },
  {
    id: 1,
    label: text(["Архив", "Archivo"]),
  },
];
