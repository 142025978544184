import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ColumnType, Sort, TableFilter, TableTemplate} from "../../../model/table";
import {NgForOf, NgIf} from "@angular/common";
import {CheckboxComponent} from "../checkbox/checkbox.component";
import {formatDate} from "../../../function/formatDate";
import {formatRoles, formatStatuses} from "../../../function/formatRoles";
import {getPropValue} from "../../../function/getValue";
import {getFio} from "../../../function/getFio";
import {LoadingComponent} from "../loading/loading.component";
import {getPeriodLabel} from "../../../model/period";
import {getListLabels} from "../../../function/getListLabels";
import {getAddress} from "../../../function/getAddress";
import {FilterComponent} from "../filter/filter.component";
import {isFixedPrice, isPeriodical} from "../../../function/getBoolType";
import {IconComponent} from "../icon/icon.component";
import {iconsByName} from "../../../model/icon";
import {text} from "../../../function/localization";

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    CheckboxComponent,
    LoadingComponent,
    FilterComponent,
    IconComponent
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss'
})
export class TableComponent implements OnInit {
  @Input() tableTemplate: TableTemplate = new TableTemplate();
  @Input() tableFilter: TableFilter = new TableFilter();
  @Input() loading: boolean = false;
  @Input() disable: boolean = false;
  @Input() refreshSort: number = 0;
  @Input() taskPage: boolean = false;
  @Output() sortingOutput = new EventEmitter<string>();
  @Output() searchOutput = new EventEmitter<string>();
  @Output() clickRow = new EventEmitter<any>();
  @Output() clickCheckbox = new EventEmitter<{ index: number; state: boolean }>();
  @Output() tableFilterEmit = new EventEmitter<TableFilter>();
  @Output() deleteRow = new EventEmitter<any>();

  ngOnInit() {
    if (this.taskPage) {
      this.taskPageStyling();
    }
  }

  getStyleToRow(value: any) {
    if (this.taskPage && value.status == "FINISHED") {
      const w = value.taskServices.filter((q) => !q.done);
      return w.length > 0 ? "bg-red" : "";
    } else return "";
  }

  taskPageStyling() {
    const x = this.tableTemplate.content.filter((z) => z.status == "FINISHED");
    const y = x.filter((z) => {
      const w = z.taskServices.filter((q) => !q.done);
      return w.length > 0;
    });
  }

  clickOnRow(row: any) {
    this.clickRow.emit(row);
  }

  clickOnCheckbox(index: number, state: boolean) {
    this.clickCheckbox.emit({index, state});
  }

  search(search: string) {
    this.searchOutput.emit(search);
  }


  columnUpd(key: string, sort: Sort) {
    this.tableTemplate.columns.forEach(c => {
      c.sort = Sort.None;
    })
    this.tableTemplate.columns.forEach(c => {
      if (c.key == key) {
        c.sort = sort;
      }
    })
  }

  getValue(key: string, row: any) {
    return getPropValue(row, key);
  }

  formatingValue(key: string, columnType: ColumnType, row: any, labelKey?: string) {
    const value = this.getValue(key, row);

    switch (columnType) {
      case ColumnType.Default:
        return value;
      case ColumnType.Date:
        return formatDate(value);
      case ColumnType.Role:
        return formatRoles(value);
      case ColumnType.Fio:
        return getFio(row, key);
      case ColumnType.Status:
        return formatStatuses(value);
      case ColumnType.Period:
        return getPeriodLabel(value);
      case ColumnType.Checkbox:
        return value;
      case ColumnType.ServiceTypes:
        return getListLabels(value, labelKey);
      case ColumnType.Address:
        return getAddress(row, key);
      case ColumnType.IsFixedPrice:
        return isFixedPrice(value);
      case ColumnType.isPeriodical:
        return isPeriodical(value);
    }
  }

  protected readonly ColumnType = ColumnType;
  protected readonly iconsByName = iconsByName;
  protected readonly text = text;
}
