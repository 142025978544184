import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Option} from "../../../model/option";
import {NgForOf} from "@angular/common";
import {CheckboxComponent} from "../checkbox/checkbox.component";
import {Client} from "../../../model/client";
import {text} from "../../../function/localization";

@Component({
  selector: 'app-option-switcher',
  standalone: true,
  imports: [
    NgForOf,
    CheckboxComponent
  ],
  templateUrl: './option-switcher.component.html',
  styleUrl: './option-switcher.component.scss'
})
export class OptionSwitcherComponent implements OnInit{
  @Input() options: Option[] = [];
  @Input() value: string[] = [];
  @Input() radioSelection: boolean = false;
  @Input() setActive: boolean = false;
  @Output() emitChange = new EventEmitter();
  @Output() emitSetActive: EventEmitter<number> = new EventEmitter();

  ngOnInit() {
    if (this.radioSelection) {
      this.options.forEach(o => {
        o.selected = false;
      });
      const selected = this.options.find(v => v.code == this.value[0]);
      if (selected) {
        selected.selected = true;
      } else {
        this.options[0].selected = true;
      }
    } else {
      this.options.forEach(option => {
        const x = this.value.find((v: any) => v == option.code);
        if (x) {
          option.selected = true;
        }
      })
    }
  }

  select(value: boolean, option: Option){
    option.selected = value;
    if (this.radioSelection) {
      if (value) {
        const s = option;
        this.options.forEach(v => v.selected = false);
        const o = this.options.find(v => v == s);
        if (o) {
          o.selected = true;
          if (this.setActive) {
            this.emitSetActive.emit(o.id);
            this.emitChange.emit(new Client()); //TODO временный(надеюсь) костыль
          } else {
            this.emitChange.emit(value);
          }
        }
      }
    } else {
      this.emitChange.emit(this.options.filter(f => f.selected).map(v => v.code));
    }
  }

  protected readonly text = text;
}
