<div *ngIf="this.modalService.getModal()" (click)="cancel()" class="modal-container">
  <div class="modal-window" [class]="setClass()" (click)="$event.stopPropagation()">
    <div *ngIf="modalService.getOptions()?.type != ModalType.ImagesFullScreen" class="modal-form-container">
      <div class="modal-form-title">
        <h3>{{ modalService.getOptions()?.title }}</h3>
      </div>
      <div class="modal-form-content">
        <div *ngIf="modalService.getOptions()?.type == ModalType.Form">
          <app-form
            [formTemplate]="modalService.getOptions()?.formTemplate ?? formTemplates[0]"
            (emitObj)="setObj($event)"
            (emitCancel)="cancel()"
          />
        </div>
        <div *ngIf="modalService.getOptions()?.type == ModalType.Confirm">
          <div class="button-confirm">
            <app-button (click)="approve()" [styleClass]="'button-style-red'">{{ text(["Удалить", "Eliminar"]) }}</app-button>
            <app-button (click)="cancel()" [styleClass]="'button-style-white-red'">{{ text(["Отменить", "Cancelar"]) }}</app-button>
          </div>
        </div>
      </div>
    </div>
    <div class="image-container" *ngIf="modalService.getOptions()?.type == ModalType.ImagesFullScreen">
      <img id="fullScreen" [src]='modalService.getModal()' alt="fullScreen"/>
    </div>
  </div>
</div>
