
export class LanguageList {
  code: string = "";
  labels: string[] = [];
  active: boolean = false;
  constructor(value?: LanguageList) {
    if (value) {
      this.code = value?.code;
      this.labels = value?.labels;
      this.active = value?.active;
    }
  }
}
