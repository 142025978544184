import {text} from "../function/localization";

export class DirectoriesMenu {
  id: number;
  name: string;
  label: string;

  constructor(value: DirectoriesMenu) {
    this.id = value.id ?? 0;
    this.name = value.name ?? "";
    this.label = value.label ?? "";
  }
}

export const dataDirectories : DirectoriesMenu[] = [
  {
    id: 0,
    name: text(["Страны", "Países"]),
    label: "",
  },
  {
    id: 1,
    name: text(["Валюты", "Monedas"]),
    label: "",
  },
  {
    id: 2,
    name: text(["Типы документов", "Tipos de documentos"]),
    label: "",
  },
  {
    id: 3,
    name: text(["Типы объектов", "Tipos de objetos"]),
    label: "",
  },
  {
    id: 4,
    name: text(["Единицы измерения", "Unidades de medida"]),
    label: "",
  },
  {
    id: 5,
    name: text(["Клиенты", "Clientes"]),
    label: "",
  },
  {
    id: 6,
    name: text(["Объекты", "Objetos"]),
    label: "",
  },
  {
    id: 7,
    name: text(["Тарифы", "Tarifas"]),
    label: "",
  },
  {
    id: 8,
    name: text(["Типы услуг", "Tipos de servicios"]),
    label: "",
  },
  {
    id: 9,
    name: text(["Типы дефектов", "Tipos de defectos"]),
    label: "",
  },
];

