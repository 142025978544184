import { Routes } from '@angular/router';
import { LoginComponent } from './components/pages/login/login.component';
import {AuthGuardService} from "./services/auth-guard.service";
import {DirectoriesComponent} from "./components/pages/directories/directories.component";
import {ContractsComponent} from "./components/pages/contracts/contracts.component";
import {AdminComponent} from "./components/pages/admin/admin.component";
import {UserPageComponent} from "./components/pages/admin/page/user-page/user-page.component";
import {CountryPageComponent} from "./components/pages/directories/pages/country-page/country-page.component";
import {CurrencyPageComponent} from "./components/pages/directories/pages/currency-page/currency-page.component";
import {DocumentPageComponent} from "./components/pages/directories/pages/document-page/document-page.component";
import {ContractPageComponent} from "./components/pages/contracts/pages/contract-page/contract-page.component";
import {ObjectsTypePageComponent} from "./components/pages/directories/pages/objects-type-page/objects-type-page.component";
import {MeasurePageComponent} from "./components/pages/directories/pages/measure-page/measure-page.component";
import {ClientPageComponent} from "./components/pages/directories/pages/client-page/client-page.component";
import {ObjectivePageComponent} from "./components/pages/directories/pages/objective-page/objective-page.component";
import {TariffsPageComponent} from "./components/pages/directories/pages/tariffs-page/tariffs-page.component";
import {
  ServiceTypePageComponent
} from "./components/pages/directories/pages/service-type-page/service-type-page.component";
import {
  DefectTypePageComponent
} from "./components/pages/directories/pages/defect-type-page/defect-type-page.component";
import {TasksComponent} from "./components/pages/task/tasks.component";
import {TaskPageComponent} from "./components/pages/task/pages/task-page/task-page.component";

export const routes: Routes = [
  { path: '', canActivate: [AuthGuardService], component: ContractsComponent, pathMatch: 'full' },
  { path: 'login', component: LoginComponent},
  { path: 'tasks', canActivate: [AuthGuardService], component: TasksComponent},
  { path: 'tasks', canActivate: [AuthGuardService], component: TasksComponent},
  { path: 'tasks/:id', canActivate: [AuthGuardService], component: TaskPageComponent},
  { path: 'directories', canActivate: [AuthGuardService], component: DirectoriesComponent},
  { path: 'contracts', canActivate: [AuthGuardService], component: ContractsComponent},
  { path: 'contracts/:id', canActivate: [AuthGuardService], component: ContractPageComponent},
  { path: 'admin', canActivate: [AuthGuardService], component: AdminComponent},
  { path: 'admin/user/:id', canActivate: [AuthGuardService], component: UserPageComponent},
  { path: 'directories/country/:id', canActivate: [AuthGuardService], component: CountryPageComponent},
  { path: 'directories/currency/:id', canActivate: [AuthGuardService], component: CurrencyPageComponent},
  { path: 'directories/document-type/:id', canActivate: [AuthGuardService], component: DocumentPageComponent},
  { path: 'directories/objective-type/:id', canActivate: [AuthGuardService], component: ObjectsTypePageComponent},
  { path: 'directories/measure/:id', canActivate: [AuthGuardService], component: MeasurePageComponent},
  { path: 'directories/client/:id', canActivate: [AuthGuardService], component: ClientPageComponent},
  { path: 'directories/objective/:id', canActivate: [AuthGuardService], component: ObjectivePageComponent},
  { path: 'directories/tariff/:id', canActivate: [AuthGuardService], component: TariffsPageComponent},
  { path: 'directories/service-type/:id', canActivate: [AuthGuardService], component: ServiceTypePageComponent},
  { path: 'directories/defect-type/:id', canActivate: [AuthGuardService], component: DefectTypePageComponent},

];
