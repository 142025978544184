import {Component, OnInit} from '@angular/core';
import {SectionComponent} from "../../../elements/section/section.component";
import {HeaderService} from "../../../../services/header.service";
import {headers} from "../../../../model/header";
import {MenuService} from "../../../../services/menu.service";
import {ClientSubheaderComponent} from "../../../elements/client-subheader/client-subheader.component";
import {CellItemComponent} from "../../../elements/cell-item/cell-item.component";
import {ApiService} from "../../../../services/api.service";
import {ApiPath} from "../../../../model/global";
import {TableFilter} from "../../../../model/table";
import {Task, tuskStatusesMap} from "../../../../model/task";
import {NgForOf, NgIf} from "@angular/common";
import {Contract} from "../../../../model/contract";
import {iconsByName} from "../../../../model/icon";
import {LoadingComponent} from "../../../elements/loading/loading.component";
import {ClientSubheader} from "../../../../model/clientSubheader";
import {correctFormattedDate, formatDate} from "../../../../function/formatDate";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {text} from "../../../../function/localization";
import {IconComponent} from "../../../elements/icon/icon.component";
import {ButtonComponent} from "../../../elements/button/button.component";

@Component({
  selector: 'app-client-inspection-log',
  standalone: true,
  imports: [
    SectionComponent,
    ClientSubheaderComponent,
    CellItemComponent,
    NgIf,
    NgForOf,
    LoadingComponent,
    RouterLink,
    IconComponent,
    ButtonComponent
  ],
  templateUrl: './client-inspection-log.component.html',
  styleUrl: './client-inspection-log.component.scss'
})
export class ClientInspectionLogComponent implements OnInit{
  constructor(public headerService: HeaderService,
              public menuService: MenuService,
              protected apiService: ApiService,
              private route: ActivatedRoute,
              private router: Router,) {}


  loading = false;
  contracts: Contract[] = [];

  subheader: ClientSubheader = {
    title: text(['Правовая информация', 'Información legal']),
    breadcrumbs: [
      {
        name: text(['Главное меню', 'Menú principal']),
        link: "/client",
      },
      {
        name: text(['Журнал осмотров', 'Registro de inspecciones']),
        link: "",
      },
    ],
    icon: iconsByName.spanner,
    backLink: true,
    color: "#3abeb1",
  }

  tableFilter: TableFilter = new TableFilter({
    size: 100,
    query: "",
    sort: "planDate:DESC,beginDate:DESC",
    page: 0,
    totalPages: 0,
    totalElements: 0,
  });

  ngOnInit() {
    this.headerService.setCurrentHeader(headers[1]);
    this.menuService.setActiveTab(1);
    this.getContract();
  }

  getContract() {
    this.loading = true;
    this.apiService.getList(ApiPath.ClientContract, this.tableFilter)
      .subscribe((data: any) => {
        this.contracts = data.map((v: Contract | undefined) => new Contract(v)) ?? [];
        if (data.length == 1) {
          this.router.navigate(['/client/inspection-log/contract/' + data[0].id], {relativeTo: this.route}).then(r => r);

        }
        this.loading = false;
      });
  }

  back(){
      this.router.navigate(["/client"],{relativeTo: this.route}).then(r => r);
  }






  protected readonly iconsByName = iconsByName;
  protected readonly tuskStatusesMap = tuskStatusesMap;
  protected readonly formatDate = formatDate;
  protected readonly text = text;
  protected readonly correctFormattedDate = correctFormattedDate;
}
