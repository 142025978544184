import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IconComponent} from "../icon/icon.component";
import {iconsByName} from "../../../model/icon";
import {ButtonComponent} from "../button/button.component";
import {Breadcrumbs, ClientSubheader} from "../../../model/clientSubheader";
import {NgForOf, NgIf} from "@angular/common";
import {Router, RouterLink} from "@angular/router";
import {text} from "../../../function/localization";

@Component({
  selector: 'app-client-subheader',
  standalone: true,
  imports: [
    IconComponent,
    ButtonComponent,
    NgForOf,
    NgIf,
    RouterLink
  ],
  templateUrl: './client-subheader.component.html',
  styleUrl: './client-subheader.component.scss'
})
export class ClientSubheaderComponent {
  @Input() clientSubheader: ClientSubheader | null = null;
  @Output() clickBack = new EventEmitter<any>();

  constructor(private router: Router){

  }
  action(){
    this.clickBack.emit();
  }

  route(breadcrumbs: Breadcrumbs){
    if (breadcrumbs.link.length > 0) {
      this.router.navigate([breadcrumbs.link]).then();
    }
  }


  protected readonly iconsByName = iconsByName;
  protected readonly text = text;
}
