export function text(texts: string[] | string) {
  if (typeof texts == "string") {
    return texts;
  } else {
    const idx = localStorage.getItem("language");
    if (idx != null) {
      const idxParsed: number = JSON.parse(idx);
      return texts[idxParsed] ? texts[idxParsed] : texts[0];
    } else {
      return texts[0] ?? "";
    }
  }
}

export function getLocalizationSeparator(){
  const idx = localStorage.getItem("language");
  if (idx != null) {
    const idxParsed: number = JSON.parse(idx);
    return idxParsed == 0 ? "." : '/';
  } else {
    return ".";
  }
}
