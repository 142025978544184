import {roleLabelMap, UsersRole} from "../model/user";
import {Option} from "../model/option";
import {TaskStatuses, tuskStatusesMap} from "../model/task";
import {text} from "./localization";

export function formatRoles(value: string[]) {
  return value.map(v => UsersRole[v as keyof typeof UsersRole])
    .sort((a, b) => {
      // TODO
      return 0
    })
    .map(v1 => text(roleLabelMap.get(v1) ?? [])).join(", ");
}

export function formatStatuses(value: string) {
  return tuskStatusesMap.get(value) ?? ""
}
