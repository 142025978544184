import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiPath} from "../../../model/global";
import {NgForOf, NgIf} from "@angular/common";
import {defaultFilter, TableFilter} from "../../../model/table";
import {ApiService} from "../../../services/api.service";
import {FormsModule} from "@angular/forms";
import {getSpecifyLabelType, SpecifyLabelType} from "../../../function/getSpecifyLabel";
import {IconComponent} from "../icon/icon.component";
import {iconsByName} from "../../../model/icon";
import {Option} from "../../../model/option";
import {ClickedOutsideDirectiveDirective} from "../../directive/clicked-outside-directive.directive";
import {text} from "../../../function/localization";

@Component({
  selector: 'app-drop-down-list-multiselect',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    FormsModule,
    IconComponent,
    ClickedOutsideDirectiveDirective
  ],
  templateUrl: './drop-down-list-multiselect.component.html',
  styleUrl: './drop-down-list-multiselect.component.scss'
})
export class DropDownListMultiselectComponent implements OnInit{
  @Input() method: ApiPath = ApiPath.Default;
  @Input() labelKey: string = "name";
  @Input() optionList: unknown[] = [];
  @Input() value: any = null;
  @Input() specifyLabelType: SpecifyLabelType = SpecifyLabelType.Default;
  @Input() multiSelect: boolean = false;
  @Input() emunOptions: boolean = false;
  @Input() isClearSelected: boolean = false;
  @Input() filter: TableFilter = defaultFilter;
  @Input() presetIds: number[] = [];
  @Output() emitChange  = new EventEmitter();
  constructor(protected apiService: ApiService) {
  }

  loading: boolean = false;
  openList: boolean = false;
  options: unknown[] = [];
  totalElements: number = 0;

  ngOnInit() {
    if (this.method) {
      this.getDataByMethod();
    } else {
      this.options = this.optionList;
      this.preset();
    }
  }

  getCorrectLabel(value: any, key: string, specifyLabelType: SpecifyLabelType){
    return getSpecifyLabelType(specifyLabelType, value, key, this.options);
  }

  selectItem(option: any) {
    const isSelectedItem = this.value.find((f: any) => f.id == option.id);
    if (isSelectedItem) {
      const index = this.value.indexOf(isSelectedItem);
      if (index > -1) {
        this.value.splice(index,1);
      }
    } else {
      this.value.push(option);
    }
    this.emitChange.emit(this.value);
  }

  getDataByMethod() {
    this.loading = true;
    this.apiService.getList(this.method, this.filter)
      .subscribe((data) => {
        this.totalElements = data.page.totalElements ?? 0;
        this.options = data.content ?? [];
        this.preset();
        this.loading = false;
      });
  }

  clearSelected(){
    this.value = [];
    this.emitChange.emit(this.value);
  }

  clearSearch() {
    this.filter.query = "";
    this.getDataByMethod();
  }

  getLabelSelectedItems(value: any[], key: string, specifyLabelType: SpecifyLabelType) {
    if (value.length > 0) {
      return value.map((v: any) => this.getCorrectLabel(v, key, specifyLabelType)).join(", ");
    } else return "";
  }

  isSelected(item: any) {
    const x = this.value.find((v: any) => v.id === item.id);
    return x != undefined;
  }

  preset(){

    if (this.presetIds != undefined && this.presetIds.length > 0) {
      this.value = [];
      this.presetIds.forEach((id) => {
        const item = this.options.find((f: any) => f.id == id);
        if (item != undefined) {
          this.value.push(item);
        }
      });
    }
  }

  protected readonly iconsByName = iconsByName;
    protected readonly text = text;
}
