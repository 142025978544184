<div class="autocomplete-container" appClickedOutsideDirective (clickedOutside)="reset()">
  <input
    type="text"
    placeholder='{{ text(["Введите адрес", "Introduzca la dirección"]) }}'
    [(ngModel)]="searchString"
    (ngModelChange)="search($event)"
  />
  <div class="checkbox-container">
    <app-checkbox [value]="handleInput" (valueOutput)="changeMode($event)"/>
    <div style="margin-left: 0.5rem">{{ text(["Ручной ввод", "Entrada manual"]) }}</div>
  </div>
  <div *ngIf="isSearch()" class="search-result-container">
    <div *ngFor="let item of searchResult" (click)="selectItem(item)" class="search-item">
      {{ item.getAddress() }}
    </div>
     <div *ngIf="searchResult.length == 0 && isSearch()" class="search-item">Ничего не найдено</div>
  </div>
</div>

