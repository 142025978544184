import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TableComponent} from "../table/table.component";
import {defaultFilter, TableFilter, TableTemplate} from "../../../model/table";
import {formTemplates, TemplatesNames} from "../../../model/templates";
import {setValueInto} from "../../../function/setValue";
import {ModalService} from "../../../services/modal.service";
import {ModalOptions, ModalType} from "../../../model/modalOptions";
import {FormTemplate} from "../../../model/form";
import {getPropValue} from "../../../function/getValue";
import {ButtonComponent} from "../button/button.component";
import {NgIf} from "@angular/common";
import {NotificationForm, NotificationType} from "../../../model/notification";
import {NotificationService} from "../../../services/notification.service";
import {text} from "../../../function/localization";
import {Subscription} from "rxjs";


@Component({
  selector: 'app-service-table',
  standalone: true,
  imports: [
    TableComponent,
    ButtonComponent,
    NgIf
  ],
  templateUrl: './service-table.component.html',
  styleUrl: './service-table.component.scss'
})
export class ServiceTableComponent implements OnInit, OnDestroy {
  constructor(protected modalService: ModalService, protected notificationService: NotificationService) {
    this.subscription = this.modalService.modalBuffer.subscribe(() => {
      this.saveModalChanges(this.modalService.getOptions(), this.modalService.getModalBuffer(), this.modalService.getModalActive());
    });
  }

  @Input() labelKey: string = "name";
  @Input() filterKey: string = "";
  @Input() filterCondition: boolean | undefined = undefined;
  @Input() tableTemplate: TableTemplate = new TableTemplate();
  @Input() activeTabId: number | null = null;
  @Input() value: any = null;
  @Input() checkboxKey: string | null = null;
  @Input() modalOptions: ModalOptions | null | undefined = null;
  @Input() disable: boolean = false;
  @Input() isCreateNew: boolean = false;
  @Input() isPeriodical: boolean = false;
  @Output() emitChange = new EventEmitter<any>();

  subscription: Subscription;


  tableFilter: TableFilter = defaultFilter;
  newTableTemplate: TableTemplate = new TableTemplate();


  clickRow(item: any) {
    this.openModal(true, item);
  }

  openModal(edit: boolean, item: any) {
    if (this.modalOptions && this.modalOptions.templatesNames) {
      const opt: ModalOptions = new ModalOptions(this.modalOptions);
      opt.formTemplate = new FormTemplate(formTemplates[this.modalOptions.templatesNames]);
      opt.formTemplate.disable = this.disable;
      if (edit) {
        opt.formTemplate.object = Object.assign({}, item);
      } else {
        opt.formTemplate.object = Object.assign({}, this.modalOptions.obj);
      }
      this.modalService.setModalActive(this.modalOptions.key);
      this.modalService.setModal(true, opt);
    }
  }

  saveModalChanges(options: ModalOptions | null, value: any, key: string) {
    if (options?.type != null) {
      switch (options.type) {
        case ModalType.Form: {
          if (value && key == this.modalOptions?.key) {
            const index = this.newTableTemplate.content.indexOf(this.newTableTemplate.content.find((f: any) => f.index == value.index));
            if (index > -1) {
              this.newTableTemplate.content[index] = value;
            } else {
              this.newTableTemplate.content.push(value);
            }
            this.emitChange.emit(this.newTableTemplate.content);
          }
          break;
        }
        case ModalType.Confirm: {
          const index = options.obj.index;
          if (index != undefined) {
            const findIndex = this.newTableTemplate.content.findIndex((v: any) => v.index == index);
            if (findIndex > -1) {
              this.newTableTemplate.content.splice(findIndex, 1);
            } else {
              this.notificationService.callNotification(new NotificationForm({
                label: text(["Ошибка1", "Error"]),
                status: NotificationType.Error,
                text: ``
              }));
            }
          } else {
            this.notificationService.callNotification(new NotificationForm({
              label: text(["Ошибка2", "Error"]),
              status: NotificationType.Error,
              text: ``
            }));
          }
          break;
        }
        case ModalType.Table: {
          break;
        }
        default:
          break;
      }
      this.getIndexes();
    }
  }


  setCheckbox(data: { index: number; state: boolean }) {
    if (this.checkboxKey) {
      this.newTableTemplate.content[data.index] = setValueInto(this.checkboxKey, data.state, this.newTableTemplate.content[data.index]);
      this.emitChange.emit(this.newTableTemplate.content);
    }
  }

  ngOnInit() {
    this.newTableTemplate = new TableTemplate(this.tableTemplate);
    if (this.filterKey.length > 0 && this.filterCondition != undefined) {
      this.newTableTemplate.content = this.value.filter((v: any) => {
        const x = getPropValue(v, this.filterKey) ?? false;
        return x == this.filterCondition;
      });
    } else {
      this.newTableTemplate.content = this.value.map((v: any) => v);
    }
    this.getIndexes();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getIndexes() {
    this.newTableTemplate.content.forEach((v: any, index: number) => {
      v.index = index;
    })
  }

  deleteRow(row: any) {
    const opt: ModalOptions = new ModalOptions({
      type: ModalType.Confirm,
      key: "delete",
      title: text(["Удаление услуги", "Eliminación de servicios"]) + ' ' + row.serviceType.name + '"',
      titleAdd: "",
      obj: row
    });
    this.modalService.setModalActive(opt.key);
    this.modalService.setModal(true, opt);
    this.emitChange.emit(this.newTableTemplate.content);
  }

  protected readonly text = text;


}
