import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ErrorType, FormFiled, FormTypeFiled} from "../../../model/form";
import {NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {SwitchComponent} from "../switch/switch.component";
import {OptionSwitcherComponent} from "../option-switcher/option-switcher.component";
import {dataFieldsDirectories, DirectoriesFieldsMenu} from "../../../model/directories-fields";
import {ButtonComponent} from "../button/button.component";
import {DropDownListComponent} from "../drop-down-list/drop-down-list.component";
import {ApiPath} from "../../../model/global";
import {getPropValue} from "../../../function/getValue";
import {getFio} from "../../../function/getFio";
import {SmartDropDownListComponent} from "../smart-drop-down-list/smart-drop-down-list.component";
import {SpecifyLabelType} from "../../../function/getSpecifyLabel";

import {FilesFieldComponent} from "../files-field/files-field.component";
import {DropDownListMultiselectComponent} from "../drop-down-list-multiselect/drop-down-list-multiselect.component";
import {ServiceTableComponent} from "../service-table/service-table.component";
import {defaultFilter, TableTemplate} from "../../../model/table";
import {tablesTemplates} from "../../../model/templates";
import {find} from "rxjs";
import {isEmptyObj} from "../../../function/emptyObj";
import {AutocompleteComponent} from "../autocomplete/autocomplete.component";
import {AutoSetRule, RelationRule} from "../../../model/relationRule";

@Component({
  selector: 'app-input-field',
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    SwitchComponent,
    OptionSwitcherComponent,
    NgForOf,
    ButtonComponent,
    DropDownListComponent,
    SmartDropDownListComponent,
    DropDownListComponent,
    DropDownListMultiselectComponent,
    FilesFieldComponent,
    ServiceTableComponent,
    AutocompleteComponent,
  ],
  templateUrl: './input-field.component.html',
  styleUrl: './input-field.component.scss'
})
export class InputFieldComponent implements OnChanges {
  @Input() value: any = null;
  @Input() field: FormFiled = new FormFiled();
  @Input() onChange: number = 0;
  @Input() objId: number | null = null;
  @Input() activeTabId: number | null = null;
  @Input() noPadding: boolean = false;
  @Input() obj: any = null;
  @Input() disable: boolean = false;
  @Output() saveValue: EventEmitter<any> = new EventEmitter();
  @Output() updObj: EventEmitter<any> = new EventEmitter();
  @Output() setActiveTab: EventEmitter<number> = new EventEmitter();
  @Output() switchInput: EventEmitter<number> = new EventEmitter();
  @Output() emitFiles: EventEmitter<File[]> = new EventEmitter();
  @Output() emitFilesToDel: EventEmitter<number[]> = new EventEmitter();
  @Output() emitChanges: EventEmitter<number> = new EventEmitter();
  @Output() emitAutoSetRules: EventEmitter<AutoSetRule[]> = new EventEmitter();

  constructor() {
  }

  errorText: string = "";
  directoriesFields: DirectoriesFieldsMenu[] = dataFieldsDirectories;
  styleClass: string = "button-style-2";
  isPeriodical: boolean = false;

  // checkPeriodical() {
  //   if (this.field.options != undefined) {
  //     this.isPeriodical = this.field.options.find((f: any) => f.bool == true);
  //   }
  //
  // }

  detectError(field?: any) {
    const value: any = field ? field : this.value;
    if (this.field.fieldError && this.field.fieldError.errorRules) {
      this.field.fieldError.errorRules?.forEach((errorRule) => {
        if (this.field.fieldError?.isError != undefined) {
          switch (errorRule.errorType) {
            case ErrorType.Default:
              break;
            case ErrorType.Required: {
              const t = typeof value;
              let isEr = true;
              switch (t) {
                case "string": {
                  if (value.length > 0) isEr = false;
                  break;
                }
                case "object": {
                  if (!isEmptyObj(value)) isEr = false;
                  break;
                }
                case "boolean": {
                  if (value != undefined) isEr = false;
                  break;
                }
              }
              if (!isEr) {
                this.field.fieldError.isError = false;
                this.errorText = "";
              } else {
                this.field.fieldError.isError = true;
                this.errorText = errorRule.errorText;
              }
              break;
            }
            case ErrorType.MinLength: {
              if (errorRule.minLength && value != undefined) {
                if (value.length < errorRule.minLength && value.length != 0) {
                  this.field.fieldError.isError = true;
                  this.errorText = errorRule.errorText;
                } else {
                  this.field.fieldError.isError = false;
                  this.errorText = "";
                }
              }
              break;
            }
            case ErrorType.MinDate: {
              if (value && errorRule.beginDateKey != undefined) {
                const min = getPropValue(this.obj, errorRule.beginDateKey);
                if (min) {
                  if (new Date(value) < new Date(min)) {
                    this.field.fieldError.isError = true;
                    this.emitChanges.emit();
                    this.errorText = errorRule.errorText;
                  } else {
                    this.field.fieldError.isError = false;
                    this.errorText = "";
                  }
                }
              }
              break;
            }
            case ErrorType.MaxDate: {
              if (value && errorRule.endDateKey != undefined) {
                const max = getPropValue(this.obj, errorRule.endDateKey);
                if (max) {
                  if (new Date(value) > new Date(max)) {
                    this.field.fieldError.isError = true;
                    this.emitChanges.emit();
                    this.errorText = errorRule.errorText;
                  } else {
                    this.field.fieldError.isError = false;
                    this.errorText = "";
                  }
                }
              }
              break;
            }
          }
        }
      })
    }
  }

  isRequired(field: FormFiled) {
    return field.fieldError?.errorRules?.find(f => f.errorType == ErrorType.Required);
  }

  setActive(id?: number) {
    if (id != undefined) {
      this.setActiveTab.emit(id);
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    const isHeader = Object.keys(changes).find((f: string) => f == "onChange");
    if (isHeader) {
      if (changes[isHeader].currentValue > 0) {
        this.detectError();

      }
    }
  }



  protected readonly FormTypeFiled = FormTypeFiled;
  protected readonly ApiPath = ApiPath;
  protected readonly getPropValue = getPropValue;
  protected readonly getFio = getFio;
  protected readonly SpecifyLabelType = SpecifyLabelType;
  protected readonly TableTemplate = TableTemplate;
  protected readonly tablesTemplates = tablesTemplates;
  protected readonly defaultFilter = defaultFilter;
}
