import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {User} from "../model/user";
import {ResponsePage} from "../model/responsePage";
import {TableFilter} from "../model/table";
import {LocalizationService} from "./localization.service";


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private httpClient: HttpClient,
              protected localizationService: LocalizationService) {
  }

  getList<T>(url: string, tableFilter: TableFilter, sort?: string): Observable<ResponsePage<T[]>> {
    const params = {
      pageNumber: tableFilter.page,
      pageSize: tableFilter.size,
      sort: tableFilter.sort,
      role: tableFilter.role ?? "",
      objectiveIds: tableFilter.objectiveIds ?? [],
      contractIds: tableFilter.contractIds ?? [],
      beginDateFrom: tableFilter.beginDateFrom ?? "",
      beginDateTo: tableFilter.beginDateTo ?? "",
      endDateFrom: tableFilter.endDateFrom ?? "",
      endDateTo: tableFilter.endDateTo ?? "",
      engineerIds: tableFilter.engineerIds ?? [],
      statuses: tableFilter.statuses ?? [],
      clientIds: tableFilter.clientIds ?? [],
      query: tableFilter.query ? tableFilter.query : "",
      planDateFrom: tableFilter.planDateFrom ? tableFilter.planDateFrom : "",
      planDateTo: tableFilter.planDateTo ? tableFilter.planDateTo : "",
    };
    return this.httpClient.get<ResponsePage<T[]>>(
      `${environment.apiUrl}/${url}/find`,
      {
        params: params
      }
    );
  }

  getObj<T>(url: string, id: number): Observable<T> {
    return this.httpClient.get<T>(
      `${environment.apiUrl}/${url}/find/${id}`
    );
  }

  saveObj<T>(url: string, obj: T): Observable<T> {
    const params = { params: { language: this.localizationService.getLangCodeParams() } };
    return this.httpClient.post<T>(`${environment.apiUrl}/${url}/save`, obj, params);
  }
}
