<app-section>
  <div *ngIf="loading" class="loading-container">
    <app-loading />
  </div>
  <div *ngIf="!loading" class="legal-contract-page-container">
    <app-client-subheader [clientSubheader]="subheader" (clickBack)="back()"/>
    <div class="tabs-container">
      <div class="tab active-tab">
        {{ text(['Услуги по договору', 'Servicios bajo contrato']) }}
      </div>
    </div>
    <div class="content-container">
      <div class="fields-container">
        <div class="field-container">
          <div class="label">{{ text(['Объект недвижимости', 'Inmueble']) }}</div>
          <div class="field">
            <p>{{ getAddress(contract.objective) }}</p>
            <app-icon style="width: 20px; margin-left: 1rem" [icon]="iconsByName.calendar"/>
          </div>
        </div>
        <div class="field-container">
          <div class="label">
            {{ text(['Тариф', 'Tarifa']) }}
          </div>
          <div class="field">
            <p>{{ contract.tariff?.name }}</p>
            <app-icon style="width: 20px" [icon]="iconsByName.calendar"/>
          </div>
        </div>
      </div>
    </div>
    <div class="sausages-container">
      <div class="sausages-block">
        <h4>{{ text(['Услуги тарифа', 'Servicios de tarifa']) }}</h4>
        <div *ngFor="let t of contract.tariff?.serviceTypes" class="sausage">
          <app-cell-item
            [icon]="iconsByName.tariffServices"
            [color]="'#3794FF'"
            [title]="t.name ?? ''"
            [label]="getCorrectPeriod((contract.tariff?.repetitionsNumber ?? 0), (contract.tariff?.period ?? ''))"
          />
        </div>
      </div>
      <div class="sausages-block">
        <h4>{{ text(['Дополнительные услуги', 'Servicios adicionales']) }}</h4>
        <div *ngFor="let ad of contract.additionalServices" class="sausage">
          <app-cell-item
            [icon]="iconsByName.tariffAdditionalServices"
            [color]="'#3794FF'"
            [title]="ad.serviceType.name ?? ''"
            [label]="getCorrectPeriod((contract.tariff?.repetitionsNumber ?? 0), (contract.tariff?.period ?? ''))"
            [headRightText]="ad.isPeriodical"
            [botRightText]="text(['с ', 'del ']) + formatDate(ad.beginDate) + text([' по ', ' a ']) + formatDate(ad.endDate)"
          />
        </div>
<!--        <div class="sausage">-->
<!--          <app-cell-item-->
<!--            [icon]="iconsByName.blackBlueHands"-->
<!--            [color]="'#C1D7EB'"-->
<!--            [title]="text(['Для прочего', 'Para otras cosas'])"-->
<!--            [label]="text(['Для прочего', 'Para otras cosas'])"-->
<!--          />-->
<!--        </div>-->
      </div>
    </div>
  </div>
</app-section>
