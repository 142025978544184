<app-section>
  <div *ngIf="loading" class="loading-container">
    <app-loading />
  </div>
  <div *ngIf="!loading" class="client-inspection-page-container">
    <app-client-subheader [clientSubheader]="subheader" (clickBack)="back()"/>
    <div class="tabs-container">
      <div class="tab" [class]="activeTab == 0 ? 'active-tab' : ''" (click)="activeTab = 0">
        {{ text(['Результаты осмотра', 'Resultados de la inspección']) }}
      </div>
      <div class="tab" [class]="activeTab == 1 ? 'active-tab' : ''" (click)="activeTab = 1">
        {{ text(['Выявленные проблемы', 'Problemas identificados']) }}
      </div>
    </div>
    <div *ngIf="activeTab == 0" class="result">
      <div class="content-container">
        <div class="fields-container">
          <div class="field-container">
            <div class="label">{{ text(['Дата начала осмотра', 'Fecha de Inicio de la inspección']) }}</div>
            <div class="field">
              <p>{{ formatDate(task?.beginDate) }}</p>
              <app-icon style="width: 20px" [icon]="iconsByName.calendar"/>
            </div>
          </div>
          <div class="field-container">
            <div class="label">
              {{ text(['Дата окончания осмотра', 'Fecha de finalización de la inspección']) }}
            </div>
            <div class="field">
              <p>{{ formatDate(task?.endDate) }}</p>
              <app-icon style="width: 20px" [icon]="iconsByName.calendar"/>
            </div>
          </div>
          <div class="field-container">
            <div class="label">{{ text(['Инженер', 'Ingeniero']) }}</div>
            <div class="field">
              <p>{{ task?.engineer ? task?.engineer.getFIO() : text(['Не назначен', 'No asignado']) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="taskServices.length > 0" class="service-container">
        <h4>{{ text(['Услуги тарифа', 'Servicios de tarifa']) }}</h4>
        <div class="service-items-container">
          <div *ngFor="let item of taskServices" class="service-item">
            <app-checkbox [disable]="true" [value]="item.done"/>
            <p>{{ item.serviceType?.name }}</p>
          </div>
        </div>
      </div>
      <div *ngIf="taskServicesAdditional.length > 0" class="service-container">
        <h4>{{ text(['Дополнительные услуги', 'Servicios adicionales']) }}</h4>
        <div class="service-items-container">
          <div *ngFor="let item of taskServicesAdditional" class="service-item">
            <app-checkbox [disable]="true" [value]="item.done"/>
            <p>{{ item.serviceType?.name }}</p>
          </div>
        </div>
      </div>
      <div class="recommendation-container">
        <h4>{{ text(['Рекомендации', 'Recomendación']) }}</h4>
        <div class="recommendation">
          {{ task?.recommendation?.length > 0 ?  task?.recommendation : text(['Нет рекомендаций', 'No hay recomendaciones'])}}
        </div>
      </div>
    </div>
    <div *ngIf="activeTab == 1" class="problems">
      <div class="defect-list-container">
        <div *ngFor="let defect of task?.defects" class="defect-item">
          <app-cell-item
            [icon]="defect.eliminated ? iconsByName.check2 : iconsByName.broken"
            [title]="defect.defectType?.name ?? ''"
            [label]="defect.eliminated ? 'Устранено' : 'Не устранено'"
            [color]="defect.eliminated ? '#4DCA76' : '#ff0000'"
            (click)="setActiveDefect(defect)"
            [background]="defect.id == activeDefect?.id ? '#EAF5FF' : ''"
          />
        </div>
      </div>
      <div *ngIf="activeDefect" class="defect-info-container">
        <div class="defect-info-content">
          <div *ngIf="activeDefectTab == 0" class="defect-info-files">
            <div class="defect-info-file" *ngFor="let file of activeDefect?.files">
              <app-photo [id]="file.id" />
            </div>
          </div>
          <div *ngIf="activeDefectTab == 1" class="defect-info-recommendation">
            <p *ngIf="activeDefect?.description?.length == 0; else Description">Нет описания</p>
            <ng-template #Description>
              <p>{{ activeDefect?.description }}</p>
            </ng-template>
          </div>
        </div>
        <div class="defect-info-tabs">
          <div class="defect-info-tab" [class]="activeDefectTab == 0 ? 'defect-info-tab-active' : ''" (click)="activeDefectTab = 0">
            {{ text(['Фотографии', 'Fotos']) }}
          </div>
          <div class="defect-info-tab" [class]="activeDefectTab == 1 ? 'defect-info-tab-active' : ''" (click)="activeDefectTab = 1">
            {{ text(['Описание выявленных проблем', 'Descripción de los problemas identificados']) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</app-section>

