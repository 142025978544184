import {Component, OnInit} from '@angular/core';
import {TableComponent} from "../../../elements/table/table.component";
import {ButtonComponent} from "../../../elements/button/button.component";
import {Location, NgForOf, NgIf} from "@angular/common";
import {PaginationComponent} from "../../../elements/pagination/pagination.component";
import {TableFilter, TableTemplate} from "../../../../model/table";
import {ApiService} from "../../../../services/api.service";
import {User} from "../../../../model/user";
import {ApiPath} from "../../../../model/global";
import {SectionComponent} from "../../../elements/section/section.component";
import {headers, HeadersName} from "../../../../model/header";
import {HeaderService} from "../../../../services/header.service";
import {routes} from "../../../../app.routes";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {MenuService} from "../../../../services/menu.service";
import {MenuNames} from "../../../../model/menu";
import {tablesTemplates, TemplatesNames} from "../../../../model/templates";
import {LoadingComponent} from "../../../elements/loading/loading.component";
import {LocalizationService} from "../../../../services/localization.service";
import {CheckboxComponent} from "../../../elements/checkbox/checkbox.component";
import {LanguageList} from "../../../../model/language";

import {text} from "../../../../function/localization";


@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    ButtonComponent,
    NgForOf,
    TableComponent,
    PaginationComponent,
    SectionComponent,
    RouterLink,
    NgIf,
    LoadingComponent,
    CheckboxComponent
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss'
})
export class AdminComponent implements OnInit{
  directories = [
    text(["Пользователи", "Usuarios"]),
    text(["Системные настройки", "Configuraciones del sistema"])
  ];
  tableTemplate: TableTemplate = tablesTemplates[TemplatesNames.users];
  constructor(protected getService: ApiService,
              protected headerService: HeaderService,
              protected menuService: MenuService,
              protected localizationService: LocalizationService,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location) {}

  tableFilter: TableFilter = new TableFilter({
    size: 10,
    query: "",
    sort: "",
    page: 0,
    totalPages: 0,
    totalElements: 0,
  });

  addLink: string = "/admin/admin/user/add"

  loading: boolean = false;

  languagesLabels: LanguageList[] = [];
  locationLabels: LanguageList[] = [];

  ngOnInit() {
    this.headerService.setCurrentHeader(headers[HeadersName.Admin]);
    this.menuService.setActiveTab(MenuNames.Admin);
    this.getData<User>();
    this.languagesLabels = this.localizationService.languagesLabels.map(v => new LanguageList(v));
    this.locationLabels = this.localizationService.locationLabels.map(v => new LanguageList(v));
    this.setActiveLang(this.localizationService.getActiveLang());
    this.setActiveLoc(this.localizationService.getLocation());
  }

  getData<T>() {
    this.loading = true;
    this.getService.getList<T>(ApiPath.User, this.tableFilter)
      .subscribe((data) => {
        this.tableTemplate.content = data.content ?? [];
        // TODO уёбищное решение, когда с методом всё устаканится — переделать
        this.tableFilter.size = data.page.size;
        this.tableFilter.totalElements = data.page.totalElements;
        this.tableFilter.totalPages = data.page.totalPages;
        this.loading = false;
      });
  }

  changePage(tableFilter: TableFilter) {
    this.tableFilter = tableFilter;
    this.getData<User>();
  }

  openCard(user: User) {
      this.router.navigate(['/admin/admin/user/' + user.id], {relativeTo: this.route}).then(r => r);
  }

  setActiveLang(idx: number) {
    this.languagesLabels.forEach((v, index)  => {
      v.active = idx == index;
    });
  }

  setActiveLoc(idx: number) {
    this.locationLabels.forEach((v, index)  => {
      v.active = idx == index;
    });
  }

  refreshPage() {
    this.location.replaceState(this.location.path());
    window.location.reload();
  }

  save(){
    const lang = this.languagesLabels.findIndex(v => v.active);
    const loc = this.locationLabels.findIndex(v => v.active);
    this.localizationService.setLanguage(lang);
    this.localizationService.setLocation(loc);
    this.refreshPage();
  }

  activeDirectory: number = 0;
  protected readonly text = text;
}
