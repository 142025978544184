import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {NotificationForm} from "../model/notification";
import {Header} from "../model/header";
import {ModalOptions} from "../model/modalOptions";

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor() { }

  public modal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public modalActive: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public modalOption: BehaviorSubject<ModalOptions | null> = new BehaviorSubject<ModalOptions | null>(null);
  public modalBuffer: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  getModal() {
    return this.modal.value;
  }

  getOptions() {
    return this.modalOption.value;
  }

  getModalBuffer() {
    return this.modalBuffer.value;
  }

  getModalActive() {
    return this.modalActive.value;
  }


  setModalActive(value: string){
    this.modalActive.next(value);
  }

  setModalBuffer(value: any) {
    this.modalBuffer.next(value);
  }

  setModalOptions(value: any) {
    this.modalOption.next(value);
  }

  setModal(value: boolean, options: ModalOptions | null) {
    this.modal.next(value);
    this.modalOption.next(options);
    this.getVail(value);
  }

  clearBuffer() {
    this.setModalBuffer(null);
    this.setModalActive("");
    this.setModalOptions(null);
  }

  getVail(value: boolean){
    const el = document.getElementById('body');
    if (el) {
      if (value) {
        el.style.overflow = 'hidden';
      } else {
        el.style.overflow = 'inherit';
      }
    }
  }
}
