<app-section>
  <div class="tusk-container">
    <div class="tusk-above-panel" [style]="this.tuskService.getActiveTusk() == 0 ? 'max-width: 400px' : ''">
      <div class="tusk-menu">
        <div
          *ngFor="let item of directories; index as i"
          class="tusk-menu-item"
          (click)="changeDirectory(i)"
          [class]="{ 'tusk-menu-item-active': this.tuskService.getActiveTusk() == i }"
        >
          {{ item.name }}
        </div>
      </div>
      <div *ngIf="this.tuskService.getActiveTusk() == 1" class="tusk-button-container">
        <app-button
          class="operation-button"
          (click)="changeDirectory(this.tuskService.getActiveTusk())">
          {{ text(["Обновить", "Actualizar"]) }}
        </app-button>
        <app-button
          class="operation-button"
          [styleClass]="'button-style-1'"
          (click)="refresh()"
        >
          {{ text(["Очистить", "Limpiar"]) }}
        </app-button>
        <app-button
          class="operation-button"
          (click)="openCard(this.tuskService.getActiveTusk())">
          {{ text(["Создать", "Crear"]) }}
        </app-button>
      </div>
    </div>
    <div *ngIf="this.tuskService.getActiveTusk() == 1" class="tusk-workspace">
      <app-pagination *ngIf="!loading" [tableFilter]="tableFilter" (tableFilterOut)="changePageDirectory($event)"/>
      <app-table
        class="users-table"
        [tableTemplate]="tableTemplate"
        [loading] = loading
        [refreshSort]="refreshSort"
        [tableFilter]="tableFilter"
        [taskPage]="true"
        (clickRow)="openCard(this.tuskService.getActiveTusk(), $event)"
        (tableFilterEmit)="updFilter($event)"
      />
      <app-pagination *ngIf="!loading" [tableFilter]="tableFilter" (tableFilterOut)="changePageDirectory($event)"/>
    </div>
    <div *ngIf="this.tuskService.getActiveTusk() == 0" class="calendar-workspace-container">
      <app-calendar-page
        [loading]="loading"
      />
    </div>
  </div>
</app-section>

