import {Component, OnInit} from '@angular/core';
import {IconComponent} from "../icon/icon.component";
import {icons, iconsByName} from "../../../model/icon";
import {LoginService} from "../../../services/login.service";
import {Location, NgForOf, NgIf} from "@angular/common";
import {text} from "../../../function/localization";
import {CheckboxComponent} from "../checkbox/checkbox.component";
import {LanguageList} from "../../../model/language";
import {LocalizationService} from "../../../services/localization.service";
import {ButtonComponent} from "../button/button.component";
import {InterfaceService} from "../../../services/interface-service";
import {Router} from "@angular/router";
import {ClickedOutsideDirectiveDirective} from "../../directive/clicked-outside-directive.directive";
import {SaveStorage} from "../../../services/save-storage.service";

@Component({
  selector: 'app-login-info',
  standalone: true,
  imports: [
    IconComponent,
    NgIf,
    CheckboxComponent,
    NgForOf,
    ButtonComponent,
    ClickedOutsideDirectiveDirective
  ],
  templateUrl: './login-info.component.html',
  styleUrl: './login-info.component.scss'
})
export class LoginInfoComponent implements OnInit{
  constructor(protected loginService: LoginService,
              protected localizationService: LocalizationService,
              private location: Location,
              protected interfaceService: InterfaceService,
              private router: Router,
              public saveStorage: SaveStorage) {}

  iconId: number = 1;
  openMenu: boolean = false;

  languagesLabels: LanguageList[] = [];
  locationLabels: LanguageList[] = [];

  authorization: boolean = false;

  ngOnInit() {
    this.languagesLabels = this.localizationService.languagesLabels.map(v => new LanguageList(v));
    this.locationLabels = this.localizationService.locationLabels.map(v => new LanguageList(v));
    this.setActiveLang(this.localizationService.getActiveLang());
    this.setActiveLoc(this.localizationService.getLocation());
    this.interfaceService.setActiveInterface(this.saveStorage.getInterfaceService())
  }



  setActiveLang(idx: number) {
    this.languagesLabels.forEach((v, index)  => {
      v.active = idx == index;
    });
  }

  setActiveLoc(idx: number) {
    this.locationLabels.forEach((v, index)  => {
      v.active = idx == index;
    });
  }

  isChange() {
    const lang = this.languagesLabels.findIndex(v => v.active);
    const loc = this.locationLabels.findIndex(v => v.active);
    return this.localizationService.getActiveLang() != lang || this.localizationService.getLocation() != loc;
  }

  save(){
    const lang = this.languagesLabels.findIndex(v => v.active);
    const loc = this.locationLabels.findIndex(v => v.active);
    this.localizationService.setLanguage(lang);
    this.localizationService.setLocation(loc);
    this.refreshPage();
  }

  refreshPage() {
    this.location.replaceState(this.location.path());
    window.location.reload();
  }

  switchInterface() {
    if (this.interfaceService.getActiveInterface() == 0) {
      this.interfaceService.setActiveInterface(1);
      this.saveStorage.setInterfaceService(1);
      this.router.navigate(['client']).then(r => r);
    } else {
      this.interfaceService.setActiveInterface(0);
      this.saveStorage.setInterfaceService(0);
      this.router.navigate(['admin']).then(r => r);
    }
  }


  protected readonly icons = icons;
  protected readonly iconsByName = iconsByName;
  protected readonly text = text;
}
