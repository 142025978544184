<div *ngIf="isCreateNew" (click)="openModal(false, null)" class="button-container">
  <h4>{{ newTableTemplate.name }}</h4>
  <app-button>{{ text(["Создать", "Crear"]) }}</app-button>
</div>
<app-table
  class="service-table"
  [tableTemplate]="newTableTemplate"
  [disable]="disable"
  (clickRow)="clickRow($event)"
  (clickCheckbox)="setCheckbox($event)"
  (deleteRow)="deleteRow($event)"
/>
