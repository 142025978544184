<app-section>
  <div *ngIf="loading" class="loading-container">
    <app-loading />
  </div>
  <div *ngIf="!loading" class="inspection-log-container">
    <app-client-subheader [clientSubheader]="subheader" (clickBack)="back()" class="subheader"/>
      <div class="inspection-log-items-container">
        <div *ngFor="let contract of contracts" class="log-item">
          <app-cell-item
            [routerLink]="'/client/inspection-log/contract/' + contract.id"
            [title]="contract.objective?.address ?? ''"
            [label]="contract.objective?.objectiveType?.name ?? ''"
            [color]="contract.objective?.objectiveType.id == 2 ? '#0eb58a' : '#0e62b5'"
            [icon]="contract.objective?.objectiveType.id == 2 ? iconsByName.flat : iconsByName.house"
          />
        </div>
      </div>
    </div>
</app-section>
