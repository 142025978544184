import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonComponent} from "../button/button.component";
import {NgForOf, NgIf} from "@angular/common";
import {OurFile} from "../../../model/ourFile";
import {FileService} from "../../../services/file.service";
import {NotificationService} from "../../../services/notification.service";
import {LoadingComponent} from "../loading/loading.component";
import {text} from "../../../function/localization";
import {getPropValue} from "../../../function/getValue";
import {Router} from "@angular/router";

@Component({
  selector: 'app-files-field',
  standalone: true,
  imports: [
    ButtonComponent,
    NgForOf,
    LoadingComponent,
    NgIf
  ],
  templateUrl: './files-field.component.html',
  styleUrl: './files-field.component.scss'
})
export class FilesFieldComponent {
  @Input() id: number = 0;
  @Input() value: OurFile[] = [];
  @Input() readonly: boolean = false;
  @Input() obj: any = null;
  @Output() updObj: EventEmitter<any> = new EventEmitter();
  @Output() emitFiles: EventEmitter<File[]> = new EventEmitter();
  @Output() emitFilesToDel: EventEmitter<number[]> = new EventEmitter();
  constructor(private fileService: FileService,
              public notificationService: NotificationService,
              private router: Router) {}

  loading: boolean = false;
  files: File[] = [];
  filesToDel: number[] = [];

  uploadFile(event: any){
    const files: File[] = Array.from(event.target.files);
    this.files.push(...files);
    this.emitFiles.emit(this.files);
    const ourFiles: OurFile[] = files.map(((file, index) => {
      return new OurFile({
        id: -(index + 1),
        path: file.name,
        mimeType: ""
      });
    }));
    this.value.push(...ourFiles);
  }

  downloadFile(file: OurFile){
    if (this.readonly) {
      const x = this.router.url.split("/");
      const taskId = Number(x[x.length - 1]);
      const defectId = getPropValue(this.obj, "id");
      const defectFileId = getPropValue(file, "id");
      this.fileService.downloadFileDefect(taskId, defectId, defectFileId).subscribe({
        next: (response) => {
          let blob: Blob = response.body as Blob;
          let a = document.createElement('a');
          a.download = this.getLabelFile(file.path);
          a.href = window.URL.createObjectURL(blob);
          a.click();
        }
      });
    } else {
      this.fileService.download(file.id).subscribe({
        next: (response) => {
          let blob: Blob = response.body as Blob;
          let a = document.createElement('a');
          a.download = this.getLabelFile(file.path);
          a.href = window.URL.createObjectURL(blob);
          a.click();
        }
      });
    }
  }

  delete(ourFile: OurFile){
    const indexValue = this.value.findIndex(f => ourFile.id == f.id);
    if (ourFile.id < 0) {
      const indexFiles = this.files.findIndex(f => ourFile.path == f.name);
      if (indexFiles > -1 && indexValue) {
        this.files.splice(indexFiles, 1);
        this.value.splice(indexValue, 1);
        this.emitFiles.emit(this.files);
      }
    } else {
      if (indexValue > -1) {
        const ids: number[] = [];
        ids.push(ourFile.id);
        this.filesToDel.push(...ids);
        this.value.splice(indexValue, 1);
        this.emitFilesToDel.emit(this.filesToDel);
      }
    }
  }

  getLabelFile(path: string){
    const arr = path.split("/");
    return arr[arr.length - 1] ?? "";
  }

  protected readonly text = text;
}
