import {text} from "../function/localization";

export class DirectoriesTusks {
  id: number;
  name: string;
  label: string;

  constructor(value: DirectoriesTusks) {
    this.id = value.id ?? 0;
    this.name = value.name ?? "";
    this.label = value.label ?? "";
  }
}

export const dataDirectoriesTusk : DirectoriesTusks[] = [
  {
    id: 0,
    name: text(["Календарь", "Calendario"]),
    label: "",
  },
  {
    id: 1,
    name: text(["Реестр заданий", "Lista de tareas"]),
    label: "",
  },
];

